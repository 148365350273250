import React from 'react';
import { Styles } from './styles/paginationNew.js';

const PaginationNew = ({
  total,
  perPage,
  currentPage,
  onPageChange,
  onNextPageChange,
  onPreviousPageChange,
}) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(total / perPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <Styles>
      {/* Pagination */}
      <ul className="pagination-box list-unstyled list-inline">
        <li className="list-inline-item">
          <div>
            <i
              onClick={() => onPreviousPageChange(currentPage)}
              className="las la-angle-double-left"
            ></i>
          </div>
        </li>
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={`list-inline-item ${
              currentPage === number ? 'active' : ''
            }`}
          >
            <div onClick={() => onPageChange(number)}>{number}</div>
          </li>
        ))}
        <li className="list-inline-item">
          <div>
            <i
              onClick={() => onNextPageChange(currentPage)}
              className="las la-angle-double-right"
            ></i>
          </div>
        </li>
      </ul>
    </Styles>
  );
};

export default PaginationNew;
