import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
  .main-menu {
    .main-menu-box {
      position: relative;
      z-index: 9999999999;

      .menu-box {
        .mini-menu {
          // display: none !important;
          background: red;
        }
        position: absolute;
        width: 100%;
        top: 20px;
        left: 0;
        padding: 0 30px;
        background: ${colors.gr_bg};
        border-radius: 1rem;

        ul.nav.menu-nav {
          li.nav-item {
            position: relative;

            a.nav-link,
            label {
              font-size: 14px;
              color: #ffffff;
              text-transform: uppercase !important;
              font-weight: 500;
              padding: 18px 25px 15px 0;
              margin: 0;

              i {
                font-size: 12px;
              }

              &:after {
                content: none;
              }

              &:hover {
                color: ${colors.text5};
              }

              @media (max-width: 991px) {
                padding-right: 10px;
              }
            }

            ul.dropdown {
              position: absolute;
              left: -10px;
              top: 100%;
              min-width: 190px;
              background: #fff;
              text-align: left;
              padding: 0;
              border-radius: 0 0 1rem 1rem;
              transition: 0.2s ease;
              opacity: 0;
              transform: scaleY(0);
              visibility: hidden;
              z-index: 999;
              transform-origin: center top 0;

              li.nav-item {
                position: relative;

                a.nav-link {
                  font-size: 13px;
                  color: ${colors.text1};
                  padding: 10px 20px;
                  text-transform: capitalize;
                  margin-right: 0;
                  font-weight: 400;
                  border-bottom: 1px solid ${colors.border1};

                  &:hover {
                    color: ${colors.water};

                    i {
                      color: #fff;
                    }
                  }

                  i {
                    float: right;
                    margin-top: 4px;
                  }
                }

                &:last-child {
                  margin-left: 0;

                  a.nav-link {
                    border-bottom: none;
                  }
                }

                ul.dropdown2 {
                  position: absolute;
                  top: 0;
                  left: 100%;
                  min-width: 180px;
                  background: #fff;
                  border: 1px solid ${colors.border1};
                  transition: 0.2s ease;
                  opacity: 0;
                  transform: scaleY(0);
                  visibility: hidden;
                  z-index: 999;
                  transform-origin: center top 0;
                }

                &:hover {
                  ul.dropdown2 {
                    opacity: 1;
                    transform: scaleY(1);
                    visibility: visible;
                  }
                }
              }
            }

            &:hover {
              ul.dropdown {
                opacity: 1;
                transform: scaleY(1);
                visibility: visible;
              }
            }
          }
        }
        ul ul {
          li.nav-item {
            position: relative;

            a.nav-link {
              font-size: 14px;
              color: #ffffff;
              text-transform: uppercase !important;
              font-weight: 500;
              padding: 18px 25px 15px 0;

              i {
                font-size: 12px;
              }

              &:after {
                content: none;
              }

              &:hover {
                color: ${colors.text5};
              }

              @media (max-width: 991px) {
                padding-right: 10px;
              }
            }

            ul.dropdown {
              position: absolute;
              left: 100% !important;
              top: 50% !important;
              min-width: 190px;
              background: #fff;
              text-align: left;
              padding: 0;
              border-radius: 0 1rem 1rem 0 !important;
              transition: 0.2s ease;
              opacity: 0 !important;
              transform: scaleY(0) !important;
              visibility: hidden !important;
              z-index: 999;
              transform-origin: center top 0;

              li.nav-item {
                position: relative;

                a.nav-link {
                  font-size: 13px;
                  color: ${colors.text1};
                  padding: 10px 20px;
                  text-transform: capitalize;
                  margin-right: 0;
                  font-weight: 400;
                  border-bottom: 1px solid ${colors.border1};

                  &:hover {
                    color: ${colors.water};

                    i {
                      color: #fff;
                    }
                  }

                  i {
                    float: right;
                    margin-top: 4px;
                  }
                }

                &:last-child {
                  margin-left: 0;

                  a.nav-link {
                    border-bottom: none;
                  }
                }
              }
            }

            &:hover {
              ul.dropdown {
                opacity: 1 !important;
                transform: scaleY(1) !important;
                visibility: visible !important;
              }
            }
          }
        }

        ul.search-cart-bar {
          li.search-box {
            i.close-btn {
              position: absolute;
              top: 25px;
              right: -12px;
              font-size: 26px;
              color: ${colors.water};
              cursor: pointer;
            }
          }

          li.cart-box {
            a.nav-cart {
              padding: 18px 0 0 10px;

              i {
                font-size: 20px;
                color: ${colors.border1};
                line-height: 17px;
              }
            }
          }
        }

        @media (max-width: 991px) {
          padding: 0 20px;
        }
      }
    }

    @media (max-width: 767px) {
      display: none;
    }
  }
`;
