import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
const Menu = ({ menuData }) => {
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 80) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    });
  });
  const renderMenuItems = (menuItems) => {
    return menuItems.map((item, index) => (
      <li
        key={index.toString()}
        className={`nav-item ${
          item.parentid === 0 || item.url === "" ? "" : " dropdown"
        }`}
      >
        {item.isHyperlink ? (
          <a
            className="nav-link dropdown-toggle"
            href={item.url}
            target="_blank"
            data-toggle="dropdown"
            // onClick={() => {
            //   if (item.parentid !== 0 || item.url !== "")
            //     window.location.reload();
            // }}
          >
            {item.name}
            {item.children.length > 0 && <i className="las la-angle-down"></i>}
          </a>
        ) : item.children.length > 0 ? (
          <label
          // className="nav-link dropdown-toggle"
          // href={item.url !== "" ? process.env.PUBLIC_URL + item.url : ""}
          // onClick={() => {
          //   if (item.parentid !== 0 || item.url !== "")
          //     window.location.reload();
          // }}
          // data-toggle="dropdown"
          >
            {item.name} {"  "}
            {item.children.length > 0 && <i className="las la-angle-down"></i>}
          </label>
        ) : (
          <a
            className="nav-link dropdown-toggle"
            href={item.url !== "" ? process.env.PUBLIC_URL + item.url : ""}
            onClick={() => {
              if (item.parentid !== 0 || item.url !== "")
                window.location.reload();
            }}
            data-toggle="dropdown"
          >
            {item.name} {"  "}
            {item.children.length > 0 && <i className="las la-angle-down"></i>}
          </a>
        )}
        {item.children.length > 0 && (
          <ul className="dropdown list-unstyled">
            {renderMenuItems(item.children)}
          </ul>
        )}
      </li>
    ));
  };
  return (
    <div className="main-menu-box">
      <div className="menu-box d-flex justify-content-between align-content-center ">
        <div className={isSticky ? "d-none " : ""}>
          <Link to={process.env.PUBLIC_URL + "/"}>
            <div
              className="d-flex align-items-center "
              style={{
                background: "white",
                margin: "2px 0",
                padding: "0 10px",
              }}
            >
              <img
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                }}
                src={process.env.PUBLIC_URL + "/assets/images/logo-bd.png"}
                alt=""
              />
              <img
                style={{
                  width: "70px",
                  height: "60px",
                }}
                src={process.env.PUBLIC_URL + "/assets/images/logo-company.png"}
                alt=""
              />
            </div>
          </Link>
        </div>
        <ul className="nav menu-nav" style={{ alignItems: "center" }}>
          {renderMenuItems(menuData.menu)}
        </ul>
      </div>
    </div>
  );
};

export default Menu;
