export const wfdData = [
  {
    id: 1,
    img: "wfd-cover.png",
    link: "https://wfd-data.rwm.global/uploads/123_report.pdf",
  },
  {
    id: 2,
    img: "wfd-cover.png",
    link: "https://wfd-data.rwm.global/uploads/118_report.pdf",
  },
  {
    id: 3,
    img: "wfd-cover.png",
    link: "https://wfd-data.rwm.global/uploads/119_report.pdf",
  },
  {
    id: 4,
    img: "wfd-cover.png",
    link: "https://wfd-data.rwm.global/uploads/120_report.pdf",
  },
  {
    id: 5,
    img: "wfd-cover.png",
    link: "https://wfd-data.rwm.global/uploads/121_report.pdf",
  },
  {
    id: 6,
    img: "wfd-cover.png",
    link: "https://wfd-data.rwm.global/uploads/122_report.pdf",
  },
];
