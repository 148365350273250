import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import StickyMenu from "./common/StickyMenu";
import MobileMenu from "./common/MobileMenu";
import { Styles } from "./styles/menu.js";

import { menuList } from "../data/menu/menuList";
import Menu from "./common/menu/Menu";

class Header extends Component {
  render() {
    return (
      <Styles>
        <section className="main-menu">
          <Container>
            <Row>
              <Col>
                <Menu menuData={menuList} />
              </Col>
            </Row>
          </Container>
        </section>

        {/* Sticky Menu */}
        <StickyMenu />

        {/* Mobile Menu */}
        <MobileMenu />
      </Styles>
    );
  }
}

export default Header;
