export const data = [
  {
    City: "Araihazar Municipality",
    State: "Narayanganj",
    Coordinate: "23.788763671790047, 90.6596370306207",
    Safely_Managed_Sanitation: 9,
  },
  {
    City: "Bagerhat Municipality",
    State: "Bagerhat",
    Coordinate: "22.655790270777587, 89.79475849362358",
    Safely_Managed_Sanitation: 19,
  },
  {
    City: "Bandarban Municipality",
    State: "Bandarban",
    Coordinate: "22.19421486067269, 92.215905378442",
    Safely_Managed_Sanitation: 18,
  },
  {
    City: "Barguna Municipality",
    State: "Barguna",
    Coordinate: "22.16033765114727, 90.11797208779151",
    Safely_Managed_Sanitation: 24,
  },
  {
    City: "Barishal City Corporation",
    State: "Barishal",
    Coordinate: "22.701939414722585, 90.37340313393116",
    Safely_Managed_Sanitation: 25,
  },
  {
    City: "Bauphal Municipality",
    State: "Patuakhali",
    Coordinate: "22.417127035017213, 90.55109573606623",
    Safely_Managed_Sanitation: 21,
  },
  {
    City: "Betagi Municipality",
    State: "Barguna",
    Coordinate: "22.411745887225432, 90.16695213956247",
    Safely_Managed_Sanitation: 21,
  },
  {
    City: "Bhola Municipality (vn 2022)",
    State: "Bhola",
    Coordinate: "22.68604155583049, 90.64602278147176",
    Safely_Managed_Sanitation: 26,
  },
  {
    City: "Bogra Municipality",
    State: "Rajshahi Division",
    Coordinate: "24.84534544438822, 89.3774482391843",
    Safely_Managed_Sanitation: 19,
  },
  {
    City: "Brahmanbaria Municipality",
    State: "Brahmanbaria",
    Coordinate: "23.974541130136483, 91.1097091074442",
    Safely_Managed_Sanitation: 13,
  },
  {
    City: "Chandpur Municipality",
    State: "Chandpur",
    Coordinate: "23.224573783116426, 90.65278206240153",
    Safely_Managed_Sanitation: 20,
  },
  {
    City: "Chapai Nawabganj Municipality (vn 2022)",
    State: "Rajshahi Division",
    Coordinate: "24.59743919886596, 88.26965893890846",
    Safely_Managed_Sanitation: 23,
  },
  {
    City: "Chuadanga Municipality",
    State: "Khulna Division",
    Coordinate: "23.643894915488946, 88.8501850881829",
    Safely_Managed_Sanitation: 27,
  },
  {
    City: "Coxs Bazar Municipality",
    State: "Cox's Bazar",
    Coordinate: "21.444263718820206, 91.97355717049355",
    Safely_Managed_Sanitation: 20,
  },
  {
    City: "Cumilla City Corporation (vn 2022)",
    State: "Cumilla",
    Coordinate: "23.467317822988615, 91.17599624008415",
    Safely_Managed_Sanitation: 14,
  },
  {
    City: "Dinajpur Municipality",
    State: "Rangpur Division",
    Coordinate: "25.624982596990638, 88.63601832149214",
    Safely_Managed_Sanitation: 23,
  },
  {
    City: "Faridpur Municipality",
    State: "Faridpur",
    Coordinate: "23.60671551592557, 89.83874752663333",
    Safely_Managed_Sanitation: 50,
  },
  {
    City: "Feni Municipality",
    State: "Feni",
    Coordinate: "23.01135446470801, 91.40142679532494",
    Safely_Managed_Sanitation: 8,
  },
  {
    City: "Gaibandha Municipality",
    State: "Rangpur Division",
    Coordinate: "25.331468948005526, 89.54651346628886",
    Safely_Managed_Sanitation: 24,
  },
  {
    City: "Galachipa Municipality",
    State: "Patuakhali",
    Coordinate: "22.171885152715383, 90.41188102310828",
    Safely_Managed_Sanitation: 20,
  },
  {
    City: "Gazipur (vn 2022)",
    State: "Gazipur",
    Coordinate: "23.998522834379962, 90.42197960236868",
    Safely_Managed_Sanitation: 26,
  },
  {
    City: "Gopalganj Municipality",
    State: "Gopalganj",
    Coordinate: "23.005730272884268, 89.82612647380645",
    Safely_Managed_Sanitation: 26,
  },
  {
    City: "Homna Municipality",
    State: "Cumilla",
    Coordinate: "23.6826909732922, 90.78315538406709",
    Safely_Managed_Sanitation: 9,
  },
  {
    City: "Ishwardi Municipality",
    State: "Pabna",
    Coordinate: "24.13017924779836, 89.07149121950154",
    Safely_Managed_Sanitation: 18,
  },
  {
    City: "Jamalpur, Bangladesh",
    State: "Mymensingh Division",
    Coordinate: "24.93258123652839, 89.94387167509542",
    Safely_Managed_Sanitation: 1,
  },
  {
    City: "Jhenaidah Municipality",
    State: "Jhenaidah",
    Coordinate: "23.552892381131386, 89.17538947630091",
    Safely_Managed_Sanitation: 29,
  },
  {
    City: "Joypurhat Municipality (vn 2022)",
    State: "Rajshahi Division",
    Coordinate: "25.102659831672835, 89.02526008417117",
    Safely_Managed_Sanitation: 33,
  },
  {
    City: "Kaliakair Municipality",
    State: "Gazipur",
    Coordinate: "24.076580547023767, 90.21301902545208",
    Safely_Managed_Sanitation: 11,
  },
  {
    City: "Kalihati Municipality",
    State: "Tangail",
    Coordinate: "24.388265909461985, 89.99103381531876",
    Safely_Managed_Sanitation: 26,
  },
  {
    City: "Kishoreganj Municipality",
    State: "Dhaka Division",
    Coordinate: "24.439000737245845, 90.77641709663516",
    Safely_Managed_Sanitation: 9,
  },
  {
    City: "Lakshmipur Municipality",
    State: "Lakshmipur",
    Coordinate: "22.94568416015072, 90.83417648775176",
    Safely_Managed_Sanitation: 30,
  },
  {
    City: "Lalmonirhat Municipality",
    State: "Rangpur Division",
    Coordinate: "25.91208988364056, 89.44746641286797",
    Safely_Managed_Sanitation: 17,
  },
  {
    City: "Lama Municipality",
    State: "Chittagong Division",
    Coordinate: "21.80128409107402, 92.21871877546114",
    Safely_Managed_Sanitation: 10,
  },
  {
    City: "Magura Municipality",
    State: "Khulna Division",
    Coordinate: "23.489890847967377, 89.42045621376217",
    Safely_Managed_Sanitation: 17,
  },
  {
    City: "Meherpur Municipality (vn 2022)",
    State: "Khulna Division",
    Coordinate: "23.776491797546964, 88.63177902206947",
    Safely_Managed_Sanitation: 16,
  },
  {
    City: "Naogaon Municipality (vn 2022)",
    State: "Rajshahi Division",
    Coordinate: "24.811305219386448, 88.94394204119918",
    Safely_Managed_Sanitation: 30,
  },
  {
    City: "Narayanganj City Corporation (vn 2022)",
    State: "Dhaka Division",
    Coordinate: "23.60823748515351, 90.50316874002736",
    Safely_Managed_Sanitation: 10,
  },
  {
    City: "Narsingdi Municipality",
    State: "Narsingdi",
    Coordinate: "23.920852329214902, 90.71989773587869",
    Safely_Managed_Sanitation: 2,
  },
  {
    City: "Natore Municipality",
    State: "Natore",
    Coordinate: "24.412501531213646, 88.9845793724179",
    Safely_Managed_Sanitation: 22,
  },
  {
    City: "Nilphamari Municipality (vn 2022)",
    State: "Rangpur Division",
    Coordinate: "25.93576885650223, 88.85209090266578",
    Safely_Managed_Sanitation: 23,
  },
  {
    City: "Noakhali Municipality",
    State: "Chittagong Division",
    Coordinate: "22.826562908844593, 91.10151129225872",
    Safely_Managed_Sanitation: 15,
  },
  {
    City: "Pabna Municipality",
    State: "Pabna",
    Coordinate: "24.000419957425795, 89.23442334828229",
    Safely_Managed_Sanitation: 2,
  },
  {
    City: "Paikgachha Municipality",
    State: "Khulna Division",
    Coordinate: "22.586230274749912, 89.32106137527357",
    Safely_Managed_Sanitation: 24,
  },
  {
    City: "Panchagarh Municipality",
    State: "Rangpur Division",
    Coordinate: "26.328614682606553, 88.55237756808712",
    Safely_Managed_Sanitation: 22,
  },
  {
    City: "Patuakhali Municipality",
    State: "Patuakhali",
    Coordinate: "22.359245718213696, 90.34442365559069",
    Safely_Managed_Sanitation: 12,
  },
  {
    City: "Rajbari Municipality",
    State: "Dhaka Division",
    Coordinate: "23.756071909305987, 89.64471643008943",
    Safely_Managed_Sanitation: 19,
  },
  {
    City: "Rangamati Municipality",
    State: "Rangamati",
    Coordinate: "22.649150290967587, 92.1826644286429",
    Safely_Managed_Sanitation: 13,
  },
  {
    City: "Shariatpur Municipality",
    State: "Shariatpur",
    Coordinate: "23.208663827955494, 90.34857258545266",
    Safely_Managed_Sanitation: 15,
  },
  {
    City: "Singair Municipality",
    State: "Manikganj",
    Coordinate: "23.812895124681244, 90.14781169790334",
    Safely_Managed_Sanitation: 24,
  },
  {
    City: "Sirajganj Municipality",
    State: "Rajshahi Division",
    Coordinate: "24.45382169632394, 89.70329766082409",
    Safely_Managed_Sanitation: 29,
  },
  {
    City: "Sonargaon Municipality",
    State: "Narayanganj",
    Coordinate: "23.657951296241247, 90.60793752379809",
    Safely_Managed_Sanitation: 25,
  },
  {
    City: "Sreepur Municipality",
    State: "Gazipur",
    Coordinate: "24.199800006378545, 90.47669267395105",
    Safely_Managed_Sanitation: 15,
  },
  {
    City: "Sylhet City Corporation (vn 2022)",
    State: "Sylhet Division",
    Coordinate: "24.891112434865562, 91.87046566629134",
    Safely_Managed_Sanitation: 27,
  },
  {
    City: "Tarabo Municipality",
    State: "Narayanganj",
    Coordinate: "23.736684398603543, 90.52323116189346",
    Safely_Managed_Sanitation: 6,
  },
  {
    City: "Thakurgaon Municipality (vn 2022)",
    State: "Rangpur Division",
    Coordinate: "26.040694974340287, 88.52655842975486",
    Safely_Managed_Sanitation: 28,
  },
];
