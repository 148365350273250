import React from "react";

import { animateScroll as scroll } from "react-scroll";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMouse } from "@fortawesome/free-solid-svg-icons";

const Banner = () => {
  const scrollToNextSection = () => {
    scroll.scrollTo(window.innerHeight);
  };
  return (
    <div className="home-banner">
      <img
        src={process.env.PUBLIC_URL + `/assets/images/home_banner_wide.png`}
        alt=""
      />
      <div className="icon" onClick={scrollToNextSection}>
        <FontAwesomeIcon icon={faMouse} />
      </div>
    </div>
  );
};

export default Banner;
