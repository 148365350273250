import React from "react";
import { useParams, useHistory } from "react-router-dom";
import Event1 from "./Event1";
import Event2 from "./Event2";
import Event3 from "./Event3";
import Event4 from "./Event4";
import Event5 from "./Event5";

const EventsWrapper = () => {
  const params = useParams();

  const { id } = params;

  if (id == 1) {
    return <Event1 />;
  } else if (id == 2) {
    return <Event2 />;
  } else if (id == 3) {
    return <Event3 />;
  } else if (id == 4) {
    return <Event4 />;
  } else if (id == 5) {
    return <Event5 />;
  }

  return <div>No News Found With this ID</div>;
};

export default EventsWrapper;
