import React from "react";

const Slide = ({ image_url, caption, active }) => {
  return (
    <div className={`slide ${active ? "active" : ""}`}>
      <img src={image_url} alt={caption} />
    </div>
  );
};

const Slider = ({ images, active, setActive }) => {
  const onNext = () => {
    if (active < images.length - 1) {
      setActive(active + 1);
    }
  };

  const onPrev = () => {
    if (active > 0) {
      setActive(active - 1);
    }
  };

  return (
    <div className="slider">
      <div className="slides">
        {images.map((e, i) => (
          <Slide key={e.caption} {...e} active={i === active} />
        ))}
      </div>
      <div className="navigation">
        <div className="navigation-next-prev">
          <div class="next-prev prev" onClick={onPrev} style={{background:'blue', color:'white', padding:'5px', height:'20px',width:'20px',display:'flex', alignItems:'center', justifyContent:'center'}}>
            {" "}
            &lt;{" "}
          </div>
          <div class="next-prev next" onClick={onNext} style={{background:'blue', color:'white', padding:'5px', height:'20px',width:'20px',display:'flex', alignItems:'center', justifyContent:'center'}}>
            {" "}
            &gt;{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Slider