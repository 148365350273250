import styled from "styled-components";
import { colors } from "../../../components/common/element/elements";

export const Styles = styled.div`
  .content-main {
    overflow-x: hidden;
    .content-container {
      max-width: 960px;
      margin: 0 auto;
      ul {
        padding-left: 30px;
      }
    }
    .sub-card {
      box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
        rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
      border: 1px solid lightgray;
      width: max-content;
      padding: 0 20px;
      border-radius: 8px;
      .card-title {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      :hover {
        cursor: pointer;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
          rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        background: ${colors.gr_bg};
        color: white;
      }
    }
  }
`;
