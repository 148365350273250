import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { Styles } from "./styles/contentStyle";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const Content = () => {
  let { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const title = (location && location.state && location.state.title) || "";

  const handleClick = (path, external) => {
    if (external) window.open(path, "_blank");
    else {
      console.log(process.env.PUBLIC_URL + path);
      history.push({ pathname: process.env.PUBLIC_URL + path });
    }
  };

  const Content1 = () => {
    return (
      <div style={{ padding: "20px 0" }}>
        <div className="content-container">
          <h2 className="text-center">{title}</h2>
          <div
            className="d-flex flex-column"
            style={{ gap: "10px", margin: "20px 0" }}
          >
            <h2 style={{ fontSize: "12px", fontWeight: 500 }}>
              Urban Sanitation Financing: Optimized resource mobilization for
              national planning
            </h2>
            <p>
              stresses the necessity of strategic collaboration to address the
              multifaceted challenges of urban sanitation. It acknowledges
              sanitation&#39;s pivotal role in public health, environmental
              sustainability, and urban community well-being. It advocates
              prioritizing investments in sanitation infrastructure and
              services, recognizing the need to efficiently allocate limited
              resources amid rapid urbanization. By aligning investments with
              national development objectives and urban planning strategies,
              governments can maximize their impact and meet sanitation needs
              effectively.
            </p>
            <p>
              Moreover, the importance of diversifying funding sources is
              emphasized, including private sector investments, international
              aid, and innovative financing mechanisms. Collaboration with
              stakeholders such as financial institutions, development agencies,
              and community organizations is crucial for sustainable resource
              mobilization.
            </p>
            <p>
              Transparency and accountability are highlighted as fundamental for
              successful urban sanitation financing, ensuring equitable access
              to services, and minimizing corruption risks. Ultimately, the goal
              is to improve public health, environmental quality, and urban
              livability through coordinated efforts that prioritize
              investments, leverage diverse funding, and promote governance
              practices.
            </p>
          </div>

          <div
            className="d-flex flex-column"
            style={{ gap: "10px", margin: "20px 0" }}
          >
            <h2 style={{ fontSize: "12px", fontWeight: 500 }}>
              Resource Mobilization through advocacy in designing new CWIS
              Project
            </h2>
          </div>
        </div>
      </div>
    );
  };

  const Content2 = () => {
    return (
      <div style={{ padding: "20px 0" }}>
        <div className="content-container">
          <h2 className="text-center">{title}</h2>
          <div
            className="d-flex flex-column"
            style={{ gap: "10px", margin: "20px 0" }}
          >
            <h2 style={{ fontSize: "12px", fontWeight: 500 }}>
              Data Utilization: Data use culture &amp; data utilization for
              allocating resources and tracking progress.
            </h2>
            <p>
              Integrating data utilization into scaling up City-wide Inclusive
              sanitation initiatives enhances transparency, accountability, and
              efficiency in resource allocation and program management. By
              adopting a culture of data use and leveraging data-driven
              approaches, urban areas can make significant strides towards
              achieving their sanitation goals and creating healthier, more
              livable environments for their residents.
            </p>
            <p>
              Ihe importance of leveraging data utilization in scaling up
              City-wide Inclusive sanitation efforts. It highlights how a
              data-driven approach can promote informed decision-making,
              optimize resource allocation, and monitor progress effectively.
            </p>
            <div>
              <p>Key point include:</p>
              <ul>
                <li style={{ fontStyle: "italic" }}>
                  Advocating for a robust data use culture among stakeholders at
                  all levels, promoting data-driven decision-making practices.
                </li>
                <li style={{ fontStyle: "italic" }}>
                  Utilizing various types of data, such as demographic,
                  socio-economic, Water, sanitation, and hygiene (WASH) and
                  environmental indicators, to identify priority areas and
                  allocate resources accordingly.
                </li>
                <li style={{ fontStyle: "italic" }}>
                  Utilizing various types of data, such as demographic,
                  socio-economic, Water, sanitation, and hygiene (WASH) and
                  environmental indicators, to identify priority areas and
                  allocate resources accordingly.
                </li>
              </ul>
            </div>
            <p>
              Overall, embedding data utilization into sanitation initiatives
              enhances transparency, accountability, and efficiency, ultimately
              contributing to the creation of healthier and more livable urban
              environments.
            </p>
          </div>

          <div
            className="d-flex flex-column"
            style={{ gap: "10px", margin: "20px 0" }}
          >
            <h2 style={{ fontSize: "12px", fontWeight: 500 }}>Sanboard</h2>
            <Row>
              <Col sm="2">
                <div
                  className="sub-card"
                  onClick={() => handleClick("http://sanboard.gov.bd/", true)}
                >
                  <div className="px-2 pt-3 card-title">
                    <label
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        cursor: "pointer",
                      }}
                    >
                      Sanboard
                    </label>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div
            className="d-flex flex-column"
            style={{ gap: "10px", margin: "20px 0" }}
          >
            <h2 style={{ fontSize: "12px", fontWeight: 500 }}>
              Shit Flow Diagram
            </h2>
            <Row>
              <Col sm="2">
                <div
                  className="sub-card"
                  onClick={() => handleClick("/shit-flow-diagram", false)}
                >
                  <div className="px-2 pt-3 card-title">
                    <label
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        cursor: "pointer",
                      }}
                    >
                      Shit Flow Diagram
                    </label>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div
            className="d-flex flex-column"
            style={{ gap: "10px", margin: "20px 0" }}
          >
            <h2 style={{ fontSize: "12px", fontWeight: 500 }}>
              SFD Interactive Diagram
            </h2>
            <Row>
              <Col sm="2">
                <div
                  className="sub-card"
                  onClick={() => handleClick("/sfd-dashboard", false)}
                >
                  <div className="px-2 pt-3 card-title">
                    <label
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        cursor: "pointer",
                      }}
                    >
                      SFD Interactive Diagram
                    </label>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div
            className="d-flex flex-column"
            style={{ gap: "10px", margin: "20px 0" }}
          >
            <h2 style={{ fontSize: "12px", fontWeight: 500 }}>WFD</h2>
            <Row>
              <Col sm="2">
                <div
                  className="sub-card"
                  onClick={() => handleClick("/wfd", false)}
                >
                  <div className="px-2 pt-3 card-title">
                    <label
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        cursor: "pointer",
                      }}
                    >
                      WFD
                    </label>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  };

  const Content3 = () => {
    return (
      <div style={{ padding: "20px 0" }}>
        <div className="content-container">
          <h2 className="text-center">{title}</h2>
          <div
            className="d-flex flex-column"
            style={{ gap: "10px", margin: "20px 0" }}
          >
            <h2 style={{ fontSize: "12px", fontWeight: 500 }}>
              Capacity Building: Strengthened workforce for city-wide inclusive
              sanitation service delivery.
            </h2>
            <p>
              Enhancing the Capacity for City-wide Inclusive Sanitation (CWIS)
              in Bangladesh is crucial for advancing effective planning,
              decision-making, community involvement, and sustainability. It
              equips stakeholders with the necessary knowledge and expertise to
              drive successful sanitation endeavors, ensuring environmental
              stewardship and bolstering public health. Moreover, capacity
              building advances gender equality and community resilience,
              serving as the bedrock for thriving sanitation initiatives across
              the nation.
            </p>
            <p>
              The CWIS-FSM Support Cell, in collaboration with ITN-BUET, sector
              partners, and the Municipal Association of Bangladesh (MAB),
              spearheads CWIS capacity building endeavors by nurturing a
              comprehensive CWIS ecosystem. This cell shoulders the
              responsibility of providing technical assistance to scale up CWIS
              primarily across the 329 municipalities of Bangladesh, aligning
              with the National Action Plan-2020 for implementing the Integrated
              Resource Recovery-Faecal Sludge Management (IRF-FSM) framework.
            </p>
            <div>
              <p>The key components of Capacity Building encompass::</p>
              <ul>
                <li style={{ fontStyle: "italic" }}>
                  Formulating CWIS strategies, guidelines, and advisory
                  documents.
                </li>
                <li style={{ fontStyle: "italic" }}>
                  Crafting CWIS Tools such as Capacity Needs Assessment (CNA),
                  Training Needs Assessment (TNA), Gender Gap Assessment tools
                  (GGA), and CWIS Action Plan (CAP) tools.
                </li>
                <li style={{ fontStyle: "italic" }}>
                  Developing comprehensive Capacity Building Packages utilizing
                  CNA, TNA, GGA, and CAP.
                </li>

                <li style={{ fontStyle: "italic" }}>
                  Designing specialized Training Modules.
                </li>
                <li style={{ fontStyle: "italic" }}>
                  Creating Information, Education, Communication-Behavior Change
                  Communication (IEC-BCC) materials.
                </li>
                <li style={{ fontStyle: "italic" }}>
                  Orchestrating CWIS Campaigns at both national and city levels.
                </li>
                <li style={{ fontStyle: "italic" }}>
                  Establishing a Regional Master Trainers Resource Pool to
                  facilitate the scaling up of CWIS.
                </li>
                <li style={{ fontStyle: "italic" }}>
                  Enhancing the capacities of City Authorities, officials from
                  the Department of Public Health Engineering (DPHE), the Master
                  Trainers resource pool, and other sector stakeholders.
                </li>
                <li style={{ fontStyle: "italic" }}>
                  Designing Technology Models for Integrated Waste Management
                  (IWM).
                </li>
                <li style={{ fontStyle: "italic" }}>
                  Formulating CWIS service delivery Models.
                </li>
                <li style={{ fontStyle: "italic" }}>
                  Integrating CWIS considerations into new Government of
                  Bangladesh (GoB) projects.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Content4 = () => {
    return (
      <div style={{ padding: "20px 0" }}>
        <div className="content-container">
          <h2 className="text-center">{title}</h2>
          <div
            className="d-flex flex-column"
            style={{ gap: "10px", margin: "20px 0" }}
          >
            <h2 style={{ fontSize: "12px", fontWeight: 500 }}>
              CWIS/FSM Model Demonstration: CWIS service delivery models
              replicated for safely managed human waste, equity of service
              coverage cost, quality and improved viability.
            </h2>
            <p>
              The CWIS/FSM Model Demonstration focuses on replicating successful
              CWIS service delivery models for FSM to improve the quality,
              coverage, and viability of sanitation services in urban areas. By
              promoting safe sanitation practices, enhancing equity in service
              provision, and exploring innovative financing mechanisms, this
              approach aims to create healthier and more sustainable urban
              environments for all residents.
            </p>
            <p>
              The CWIS/FSM Model Demonstration focuses on replicating successful
              CWIS service delivery models for FSM, aiming to improve the
              viability, quality, and coverage of sanitation services while
              promoting equity. By showcasing effective CWIS models that
              integrate on-site and off- site sanitation solutions, stakeholders
              can identify best practices and strategies for adapting them to
              diverse urban contexts. This approach emphasizes the importance of
              safely managed human waste, advocating for the adoption of FSM
              technologies and practices that meet international standards.
              Furthermore, the initiative seeks to ensure equitable access to
              sanitation services for all urban residents, regardless of
              socio-economic status or location, by implementing targeted
              interventions and exploring innovative financing mechanisms.
              Ultimately, the CWIS/FSM Model Demonstration aims to create
              healthier and more sustainable urban environments through
              comprehensive and inclusive sanitation solutions.
            </p>
          </div>

          <div
            className="d-flex flex-column"
            style={{ gap: "10px", margin: "20px 0" }}
          >
            <h2 style={{ fontSize: "12px", fontWeight: 500 }}>FSM/IWM Model</h2>
            <Row>
              <Col sm="2">
                <div
                  className="sub-card"
                  onClick={() => handleClick("/iwmdetails", false)}
                >
                  <div className="px-2 pt-3 card-title">
                    <label
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        cursor: "pointer",
                      }}
                    >
                      FSM/IWM Model
                    </label>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div
            className="d-flex flex-column"
            style={{ gap: "10px", margin: "20px 0" }}
          >
            <h2 style={{ fontSize: "12px", fontWeight: 500 }}>
              Business Model
            </h2>
            <Row>
              <Col sm="2">
                <div
                  className="sub-card"
                  onClick={() => handleClick("", false)}
                >
                  <div className="px-2 pt-3 card-title">
                    <label
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        cursor: "pointer",
                      }}
                    >
                      Business Model
                    </label>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  };

  const Content5 = () => {
    return (
      <div style={{ padding: "20px 0" }}>
        <div className="content-container">
          <h2 className="text-center">{title}</h2>
          <div
            className="d-flex flex-column"
            style={{ gap: "10px", margin: "20px 0" }}
          >
            <h2 style={{ fontSize: "12px", fontWeight: 500 }}>
              Policy Advocacy: Established collective responsibility &amp;
              accountability through cross sector collaborations.
            </h2>
            <p>
              Policy advocacy in scaling up City-wide Inclusive sanitation
              involves{" "}
              <span style={{ fontWeight: "bold" }}>
                mobilizing stakeholders
              </span>{" "}
              to prioritize sanitation, fostering cross-sector collaborations,
              establishing collective responsibility, and enhancing
              accountability through transparent and participatory processes. By
              advocating for policies that promote comprehensive and sustainable
              sanitation solutions, stakeholders can create healthier, more
              resilient, and inclusive urban environments for all residents.
            </p>
            <p>
              In the quest to upscale City-wide Inclusive sanitation, policy
              advocacy emerges as a pivotal driver for fostering collective
              responsibility and accountability through collaborative cross-
              sector efforts. This multifaceted approach entails advocating for
              policies prioritizing sanitation as a vital public health and
              environmental concern, rallying diverse stakeholders towards
              common goals. Beginning with raising awareness among policymakers,
              government officials, and the public, policy advocacy emphasizes
              sanitation&#39;s profound impact on health, the environment, and
              the economy. It involves nurturing cross-sector collaborations to
              develop sustainable sanitation strategies tailored to urban
              complexities. By engaging stakeholders at all levels and ensuring
              alignment with community needs, policy advocacy cultivates a sense
              of collective ownership over sanitation initiatives.
            </p>
          </div>

          <div
            className="d-flex flex-column"
            style={{ gap: "10px", margin: "20px 0" }}
          >
            <h2 style={{ fontSize: "12px", fontWeight: 500 }}>
              Collective Endeavour towards A Model CWIS city: Lakshmipur
              Municipality
            </h2>
          </div>
        </div>
      </div>
    );
  };

  const Content6 = () => {
    return (
      <div style={{ padding: "20px 0" }}>
        <div className="content-container">
          <h2 className="text-center">{title}</h2>
          <div
            className="d-flex flex-column"
            style={{ gap: "10px", margin: "20px 0" }}
          >
            <h2 style={{ fontSize: "12px", fontWeight: 500 }}>
              Market Development: Advanced smart practices &amp; transformative
              technologies in water &amp; sanitation investments.
            </h2>
            <p>
              By promoting market development and encouraging the adoption of
              advanced smart practices and transformative technologies in water
              and sanitation investments, stakeholders can accelerate progress
              towards achieving City-wide Inclusive sanitation goals. This
              approach not only enhances the effectiveness and sustainability of
              sanitation systems but also drives economic growth, fosters
              innovation, and improves quality of life in urban areas.
            </p>
            <p>
              In the realm of scaling up City-wide Inclusive sanitation, market
              development emerges as a pivotal force driving the uptake of
              advanced smart practices and transformative technologies in water
              and sanitation investments. This comprehensive approach fosters an
              environment conducive to innovation, encourages private sector
              engagement, and advocates for the adoption of state-of-the-art
              solutions to tackle the intricate challenges of urban sanitation.
            </p>
            <div>
              <p>
                Key components of market development in City-wide Inclusive
                sanitation include:
              </p>
              <ul>
                <li style={{ fontStyle: "italic" }}>
                  <span style={{ fontWeight: "bold" }}>
                    Promotion of Advanced Smart Practices:{" "}
                  </span>
                  This involves leveraging technology and data analytics to
                  enhance the efficiency, effectiveness, and sustainability of
                  water and sanitation systems. Strategies encompass real-time
                  monitoring, predictive maintenance, and digital platforms for
                  stakeholder engagement.
                </li>
                <li style={{ fontStyle: "italic" }}>
                  <span style={{ fontWeight: "bold" }}>
                    Fostering Transformative Technologies:{" "}
                  </span>
                  Market development endeavors to support the growth of
                  innovative solutions to sanitation challenges, such as
                  decentralized treatment systems, resource recovery
                  technologies, and water recycling and reuse solutions.
                </li>
                <li style={{ fontStyle: "italic" }}>
                  <span style={{ fontWeight: "bold" }}>
                    Creation of an Enabling Regulatory Environment:{" "}
                  </span>
                  Efforts are directed towards establishing policies and
                  regulations that incentivize investment in innovative water
                  and sanitation technologies, offering financial incentives and
                  streamlining regulatory processes.
                </li>
                <li style={{ fontStyle: "italic" }}>
                  <span style={{ fontWeight: "bold" }}>
                    Capacity Building and Ecosystem Strengthening:{" "}
                  </span>
                  Market development initiatives aim to build the capacity of
                  local stakeholders and foster collaboration and
                  knowledge-sharing to support the adoption and scaling of
                  innovative water and sanitation technologies.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Styles>
      <div className="content-main">
        {/* Header 2 */}
        <Header />

        {/* Breadcroumb */}
        <BreadcrumbBox title={title || ""} img="2.jpg" />

        {/* Tab Section */}
        <div>{id == 1 && <Content1 />}</div>
        <div>{id == 2 && <Content2 />}</div>
        <div>{id == 3 && <Content3 />}</div>
        <div>{id == 4 && <Content4 />}</div>
        <div>{id == 5 && <Content5 />}</div>
        <div>{id == 6 && <Content6 />}</div>

        {/* Footer 2 */}
        <Footer />
      </div>
    </Styles>
  );
};

export default Content;
