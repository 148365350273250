export const teamData = {
  title: "CWIS Core Team",
  dataList: [
    {
      id: "1",
      personImage: "1_Abdullah Al Muyeed.jpeg",
      personName: "Dr Abdullah Al-Muyeed",
      personTitle: "Chief Operating Officer",
      email: "test@gmail.com",
      socialLinks: {
        facebook: "//www.facebook.com",
        twitter: "//www.twitter.com",
        linkedin: "//www.linkedin.com",
        youtube: "//www.youtube.com",
      },
    },
    {
      id: "2",
      personImage: "2_Sanjoy Mukherjee.jpg",
      personName: "Sanjoy Mukherjee",
      personTitle: "Social Development Expert",
      email: "test1@gmail.com",
      socialLinks: {
        facebook: "//www.facebook.com",
        twitter: "//www.twitter.com",
        linkedin: "//www.linkedin.com",
        youtube: "//www.youtube.com",
      },
    },
    {
      id: "3",
      personImage: "3_Md.Tawhidur Rahaman.jpg",
      personName: "Md.Tawhidur Rahaman",
      personTitle: "Technical Expert",
      email: "test2@gmail.com",
      socialLinks: {
        facebook: "//www.facebook.com",
        twitter: "//www.twitter.com",
        linkedin: "//www.linkedin.com",
        youtube: "//www.youtube.com",
      },
    },
    {
      id: "4",
      personImage: "4_Rifat Binte Jia.JPG",
      personName: "Rifat Binte Jia",
      personTitle: "Gender Expert",
      email: "test3@gmail.com",
      socialLinks: {
        facebook: "//www.facebook.com",
        twitter: "//www.twitter.com",
        linkedin: "//www.linkedin.com",
        youtube: "//www.youtube.com",
      },
    },
    {
      id: "5",
      personImage: "5_Monzur Morshed.jpg",
      personName: "Monzur Morshed",
      personTitle: "Business Expert",
      email: "test4@gmail.com",
      socialLinks: {
        facebook: "//www.facebook.com",
        twitter: "//www.twitter.com",
        linkedin: "//www.linkedin.com",
        youtube: "//www.youtube.com",
      },
    },
    {
      id: "6",
      personImage: "6_Sadekur Rahaman.jpg",
      personName: "Sadekur Rahaman",
      personTitle: "Accounts and Admin Coordinator",
      email: "test4@gmail.com",
      socialLinks: {
        facebook: "//www.facebook.com",
        twitter: "//www.twitter.com",
        linkedin: "//www.linkedin.com",
        youtube: "//www.youtube.com",
      },
    },
  ],
};
