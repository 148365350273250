import React from "react";
import { ShareSocial } from "react-share-social";
const style = {
  root: {
    borderRadius: 3,
    border: 0,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
  copyContainer: {},
  title: {
    color: "aquamarine",
    fontStyle: "italic",
  },
};
const SocialShare = () => {
  return (
    <ShareSocial
      title="Share this page on"
      url={window.location.href}
      socialTypes={["facebook", "twitter", "linkedin"]}
      style={style}
      onSocialButtonClicked={(data) => console.log(data)}
    />
  );
};

export default SocialShare;
