import styled from "styled-components";
import { colors } from "../../../components/common/element/elements";

export const Styles = styled.div`
  .about-page {
    .icon-box-area {
      padding-bottom: 72px;
      .full-icon-box {
        .icon-box {
          position: inherit;
          top: 0;
          left: 0;
          width: unset;
        }
      }

      @media (max-width: 767px) {
        padding: 0 0 10px;
      }
    }
    .custom-card {
      margin-bottom: 20px;
      //width: 400px;
      h2 {
        font-size: 14px;
        margin: 10px 0;
      }
      &:hover {
        transform: scale(1.1);
        transition: 0.5s;
        cursor: pointer;
      }
      .join-btn {
        margin-bottom: 15px;
        a {
          font-size: 12px;
          color: #fff;
          background: ${colors.gr_bg};
          font-weight: 500;
          text-transform: uppercase;
          padding: 9px 12px 7px;
          border-radius: 1rem;
          &:hover {
            color: #ffffff;
            background: ${colors.gr_bg2};
            border-color: ${colors.water};
          }

          @media (max-width: 767px) {
            float: left;
            padding: 5px 10px 3px;
          }
        }
      }
    }
  }
`;
