import React from "react";
import { Styles } from "./styles/genderequality";
import Datas from "../../data/gender-equality/genderEquality.json";
import Header from "../../components/Header";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import Footer from "../../components/Footer";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
const GenderEquality = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const playVideo = () => {
    setIsPlaying(true);
    const video = document.getElementById("video");
    video.play();
  };
  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper event-page">
        {/* Header 2 */}
        <Header />

        {/* Breadcroumb */}
        <BreadcrumbBox
          title="Gender Equality and Women Empowerment"
          img="GenderEqualityandWomenEmpowerment.png"
        />

        {/* main content */}
        <section className="event-page-area">
          <Container>
            <Row>
              <Col>
                <p
                  style={{
                    textAlign: "center",
                    padding: "30px 0",
                    fontWeight: "bold",
                    fontSize: "30px",
                    color: "black",
                  }}
                >
                  CWIS-FSM Support Cell&#39;s Gender-Driven Mission
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p
                  style={{
                    textAlign: "justify",
                    padding: "20px 0",
                    fontSize: "16px",
                    color: "black",
                    marginBottom: "30px",
                  }}
                >
                  At the core of the mission of the CWIS-FSM Support Cell lies a
                  profound commitment to integrating gender considerations into
                  its core values. The CWIS-FSM Support Cell plays a pivotal
                  role in providing strategic and technical oversight, adhering
                  to the seven principles of citywide inclusive sanitation and
                  the Citywide Inclusive Sanitation Service 2X3 Framework.
                  Guided by this framework and following the CWIS principal 2,
                  the CWIS-FSM Support Cell actively addresses gender, with a
                  specific focus on marginalized groups, especially women and
                  girls, people with disabilities (PWD) and sanitation and waste
                  workers. Within the CWIS ecosystem, the role of gender emerges
                  as a driving force for effective implementation, focusing on
                  the goal of mainstreaming gender through a transformative
                  approach. This integrated strategy sets the stage for a
                  society that is more just and equitable for all.
                </p>
              </Col>
            </Row>
            <div className="publication-container">
              {Datas.genderEqualityData.map((data, i) => (
                <ScrollAnimation
                  animateIn={i % 2 == 0 ? "fadeInLeft" : "fadeInRight"}
                  key={i + 1}
                >
                  <div className="event-box" key={i}>
                    <Row>
                      <Col xl="6" lg="6" md="0">
                        <div className="event-img">
                          <a href={data.link} target="_blank">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                `/assets/images/${data.img}`
                              }
                              alt=""
                              className="img-fluid"
                            />
                          </a>
                        </div>
                      </Col>
                      <Col>
                        <div
                          className="event-content"
                          style={{ width: "100%" }}
                        >
                          <div
                            // className="content-box"
                            className="content-box1"
                            style={{
                              width: "100%",
                            }}
                          >
                            <Row>
                              <Col md="9">
                                <div className="event-title">
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "20px",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    Case Study {i + 1}:
                                  </p>
                                  <h6>
                                    <a href={data.link} target="_blank">
                                      {data.title}
                                    </a>
                                  </h6>
                                </div>

                                <div className="event-desc">
                                  <p>{data.eventdesc}</p>
                                </div>

                                <div className="join-btn">
                                  <a
                                    href={data.link}
                                    target="_blank"
                                    rel="noreferrer"
                                    type="application/pdf"
                                  >
                                    <i className="fa fa-eye ">View</i>
                                  </a>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {/* <Col>
                    <Card className="custom-card" style={{ margin: "30px 0" }}>
                      <Card.Body>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                          }}
                        >
                          <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                            {" "}
                            Case Study 1:
                          </p>
                          <p style={{ fontWeight: "bold", fontSize: "15px" }}>
                            Gender Inclusive Waste Management Fosters Enabling
                            Environment for Waste Workers
                          </p>
                          <div className="join-btn">
                            <a
                              href="assets/docs/Case_Study_1.pdf"
                              target="_blank"
                            >
                              <i className="fa fa-eye "></i>
                              &nbsp;Click for Download
                            </a>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col> */}
                </ScrollAnimation>
              ))}
            </div>
          </Container>
        </section>

        {/* Footer 2 */}
        <Footer />
      </div>
    </Styles>
  );
};

export default GenderEquality;
