import React, { useState } from "react";
import { Styles } from "../award-event/styles/awardEvent";
import Header from "../../components/Header";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/Footer";
import "./styles/modal.css";
import Modal from "./Modal";
import Slider from "./Slider";

const Event1 = () => {
  const [show, setShow] = useState(false);
  const [active, setActive] = useState(0);

  const handleClick = (index) => {
    setActive(index);
    setShow(true);
  };

  const onClose = () => {
    setShow(false);
  };

  const images = [
    {
      image_url: "/assets/images/n_1.jpeg",
    },
    {
      image_url: "/assets/images/n_2.jpeg",
    },
    {
      image_url: "/assets/images/n_3.jpeg",
    },
    {
      image_url: "/assets/images/n_4.jpeg",
    },
    {
      image_url: "/assets/images/n_13.jpeg",
    },
    {
      image_url: "/assets/images/n_6.jpg",
    },
    {
      image_url: "/assets/images/n_7.jpeg",
    },
    {
      image_url: "/assets/images/n_8.jpeg",
    },
    {
      image_url: "/assets/images/n_9.jpeg",
    },
    {
      image_url: "/assets/images/n_10.jpeg",
    },
    {
      image_url: "/assets/images/n_11.jpeg",
    },
    {
      image_url: "/assets/images/n_12.jpeg",
    },
  ];

  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper event-page">
        {/* Header 2 */}
        <Header />

        {/* Breadcroumb */}
        <BreadcrumbBox title="Event" img="n_4.jpeg" />
        <Container>
          <Row>
            <Col>
              <a
                href="/news"
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  lineHeight: "40px",
                }}
              >
                Back To News
              </a>
            </Col>
          </Row>
        </Container>

        {/* News Area */}
        <section className="award-page">
          <Container>
            <Row>
              <Col md="12">
                <div style={{ padding: "20px 0" }}>
                  <h2
                    style={{
                      width: "100%",
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    “Empowering Change: CWIS Training Elevates Waste Management
                    Solutions in Bangladesh”
                  </h2>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: 500,
                      padding: "20px 0",
                    }}
                  >
                    Date: March 2nd and 3rd, 2024, Location: Saidpur, Nilphamari
                  </p>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        width: "70%",
                        maxHeight: "550px",
                      }}
                      src={process.env.PUBLIC_URL + `/assets/images/n_5.jpg`}
                      alt="award"
                    />
                  </div>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "20px 0",
                    }}
                  >
                    Training Division of the Department of Public Health
                    Engineering (DPHE) organized a two-day training on City-wide
                    Inclusive Sanitation (CWIS) approach focusing on Integrated
                    Waste Management (IWM). Held on March 2nd and 3rd, 2024, in
                    Saidpur, Nilphamari, this immersive experience on Integrated
                    Waste Management (IWM) works as the cornerstone for
                    advancing sanitation efforts including solid waste
                    management of Bangladesh. Executive Engineers from the
                    Department of Public Health Engineering (DPHE) convened to
                    explore the intricacies of IWM, aiming to fortify the CWIS
                    approach and accelerate progress towards SDG Goal 6.2. With
                    representation from 19 districts among the 23 participants,
                    the training fostered a rich exchange of insights and best
                    practices.
                  </p>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "20px 0",
                    }}
                  >
                    A highlight of the program was a field visit to the Saidpur
                    IWM plant, offering firsthand exposure to innovative waste
                    management techniques. Guided by experts from WaterAid
                    Bangladesh, participants gained valuable insights into
                    sustainable solutions that can be replicated on a larger
                    scale. Md. Shafiqul Hassan, Co-chair of CFSC, DPHE, and Md.
                    Anwar Yousuf, SE (Planning Circle), DPHE, alongside Alauddin
                    Ahmed from ITN-BUET and Dr. Abdullah Al Muyeed, COO of CFSC,
                    DPHE. Rukhsana Parveen, Executive Engineer, Training
                    division, DPHE, played a pivotal role in orchestrating the
                    event, ensuring its seamless execution and impactful
                    outcomes. As Bangladesh charts its course towards a cleaner,
                    more sustainable future, initiatives like these serve as
                    catalysts for meaningful change, driving us closer to our
                    collective vision of a healthier, more resilient society.
                    CWIS-FSM Support Cell, DPHE provided extended support
                    organizing this training program.
                  </p>

                  <Modal show={show} title="Images" onClose={onClose}>
                    <Slider
                      images={images}
                      active={active}
                      setActive={setActive}
                    />
                  </Modal>
                  {/* <div style={{ display: "grid", gridTemplateColumns:'repeat(4, 1fr)', gap:'10px' }}> */}

                  <div className="image-list">
                    {images.map((e, i) => (
                      <div
                        className={i === active ? "active" : ""}
                        onClick={() => handleClick(i)}
                        key={e.image_url}
                      >
                        <img
                          src={process.env.PUBLIC_URL + e.image_url}
                          alt={e.image_url}
                        />
                      </div>
                    ))}
                    {/* <img
                      style={{ width: "100%" }}
                      src={process.env.PUBLIC_URL + `/assets/images/n_1.jpeg`}
                      alt="session"
                    /> */}
                    {/* <img
                      style={{ width: "100%" }}
                      src={process.env.PUBLIC_URL + `/assets/images/n_2.jpeg`}
                      alt="session"
                    />
                    <img
                      style={{ width: "100%" }}
                      src={process.env.PUBLIC_URL + `/assets/images/n_3.jpeg`}
                      alt="session"
                    />
                    <img
                      style={{ width: "100%" }}
                      src={process.env.PUBLIC_URL + `/assets/images/n_4.jpeg`}
                      alt="session"
                    />

                    
                    <img
                      style={{ width: "100%" }}
                      src={process.env.PUBLIC_URL + `/assets/images/n_13.jpeg`}
                      alt="session"
                    />
                    <img
                      style={{ width: "100%" }}
                      src={process.env.PUBLIC_URL + `/assets/images/n_6.jpg`}
                      alt="session"
                    />
                    <img
                      style={{ width: "100%" }}
                      src={process.env.PUBLIC_URL + `/assets/images/n_7.jpeg`}
                      alt="session"
                    />
                    <img
                      style={{ width: "100%" }}
                      src={process.env.PUBLIC_URL + `/assets/images/n_8.jpeg`}
                      alt="session"
                    />
                    <img
                      style={{ width: "100%" }}
                      src={process.env.PUBLIC_URL + `/assets/images/n_9.jpeg`}
                      alt="session"
                    />
                    <img
                      style={{ width: "100%" }}
                      src={process.env.PUBLIC_URL + `/assets/images/n_10.jpeg`}
                      alt="session"
                    />
                    <img
                      style={{ width: "100%" }}
                      src={process.env.PUBLIC_URL + `/assets/images/n_11.jpeg`}
                      alt="session"
                    />
                    <img
                      style={{ width: "100%" }}
                      src={process.env.PUBLIC_URL + `/assets/images/n_12.jpeg`}
                      alt="session"
                    /> */}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </Styles>
  );
};

export default Event1;
