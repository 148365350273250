import React from "react";
import { Styles } from "./styles/iwmdetails";
import Header from "../../components/Header";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import Footer from "../../components/Footer";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import YouTube from "react-youtube";
import { data } from "./data";
import ScrollAnimation from "react-animate-on-scroll";

const IWMDetails = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const playVideo = () => {
    setIsPlaying(true);
    const video = document.getElementById("video");
    video.play();
  };
  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper about-page">
        {/* Header 2 */}
        <Header />

        {/* Breadcroumb */}
        <BreadcrumbBox title="IWM Details" img="2.jpg" />

        {/* main content */}
        <section className="details">
          <Container>
            <Row>
              <Col>
                <p
                  dangerouslySetInnerHTML={{
                    __html: data.text, //.replace(/\n/g, "<br/>"),
                  }}
                ></p>
              </Col>
            </Row>

            <Row xs={1} sm={1} md={1} lg={1} className="align-items-center">
              <Col
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Card className="custom-card" style={{ margin: "60px 0" }}>
                  <div className="card-image-container">
                    <a
                      href="https://dphe.portal.gov.bd/sites/default/files/files/dphe.portal.gov.bd/page/e2814cc6_d350_4384_95da_253fa34e3a42/2023-09-12-09-14-9923b08b4e2571a109a6e92e365a6d96.pdf"
                      target="_blank"
                    >
                      <Card.Img
                        variant="top"
                        src={
                          process.env.PUBLIC_URL + `/assets/images/bookBN.png`
                        }
                        className="card-image"
                      />
                    </a>
                  </div>
                  <Card.Body>
                    <h2>
                      <p>1. IWM Concept (Bangla)</p>
                    </h2>
                    <div className="join-btn">
                      <a
                        href="https://dphe.portal.gov.bd/sites/default/files/files/dphe.portal.gov.bd/page/e2814cc6_d350_4384_95da_253fa34e3a42/2023-09-12-09-14-9923b08b4e2571a109a6e92e365a6d96.pdf"
                        target="_blank"
                      >
                        <i className="fa fa-eye "></i>
                        &nbsp;Click for Download
                      </a>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="custom-card" style={{ margin: "60px 0" }}>
                  <div className="card-image-container">
                    <a
                      href="https://drive.google.com/file/d/12QZzkzRIw1uj1PXjHaylA0ZGgwPUKNWL/view"
                      target="_blank"
                    >
                      <Card.Img
                        variant="top"
                        src={
                          process.env.PUBLIC_URL + `/assets/images/bookEN.png`
                        }
                        className="card-image"
                      />
                    </a>
                  </div>
                  <Card.Body>
                    <h2>
                      <p>2. IWM Concept Synopsis (English)</p>
                    </h2>
                    <div className="join-btn">
                      <a
                        href="https://drive.google.com/file/d/12QZzkzRIw1uj1PXjHaylA0ZGgwPUKNWL/view"
                        target="_blank"
                      >
                        <i className="fa fa-eye "></i>
                        &nbsp;Click for Download
                      </a>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row style={{ margin: "20px 0" }}>
              <Col md={12} className="text-center ">
                <h2 style={{ margin: "30px 0", color: "black" }}>
                  IWM Concept Video
                </h2>
                <YouTube videoId="7ycizIgTbEU" />
              </Col>
            </Row>
          </Container>
        </section>

        {/* Footer 2 */}
        <Footer />
      </div>
    </Styles>
  );
};

export default IWMDetails;
