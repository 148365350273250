import React from "react";
import { Styles } from "../award-event/styles/awardEvent";
import Header from "../../components/Header";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/Footer";

const Event4 = () => {
  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper event-page">
        {/* Header 2 */}
        <Header />

        {/* Breadcroumb */}
        <BreadcrumbBox title="Event" img="n_4.jpeg" />
        <Container>
          <Row>
            <Col>
              <a
                href="/news"
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  lineHeight: "40px",
                }}
              >
                Back To News
              </a>
            </Col>
          </Row>
        </Container>

        {/* News Area */}
        <section className="award-page">
          <Container>
            <Row>
              <Col md="12">
                <div style={{ padding: "20px 0" }}>
                  <h2
                    style={{
                      width: "100%",
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    CWIS - FSM Newsletter
                  </h2>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        width: "95%",
                      }}
                      src={
                        process.env.PUBLIC_URL +
                        `/assets/images/CWIS_FSM_Newsletter.png`
                      }
                      alt="news"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </Styles>
  );
};

export default Event4;
