export const menuList = {
  menu: [
    {
      menuid: 1,
      parentid: 0,
      name: "Home",
      url: "/",
      children: [],
    },
    {
      menuid: 2,
      parentid: 0,
      name: "About Us",
      url: "/about",
      children: [
        // {
        //   menuid: 3,
        //   parentid: 2,
        //   name: 'CWIS',
        //   //url: "/about/cwis",
        //   url: '',
        //   children: [
        //     // {
        //     //   menuid: 17,
        //     //   parentid: 3,
        //     //   name: "Contact Us",
        //     //   url: "/contact-us",
        //     //   children: [],
        //     // },
        //   ],
        // },
        // {
        //   menuid: 4,
        //   parentid: 2,
        //   name: 'CWIS Support Cell',
        //   url: '/about/cwis-support-cell',
        //   children: [],
        // },
        // {
        //   menuid: 5,
        //   parentid: 2,
        //   name: 'Workshop',
        //   url: '/about/workshop',
        //   children: [],
        // },
      ],
    },
    {
      menuid: 11,
      parentid: 0,
      name: "CWIS Indicators",
      url: "",
      children: [
        {
          menuid: 12,
          parentid: 11,
          name: "CWIS Outcomes",
          url: "/indicators/outcomes",
          children: [],
        },
        {
          menuid: 13,
          parentid: 11,
          name: "CWIS Functions",
          url: "/indicators/functions",
          children: [],
        },
      ],
    },
    {
      menuid: 9,
      parentid: 0,
      name: "Activities",
      url: "",
      children: [
        {
          menuid: 18,
          parentid: 9,
          name: "Support to Govt",
          url: "/support-to-govt",
          children: [],
        },
        {
          menuid: 19,
          parentid: 9,
          name: "Workshop Seminars",
          url: "/workshop-seminar",
          children: [],
        },
      ],
    },
    {
      menuid: 6,
      parentid: 0,
      name: "Resources",
      url: "",
      children: [
        {
          menuid: 7,
          parentid: 6,
          name: "SANBOARD",
          url: "http://sanboard.gov.bd/",
          children: [],
          isHyperlink: true,
        },
        {
          menuid: 8,
          parentid: 6,
          name: "Shit Flow Diagram",
          url: "shit-flow-diagram",
          children: [],
          isHyperlink: false,
        },
        {
          menuid: 20,
          parentid: 6,
          name: "SFD Interactive Dashboard",
          url: "sfd-dashboard",
          children: [],
          isHyperlink: false,
        },
        {
          menuid: 20,
          parentid: 6,
          name: "DPHE Public Toilet Model",
          url: "public-toilet-model",
          children: [],
          isHyperlink: false,
        },
        {
          menuid: 21,
          parentid: 6,
          name: "DPHE Integrated Waste Management",
          url: "https://dphe.gov.bd/site/page/f2816782-3da7-449d-b460-bea594ed2b62/-",
          children: [],
          isHyperlink: true,
        },
      ],
    },
    {
      menuid: 14,
      parentid: 0,
      name: "Publication",
      url: "/publication",
      children: [],
    },
    {
      menuid: 10,
      parentid: 0,
      name: "News",
      url: "/news",
      children: [],
    },
    {
      menuid: 16,
      parentid: 0,
      name: "Contact Us",
      url: "/contact",
      children: [],
    },
  ],
};
