import React from "react";
import { Link } from "react-router-dom";
const MenuMobile = ({ menuData }) => {
  const renderMenuItems = (menuItems) => {
    return menuItems.map((item, index) => (
      <div key={index.toString()} className="mb-menu-item">
        <li
          className={`${
            item.children.length > 0 ? "mb-menu-button  active" : ""
          }`}
        >
          <p>
            {item.isHyperlink ? (
              <a className="" href={item.url} target="_blank">
                {item.name} {"  "}
                {item.children.length > 0 && (
                  <i className="las la-angle-down"></i>
                )}
              </a>
            ) : (
              <Link className="" to={process.env.PUBLIC_URL + item.url}>
                {item.name} {"  "}
                {item.children.length > 0 && (
                  <i className="las la-angle-down"></i>
                )}
              </Link>
            )}
          </p>
        </li>

        {item.children.length > 0 && (
          <div className="mb-menu-content show">
            <ul className="list-unstyled">{renderMenuItems(item.children)}</ul>
          </div>
        )}
      </div>
    ));
  };
  return <ul>{renderMenuItems(menuData.menu)}</ul>;
};

export default MenuMobile;
