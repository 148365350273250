export const data = [
  {
    Name: "LAKSAM",
    Responsibility: 37.3,
    Accountability: 11.8,
    Resourcing: 37,
  },
  {
    Name: "LAXMIPUR",
    Responsibility: 67,
    Accountability: 79.6,
    Resourcing: 78.9,
  },
  { Name: "FENI", Responsibility: 31.8, Accountability: 8.3, Resourcing: 43.3 },
  {
    Name: "NABINAGAR",
    Responsibility: 97.3,
    Accountability: 86.1,
    Resourcing: 63.3,
  },
  {
    Name: "CHATKHIL",
    Responsibility: 35.5,
    Accountability: 27,
    Resourcing: 32.6,
  },
  {
    Name: "Faridpur",
    Responsibility: 44.8,
    Accountability: 24.5,
    Resourcing: 59.375,
  },
  {
    Name: "Jamalpur",
    Responsibility: 27.3,
    Accountability: 7,
    Resourcing: 19.4,
  },
  {
    Name: "Jhenaidah",
    Responsibility: 65.5,
    Accountability: 43.3,
    Resourcing: 57.9,
  },
  {
    Name: "Kushtia",
    Responsibility: 77.3,
    Accountability: 40.3,
    Resourcing: 66.8,
  },
  {
    Name: "Meherpur",
    Responsibility: 71.5,
    Accountability: 55.3,
    Resourcing: 85.6,
  },
  {
    Name: "Saidpur",
    Responsibility: 39.4,
    Accountability: 21.4,
    Resourcing: 39.4,
  },
  {
    Name: "Sakhipur",
    Responsibility: 78.8,
    Accountability: 40.8,
    Resourcing: 29.2,
  },
  {
    Name: "Tarabo",
    Responsibility: 53.3,
    Accountability: 40.6,
    Resourcing: 68.3,
  },
  {
    Name: "Teknaf",
    Responsibility: 39.7,
    Accountability: 20,
    Resourcing: 60.3,
  },
  {
    Name: "Shibganj",
    Responsibility: 32.7,
    Accountability: 1.3,
    Resourcing: 13.9,
  },
  {
    Name: "Jessore",
    Responsibility: 85.8,
    Accountability: 78,
    Resourcing: 95.8,
  },
  {
    Name: "Ghatail",
    Responsibility: 18.5,
    Accountability: 29.1,
    Resourcing: 29.5,
  },
  {
    Name: "Lalmonirhat",
    Responsibility: 63.3,
    Accountability: 3.3,
    Resourcing: 32.5,
  },
];
