import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
  .sfd-dashboard {
    .chart {
      .bar-title {
        width: 100%;
        text-align: center;
        font-weight: 500;
        font-size: 11px;
      }
    }
    .recharts-wrapper {
      .recharts-legend-wrapper {
        bottom: -20px !important;
      }
    }
  }
`;
