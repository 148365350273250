import React from "react";
import { Styles } from "./styles/awardEvent";
import Header from "../../components/Header";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/Footer";

const AwardEvent = () => {
  const handleClick = () => {};
  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper event-page">
        {/* Header 2 */}
        <Header />

        {/* Breadcroumb */}
        <BreadcrumbBox title="Award" img="3.jpg" />
        <Container>
          <Row>
            <Col>
              <a
                href="/news"
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  lineHeight: "40px",
                }}
              >
                Back To News
              </a>
            </Col>
          </Row>
        </Container>

        {/* News Area */}
        <section className="award-page">
          <Container>
            <Row>
              <Col md="12">
                <div style={{ padding: "20px 0" }}>
                  <h2
                    style={{
                      width: "100%",
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    Dr. Abdullah Al-Muyeed Honored with Inclusive Sanitation
                    Champion Award 2023
                  </h2>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: 500,
                      padding: "20px 0",
                    }}
                  >
                    Date: 14/12/2023, Location: IWA Congress in Kigali, Rwanda
                  </p>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        width: "70%",
                        maxHeight: "500px",
                      }}
                      src={
                        process.env.PUBLIC_URL + `/assets/images/news14.jpeg`
                      }
                      alt="award"
                    />
                  </div>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "20px 0",
                    }}
                  >
                    In a momentous event at the IWA Congress in Kigali, Rwanda,
                    Dr. Abdullah Al-Muyeed, the Chief Operating Officer of
                    CWIS-FSM Support Cell, Department of Public Health
                    Engineering (DPHE) in Bangladesh, was bestowed with the
                    prestigious Inclusive Sanitation Champion Award 2023 by the
                    International Water Association. Recognized for Dr. Muyeed's
                    groundbreaking Integrated Waste Management (IWM) service
                    delivery model, addressing both faecal sludge and solid
                    waste simultaneously, his sustainable technological solution
                    aligns with the Citywide Inclusive Sanitation (CWIS)
                    approach. Successfully implemented in Sakhipur and Saidpur
                    Municipalities, this innovative service delivery model is
                    now being replicated across various municipalities in
                    DPHE-led projects in Bangladesh, as well as in several South
                    Asian and African countries, particularly where the land is
                    limited to use. Ms. Farzana Mannan, Joint Secretary, Local
                    Government Division was graciously present during the award
                    giving ceremony.
                  </p>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "20px 0",
                    }}
                  >
                    The CWIS-FSM Support Cell has been established by the
                    Ministry of Local Government, Rural Development and
                    Co-operatives of the Government of Bangladesh and hosted in
                    Department of Public Health Engineering (DPHE). The Cell is
                    working to enable systematic change of inclusive sanitation
                    journey of Bangladesh to deliver inclusive sanitation. It is
                    supported by the Bill and Melinda Gates Foundation.
                  </p>

                  <img
                    style={{ width: "100%" }}
                    src={
                      process.env.PUBLIC_URL + `/assets/images/news14_1.jpeg`
                    }
                    alt="session"
                  />
                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "20px 0",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      2023 Inclusive Sanitation Champion Award
                    </span>{" "}
                    winner{" "}
                    <span style={{ fontWeight: "bold" }}>
                      Dr. Abdullah Al-Muyeed
                    </span>{" "}
                    from CWIS FSM Support Cell, DPHE with Ms. Farzana Mannan,
                    Joint Secretary, Local Government Division Government of
                    Bangladesh and other participants from DPHE, ITN-BUET and
                    WaterAid at IWA Congress in Kigali, Rwanda
                  </p>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      style={{ width: "45%" }}
                      src={
                        process.env.PUBLIC_URL + `/assets/images/news14_2.jpeg`
                      }
                      alt="session"
                    />
                  </div>
                  <p
                    style={{
                      width: "80%",
                      textAlign: "center",
                      fontSize: "16px",
                      color: "black",
                      padding: "20px 0",
                      margin: "0 auto",
                    }}
                  >
                    Dr.Abdullah Al-Muyeed with Ms. Farzana Mannan, Joint
                    Secretary, Local Government Division, Government of
                    Bangladesh at IWA Congress in Kigali, Rwanda
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </Styles>
  );
};

export default AwardEvent;
