import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/activityComponent";

class EcosystemComponent extends Component {
  render() {
    return (
      <Styles>
        {/* About Us */}
        <section
          className="ecosystem"
          style={{ margin: "20px 0", padding: "0 5px" }}
        >
          <Container>
            <Row>
              <div style={{ width: "100%", marginBottom: "20px" }}>
                <h4
                  style={{
                    backgroundColor: "#d0021b",
                    color: "white",
                    textAlign: "center",
                    width: "100%",
                    padding: "5px 0",
                  }}
                >
                  CWIS Ecosystem Key components
                </h4>
                <p
                  style={{
                    backgroundColor: "#0b563b",
                    color: "white",
                    textAlign: "center",
                    width: "100%",
                    padding: "5px 0",
                  }}
                >
                  The CWIS-FSM Support Cell's strategic framework outlines a{" "}
                  <br />
                  collaborative approach involving national and local
                  authorities, NGOs, CSOs,
                  <br /> and training institutes.
                </p>
              </div>
            </Row>
            <Row>
              <p style={{ fontSize: 16 }}>
                The Government of Bangladesh is committed to accelerating the
                journey of universal access to safe sanitation by 2030 with
                Citywide Inclusive Sanitation (CWIS) approach. With a focus on
                excellence in service delivery, particularly for the poor,
                vulnerable, and marginalized, the government is prioritizing the
                strengthening of core public system functions related to
                responsibility, accountability, resource planning, and
                management to ensure equitable, safe, and sustainable sanitation
                outcomes.{" "}
              </p>
              <p style={{ fontSize: 16, marginTop: 20, marginBottom: 20 }}>
                CWIS-FSM Support Cell is providing valuable insights into
                Bangladesh's sanitation journey, showcasing the latest
                initiatives by the government and its partners. Through this
                platform, this Cell is acting as a nodal agency which aims to
                highlight leading practices, foster dialogue and collaboration,
                and advocate for enhanced stakeholder participation towards
                achieving Sustainable Development Goal 6.2: By 2030, achieve
                access to adequate and equitable sanitation and hygiene for all
                and end open defecation, paying special attention to the needs
                of women and girls and those in a vulnerable situation.
              </p>
            </Row>
            <Row>
              <img
                src={process.env.PUBLIC_URL + `/assets/images/ecosystem.png`}
                className="ecosystem"
                alt=""
                style={{ width: "80%", margin: "0 auto" }}
              />
            </Row>
          </Container>
        </section>
      </Styles>
    );
  }
}

export default EcosystemComponent;
