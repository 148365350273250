export const data = [
  { Name: "LAKSAM", Equity: 96.3, Safety: 70.4, Sustainability: 45 },
  { Name: "LAXMIPUR", Equity: 81.3, Safety: 69.5, Sustainability: 80.8 },
  { Name: "FENI", Equity: 76.5, Safety: 39.4, Sustainability: 41.7 },
  { Name: "NABINAGAR", Equity: 95, Safety: 79.5, Sustainability: 100 },
  { Name: "CHATKHIL", Equity: 62.5, Safety: 31.3, Sustainability: 25 },
  { Name: "Faridpur", Equity: 83.8, Safety: 52.1, Sustainability: 80 },
  { Name: "Jamalpur", Equity: 20, Safety: 19.7, Sustainability: 20.8 },
  { Name: "Jhenaidah", Equity: 76.3, Safety: 43.7, Sustainability: 46.7 },
  { Name: "Kushtia", Equity: 72.5, Safety: 54.7, Sustainability: 36.7 },
  { Name: "Meherpur", Equity: 66.3, Safety: 51.2, Sustainability: 53.3 },
  { Name: "Saidpur", Equity: 38.8, Safety: 51.2, Sustainability: 30 },
  { Name: "Sakhipur", Equity: 52.5, Safety: 81.5, Sustainability: 86.7 },
  { Name: "Tarabo", Equity: 58.8, Safety: 23.2, Sustainability: 20 },
  { Name: "Teknaf", Equity: 38.8, Safety: 44.7, Sustainability: 28.3 },
  { Name: "Shibganj", Equity: 58.8, Safety: 19.3, Sustainability: 53.3 },
  { Name: "Jessore", Equity: 72.5, Safety: 78.5, Sustainability: 73.3 },
  { Name: "Ghatail", Equity: 61.3, Safety: 12.4, Sustainability: 20 },
  { Name: "Lalmonirhat", Equity: 46.3, Safety: 55.4, Sustainability: 57.5 },
];
