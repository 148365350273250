import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/mobileMenu.js";
import MenuMobile from "./menu/MenuMobile.js";
import { menuList } from "../../data/menu/menuList.js";

function MobileMenu() {
  useEffect(() => {
    // Mobile Menu
    const hmBtn = document.getElementById("mb-sidebar-btn");

    if (hmBtn) {
      const mdSidebarOverlay = document.getElementById("mb-sidebar-overlay");
      const mdSidebarBody = document.getElementById("mb-sidebar-body");
      const mdSidebarExit = document.getElementById("close-mb-sidebar");

      hmBtn.addEventListener("click", function (e) {
        e.preventDefault();
        mdSidebarOverlay.classList.toggle("visible");
        mdSidebarBody.classList.toggle("opened");
      });

      mdSidebarOverlay.addEventListener("click", function (e) {
        e.preventDefault();
        mdSidebarOverlay.classList.remove("visible");
        mdSidebarBody.classList.remove("opened");
      });

      mdSidebarExit.addEventListener("click", function (e) {
        e.preventDefault();
        mdSidebarOverlay.classList.remove("visible");
        mdSidebarBody.classList.remove("opened");
      });
    }

    // Menu Accordion -----------------
    const menuButton = document.querySelectorAll(".mb-menu-button");
    menuButton.forEach((button) => {
      button.addEventListener("click", () => {
        button.classList.toggle("active");
        const content = button.nextElementSibling;

        if (button.classList.contains("active")) {
          content.className = "mb-menu-content show";
          content.style.maxHeight = content.scrollHeight + "px";
        } else {
          content.className = "mb-menu-content";
          content.style.maxHeight = "0";
        }
      });
    });
  });

  return (
    <Styles>
      {/* Mobile Menu */}
      <section className="mobile-menu-area">
        <Container>
          <Row>
            <Col md="0" sm="12">
              <div className="mb-logo-area d-flex justify-content-between">
                <div className="mb-logo-box d-flex" style={{ width: "100%" }}>
                  <div className="hm-button">
                    <a href={process.env.PUBLIC_URL + "/"} id="mb-sidebar-btn">
                      <i className="las la-bars"></i>
                    </a>
                  </div>
                  <div className="mb-logo" style={{ width: "100%" }}>
                    <div
                      className="logo"
                      style={{
                        marginTop: "-12px",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <a
                        href={process.env.PUBLIC_URL + "/"}
                        onClick={() => {
                          window.location.reload();
                        }}
                        style={{ width: "100%" }}
                      >
                        <div className="d-flex align-items-center justify-content-between ">
                          <img
                            style={{
                              width: "60px",
                              height: "60px",
                            }}
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/logo-bd.png"
                            }
                            alt=""
                          />
                          <img
                            style={{
                              width: "80px",
                              height: "70px",
                            }}
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/logo-company.png"
                            }
                            alt=""
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                {/* <div className="mb-search-box">
                  <form action="#">
                    <input
                      type="text"
                      name="search"
                      placeholder="Search Here"
                    />
                    <button type="submit">
                      <i className="las la-search"></i>
                    </button>
                  </form>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Mobile Menu Sidebar */}
      <section className="mb-sidebar" id="mb-sidebar-body">
        <div className="mb-sidebar-heading d-flex justify-content-between">
          <div>
            <h5>Menu</h5>
          </div>
          <div>
            <a href={process.env.PUBLIC_URL + "/"} id="close-mb-sidebar">
              <i className="las la-times"></i>
            </a>
          </div>
        </div>
        <div className="mb-sidebar-menu">
          <MenuMobile menuData={menuList} />
        </div>
      </section>
      <div className="mb-sidebar-overlay" id="mb-sidebar-overlay"></div>
    </Styles>
  );
}

export default MobileMenu;
