import React, { Component } from "react";
import Datas from "../data/footer/footer.json";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import BackToTop from "./common/BackToTop";
import { Styles } from "./styles/footerOne.js";
import { colors } from "./common/element/elements";
import SocialShare from "./SocialShare.js";

class Footer extends Component {
  render() {
    return (
      <Styles>
        {/* Footer Area */}
        <footer className="footer1">
          <Container>
            <Row>
              <Col md="6">
                <div className="footer-logo-info">
                  <img
                    style={{
                      width: "100px",
                      height: "90px",
                    }}
                    src={
                      process.env.PUBLIC_URL + "/assets/images/logo-company.png"
                    }
                    alt=""
                    className="img-fluid"
                  />
                  <p>Department of Public Health Engineering (DPHE)</p>
                  <ul className="list-unstyled">
                    <li>
                      <i className="las la-map-marker"></i>
                      DPHE Bhaban ( 9th Floor ),
                      <br />
                      14 Shaheed Captain Mansur Ali Sarani,Kakrail, Dhaka-1000,
                      Bangladesh
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md="6">
                <div
                  style={{ marginTop: "50px", width: "100%" }}
                  className="f-links  "
                >
                  <h5>Useful Links</h5>
                  <ul className="list-unstyled">
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/about"}>
                        <i className="las la-angle-right"></i>
                        About us
                      </Link>
                    </li>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/support-to-govt"}>
                        <i className="las la-angle-right"></i>
                        Support to Govt
                      </Link>
                    </li>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/news"}>
                        <i className="las la-angle-right"></i>
                        News
                      </Link>
                    </li>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/publication"}>
                        <i className="las la-angle-right"></i>
                        Publications
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <SocialShare />
              </Col>
            </Row>
          </Container>
        </footer>

        {/* Copyright Area */}
        <section className="copyright-area">
          <Container>
            <Row>
              <Col md="6">
                <div className="copy-text">
                  <p>
                    Copyright &copy; {new Date().getFullYear()} | CWIS FSM
                    Support Cell
                  </p>
                </div>
              </Col>
              <Col md="6" className="text-right">
                {/* <ul className="social list-unstyled list-inline">
                  <li className="list-inline-item">
                    <a href={process.env.PUBLIC_URL + "/"}>
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href={process.env.PUBLIC_URL + "/"}>
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://www.linkedin.com/company/softshore-tech/?originalSubdomain=bd">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul> */}
                <p
                  style={{
                    fontSize: "20px",
                    color: "#FFF",
                    fontWeight: "bold",
                    fontStyle: "italic",
                  }}
                >
                  Supported by Bill & Melinda Gates Foundation
                </p>
              </Col>
            </Row>
          </Container>

          {/* Back To Top */}
          <BackToTop />
        </section>
      </Styles>
    );
  }
}

export default Footer;
