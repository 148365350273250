import React, { Component } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import HomeAbout from "../../components/HomeAbout";
import Banner from "./_components/Banner";
import { Styles } from "./styles/homeStyle";
import ContentWrapper from "./_components/ContentWrapper";

export default class HomeMain extends Component {
  render() {
    return (
      <Styles>
        <div className="home-main">
          <Header />
          <Banner />
          <ContentWrapper />
          <HomeAbout />
          <Footer />
        </div>
      </Styles>
    );
  }
}
