import React from "react";
import { Styles } from "./styles/cwisscoring.js";
import "leaflet/dist/leaflet.css";
import { MapContainer, Polygon, TileLayer } from "react-leaflet";
import data from "./data.json";
import { CWISFunctionBar } from "./cwisFunctionBar";
import { CWISOutcomeBar } from "./cwisOutcomeBar";
import Header from "../../components/Header.js";
import { Container } from "react-bootstrap";
import Footer from "../../components/Footer.js";
import { BreadcrumbBox } from "../../components/common/Breadcrumb.js";

const CWISScoring = () => {
  return (
    <Styles>
      <div className="main-wrapper scoring-page">
        <Header />
        <BreadcrumbBox title="CWIS Scoring" img="" />
        <div>
          <Container>
            <img
              src={process.env.PUBLIC_URL + "/assets/images/cwis-scoring.jpg"}
              style={{ width: "100%" }}
            />
            <div className="map-wrapper">
              <MapContainer
                center={[23.777176, 90.399452]}
                zoom={8}
                style={{ width: "100%", height: "100%" }}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution="© OpenStreetMap contributors"
                />

                {data &&
                  data.features.map((area) => {
                    const coor = area.geometry.rings[0].map((x) => [
                      x[1],
                      x[0],
                    ]);
                    return (
                      <Polygon
                        key={Math.random()}
                        pathOptions={{
                          fillColor: "green",
                          fillOpacity: 0.7,
                          weight: 2,
                          opacity: 1,
                          dashArray: 1,
                          color: "red",
                        }}
                        positions={coor}
                      />
                    );
                  })}
                {/* <TileLayer
          url="https://api.maptiler.com/maps/basic-v2/256/{z}/{x}/{y}.png?key=Em2CwSbjENEP7ExwSWq0" // Use OpenStreetMap as the base map
          attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
        ></TileLayer>
        <GeoJSON data={data} /> */}
              </MapContainer>
            </div>
          </Container>
          {/* for charts */}
          <div className="chart-wrapper">
            <Container>
              <CWISFunctionBar />
              <CWISOutcomeBar />
            </Container>
          </div>
        </div>

        <Footer />
      </div>
    </Styles>
  );
};

export default CWISScoring;
