import React from "react";
import { Col, Row } from "react-bootstrap";
import ContentCard from "./ContentCard";

const ContentWrapper = () => {
  return (
    <div className="content-wrapper">
      <Row>
        <Col xs="12" sm={"6"} md="4" lg="4">
          <ContentCard
            id="1"
            icon="1.png"
            title="Urban Sanitation Financing"
            subtitle="Optimized resource mobilization for national planning"
          />
        </Col>

        <Col xs="12" sm={"6"} md="4" lg="4">
          <ContentCard
            id="2"
            icon="2.png"
            title="Data Utilization"
            subtitle="Data-use culture & data utilization for allocating resources and tracking process"
          />
        </Col>

        <Col xs="12" sm={"6"} md="4" lg="4">
          <ContentCard
            id="3"
            icon="4.png"
            title="Capacity Building"
            subtitle="Strengthened workforce for citywide inclusive sanitation service delivery"
          />
        </Col>

        <Col xs="12" sm={"6"} md="4" lg="4">
          <ContentCard
            id="4"
            icon="4.png"
            title="CWIS/FAM Model demonstration"
            subtitle="CWIS Service delivery models replicated for safety managed human waste, euity of service coverage cos, quality and improved viablity"
          />
        </Col>

        <Col xs="12" sm={"6"} md="4" lg="4">
          <ContentCard
            id="5"
            icon="5.png"
            title="Policy Advocacy"
            subtitle="Established collective responsiblity & accountablity through cross sector collaborations"
          />
        </Col>

        <Col xs="12" sm={"6"} md="4" lg="4">
          <ContentCard
            id="6"
            icon="6.png"
            title="Market Development"
            subtitle="Advanced smart practises & transformative echnologies in water & sanitation investments"
          />
        </Col>
      </Row>
    </div>
  );
};

export default ContentWrapper;
