import styled from "styled-components";

export const Styles = styled.div`
  .scoring-page {
    overflow: hidden;
    .map-wrapper {
      border: 2px solid lightgray;
      border-radius: 8px;
      padding: 10px;
      margin-top: 20px;
      margin-bottom: 20px;
      height: 500px;
    }
    .chart-wrapper {
      margin: 20px 0;
      .bar-title {
        width: 100%;
        text-align: center;
        font-weight: 500;
        font-size: 20px;
      }
      .recharts-surface {
        height: 98% !important;
      }
      .recharts-legend-wrapper {
        bottom: 22px !important;
      }
    }
  }
`;
