export const data = [
  {
    name: "Araihazar Municipality",
    total_safe: 9,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 9,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 9,
    Onsite_not_delivered_to_treatment: 68,
  },
  {
    name: "Bagerhat Municipality",
    total_safe: 19,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 19,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 19,
    Onsite_not_delivered_to_treatment: 64,
  },
  {
    name: "Bandarban Municipality",
    total_safe: 18,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 18,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 18,
    Onsite_not_delivered_to_treatment: 68,
  },
  {
    name: "Barguna Municipality",
    total_safe: 24,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 24,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 24,
    Onsite_not_delivered_to_treatment: 52,
  },
  {
    name: "Barishal City Corporation",
    total_safe: 25,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 25,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 25,
    Onsite_not_delivered_to_treatment: 50,
  },
  {
    name: "Bauphal Municipality",
    total_safe: 21,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 21,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 21,
    Onsite_not_delivered_to_treatment: 65,
  },
  {
    name: "Betagi Municipality",
    total_safe: 21,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 21,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 21,
    Onsite_not_delivered_to_treatment: 67,
  },
  {
    name: "Bhola Municipality (vn 2022)",
    total_safe: 26,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 26,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 26,
    Onsite_not_delivered_to_treatment: 50,
  },
  {
    name: "Bogra Municipality",
    total_safe: 19,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 19,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 19,
    Onsite_not_delivered_to_treatment: 72,
  },
  {
    name: "Brahmanbaria Municipality",
    total_safe: 13,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 13,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 13,
    Onsite_not_delivered_to_treatment: 53,
  },
  {
    name: "Chandpur Municipality",
    total_safe: 20,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 20,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 20,
    Onsite_not_delivered_to_treatment: 45,
  },
  {
    name: "Chapai Nawabganj Municipality (vn 2022)",
    total_safe: 23,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 23,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 23,
    Onsite_not_delivered_to_treatment: 65,
  },
  {
    name: "Chuadanga Municipality",
    total_safe: 27,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 27,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 27,
    Onsite_not_delivered_to_treatment: 64,
  },
  {
    name: "Coxs Bazar Municipality",
    total_safe: 20,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 20,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 26,
    Onsite_not_delivered_to_treatment: 50,
  },
  {
    name: "Cumilla City Corporation (vn 2022)",
    total_safe: 14,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 14,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 20,
    Onsite_not_delivered_to_treatment: 64,
  },
  {
    name: "Dinajpur Municipality",
    total_safe: 23,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 23,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 23,
    Onsite_not_delivered_to_treatment: 68,
  },
  {
    name: "Faridpur Municipality",
    total_safe: 50,
    Onsite_treated: 14,
    Onsite_contained_but_not_emptied: 36,
    Onsite_delivered_to_treatment_but_not_treated: 1,
    Onsite_not_contained_and_not_emptied: 12,
    Onsite_not_delivered_to_treatment: 34,
  },
  {
    name: "Feni Municipality",
    total_safe: 8,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 8,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 33,
    Onsite_not_delivered_to_treatment: 54,
  },
  {
    name: "Gaibandha Municipality",
    total_safe: 24,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 24,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 2,
    Onsite_not_delivered_to_treatment: 71,
  },
  {
    name: "Galachipa Municipality",
    total_safe: 20,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 20,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 14,
    Onsite_not_delivered_to_treatment: 51,
  },
  {
    name: "Gazipur (vn 2022)",
    total_safe: 26,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 26,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 15,
    Onsite_not_delivered_to_treatment: 49,
  },
  {
    name: "Gopalganj Municipality",
    total_safe: 26,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 26,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 11,
    Onsite_not_delivered_to_treatment: 63,
  },
  {
    name: "Homna Municipality",
    total_safe: 9,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 9,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 3,
    Onsite_not_delivered_to_treatment: 88,
  },
  {
    name: "Ishwardi Municipality",
    total_safe: 18,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 18,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 10,
    Onsite_not_delivered_to_treatment: 66,
  },
  {
    name: "Jamalpur, Bangladesh",
    total_safe: 1,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 1,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 43,
    Onsite_not_delivered_to_treatment: 53,
  },
  {
    name: "Jhenaidah Municipality",
    total_safe: 29,
    Onsite_treated: 10,
    Onsite_contained_but_not_emptied: 19,
    Onsite_delivered_to_treatment_but_not_treated: 1,
    Onsite_not_contained_and_not_emptied: 12,
    Onsite_not_delivered_to_treatment: 58,
  },
  {
    name: "Joypurhat Municipality (vn 2022)",
    total_safe: 33,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 33,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 8,
    Onsite_not_delivered_to_treatment: 55,
  },
  {
    name: "Kaliakair Municipality",
    total_safe: 11,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 11,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 36,
    Onsite_not_delivered_to_treatment: 39,
  },
  {
    name: "Kalihati Municipality",
    total_safe: 26,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 26,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 6,
    Onsite_not_delivered_to_treatment: 65,
  },
  {
    name: "Kishoreganj Municipality",
    total_safe: 9,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 9,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 26,
    Onsite_not_delivered_to_treatment: 58,
  },
  {
    name: "Lakshmipur Municipality",
    total_safe: 30,
    Onsite_treated: 14,
    Onsite_contained_but_not_emptied: 16,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 22,
    Onsite_not_delivered_to_treatment: 45,
  },
  {
    name: "Lalmonirhat Municipality",
    total_safe: 17,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 17,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 1,
    Onsite_not_delivered_to_treatment: 75,
  },
  {
    name: "Lama Municipality",
    total_safe: 10,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 10,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 7,
    Onsite_not_delivered_to_treatment: 61,
  },
  {
    name: "Magura Municipality",
    total_safe: 17,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 17,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 7,
    Onsite_not_delivered_to_treatment: 74,
  },
  {
    name: "Meherpur Municipality (vn 2022)",
    total_safe: 16,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 16,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 7,
    Onsite_not_delivered_to_treatment: 76,
  },
  {
    name: "Naogaon Municipality (vn 2022)",
    total_safe: 30,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 30,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 4,
    Onsite_not_delivered_to_treatment: 63,
  },
  {
    name: "Narayanganj City Corporation (vn 2022)",
    total_safe: 10,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 10,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 12,
    Onsite_not_delivered_to_treatment: 55,
  },
  {
    name: "Narsingdi Municipality",
    total_safe: 2,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 2,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 26,
    Onsite_not_delivered_to_treatment: 55,
  },
  {
    name: "Natore Municipality",
    total_safe: 22,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 22,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 4,
    Onsite_not_delivered_to_treatment: 71,
  },
  {
    name: "Nilphamari Municipality (vn 2022)",
    total_safe: 23,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 23,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 5,
    Onsite_not_delivered_to_treatment: 64,
  },
  {
    name: "Noakhali Municipality",
    total_safe: 15,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 15,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 16,
    Onsite_not_delivered_to_treatment: 64,
  },
  {
    name: "Pabna Municipality",
    total_safe: 2,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 2,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 46,
    Onsite_not_delivered_to_treatment: 50,
  },
  {
    name: "Paikgachha Municipality",
    total_safe: 24,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 24,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 8,
    Onsite_not_delivered_to_treatment: 68,
  },
  {
    name: "Panchagarh Municipality",
    total_safe: 22,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 22,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 2,
    Onsite_not_delivered_to_treatment: 75,
  },
  {
    name: "Patuakhali Municipality",
    total_safe: 12,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 12,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 23,
    Onsite_not_delivered_to_treatment: 60,
  },
  {
    name: "Rajbari Municipality",
    total_safe: 19,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 19,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 5,
    Onsite_not_delivered_to_treatment: 75,
  },
  {
    name: "Rangamati Municipality",
    total_safe: 13,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 13,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 13,
    Onsite_not_delivered_to_treatment: 70,
  },
  {
    name: "Shariatpur Municipality",
    total_safe: 15,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 15,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 13,
    Onsite_not_delivered_to_treatment: 69,
  },
  {
    name: "Singair Municipality",
    total_safe: 24,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 24,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 5,
    Onsite_not_delivered_to_treatment: 69,
  },
  {
    name: "Sirajganj Municipality",
    total_safe: 29,
    Onsite_treated: 9,
    Onsite_contained_but_not_emptied: 20,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 1,
    Onsite_not_delivered_to_treatment: 65,
  },
  {
    name: "Sonargaon Municipality",
    total_safe: 25,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 25,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 13,
    Onsite_not_delivered_to_treatment: 56,
  },
  {
    name: "Sreepur Municipality",
    total_safe: 15,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 15,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 22,
    Onsite_not_delivered_to_treatment: 45,
  },
  {
    name: "Sylhet City Corporation (vn 2022)",
    total_safe: 27,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 27,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 8,
    Onsite_not_delivered_to_treatment: 60,
  },
  {
    name: "Tarabo Municipality",
    total_safe: 6,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 6,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 18,
    Onsite_not_delivered_to_treatment: 58,
  },
  {
    name: "Thakurgaon Municipality (vn 2022)",
    total_safe: 28,
    Onsite_treated: 0,
    Onsite_contained_but_not_emptied: 28,
    Onsite_delivered_to_treatment_but_not_treated: 0,
    Onsite_not_contained_and_not_emptied: 1,
    Onsite_not_delivered_to_treatment: 69,
  },
];
