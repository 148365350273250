import React, { useEffect, useState } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { Styles } from "./styles/dynamicTabs.js";
import { useParams } from "react-router-dom";
import Datas from "../data/indicators/indicators.json";

const DynamicTabs = (props) => {
  let { id } = useParams();
  const tabData = Datas.find((item) => item.id === id);
  const [defaultActiveKey, setDefaultActiveKey] = useState(
    tabData && tabData.defaultActiveKey
  );

  useEffect(() => {
    setDefaultActiveKey(tabData && tabData.defaultActiveKey);
  }, [id]);

  return (
    <Styles>
      {/* Tab Box Area */}
      <section className="tab-section">
        <Container>
          <Tab.Container
            activeKey={defaultActiveKey}
            onSelect={(key) => setDefaultActiveKey(key)}
          >
            <Row>
              <Col lg="3" md="4">
                <Nav className="flex-column">
                  {tabData &&
                    tabData.childList &&
                    tabData.childList
                      .filter((item, index, self) => {
                        return (
                          self.findIndex(
                            (t) => t.eventKey === item.eventKey
                          ) === index
                        );
                      })
                      .map((item) => (
                        <Nav.Item key={item.eventKey}>
                          <Nav.Link eventKey={item.eventKey}>
                            <i className="las la-arrow-right"></i> {item.title}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                </Nav>
              </Col>

              <Col lg="9" md="8">
                <Tab.Content>
                  {tabData &&
                    tabData.childList &&
                    tabData.childList.map((item) => (
                      <Tab.Pane
                        eventKey={item.eventKey}
                        key={item.id.toString()}
                      >
                        <h4 className="tab-title">{item.title}</h4>
                        <h5 className="tab-title">{item.description}</h5>
                        <p className="tab-desc">{item.titile2}</p>
                        <p className="tab-desc">{item.title3}</p>
                        <ul className="list-unstyled check-list">
                          {item.checkList.map((text, index) => (
                            <li key={index}>
                              <div>
                                <i className="fa fa-check"></i>
                                {text}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </Tab.Pane>
                    ))}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </section>
    </Styles>
  );
};

export default DynamicTabs;
