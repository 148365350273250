import React, { useState } from "react";
import { Styles } from "../award-event/styles/awardEvent";
import Header from "../../components/Header";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/Footer";
import "./styles/event-common.css";

const Event3 = () => {
  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper event-page">
        {/* Header 2 */}
        <Header />

        {/* Breadcroumb */}
        <BreadcrumbBox title="Event" img="17_3.jpeg" />
        <Container>
          <Row>
            <Col>
              <a
                href="/news"
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  lineHeight: "40px",
                }}
              >
                Back To News
              </a>
            </Col>
          </Row>
        </Container>

        {/* News Area */}
        <section className="award-page">
          <Container>
            <Row>
              <Col md="12">
                <div style={{ padding: "20px 0" }}>
                  <h2
                    style={{
                      width: "100%",
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    A Model CWIS City Endeavour under Lakshmipur Municipality
                  </h2>
                  <h4
                    style={{
                      width: "100%",
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    Inaugural Coordination Meeting
                  </h4>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: 500,
                      padding: "20px 0 0 0",
                    }}
                  >
                    CFSC Meeting room, DPHE
                  </p>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: 500,
                      padding: "20px 0",
                    }}
                  >
                    Time 11:00am to 1:00pm{" "}
                  </p>
                  {/* <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        width: "70%",
                        maxHeight: "550px",
                      }}
                      src={process.env.PUBLIC_URL + `/assets/images/16_1.jpg`}
                      alt="award"
                    />
                  </div> */}
                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "20px 0",
                    }}
                  >
                    To foster a unified vision and mutual understanding among
                    the diverse stakeholders involved in the journey toward
                    establishing a City-wide Inclusive Sanitation (CWIS) Model
                    city in Bangladesh, the inaugural Coordination Meeting was
                    convened on May 8, 2024, at the CFSC meeting room within the
                    Department of Public Health Engineering (DPHE). This pivotal
                    gathering provided a platform for representatives from
                    various organizations to elucidate their respective areas of
                    expertise and present the current progress of planned
                    initiatives within the Lakhsmipur Municipality, all in
                    alignment with the agreed-upon Terms of Reference outlined
                    in Table-4 for the Model CWIS City.
                  </p>
                  <div className="event-2-imgs">
                    <img
                      style={{
                        width: "90%",
                        height: "300px",
                      }}
                      src={process.env.PUBLIC_URL + `/assets/images/17_1.jpeg`}
                      alt="award"
                    />
                    <img
                      style={{
                        width: "90%",
                        height: "300px",
                      }}
                      src={process.env.PUBLIC_URL + `/assets/images/17_2.jpeg`}
                      alt="award"
                    />
                    <img
                      style={{
                        width: "90%",
                        height: "300px",
                      }}
                      src={process.env.PUBLIC_URL + `/assets/images/17_4.jpeg`}
                      alt="award"
                    />
                  </div>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "10px 0",
                    }}
                  >
                    Among the esteemed attendees were representatives from the
                    Global Water and Sanitation Centre (GWSC), Practical Action
                    Bangladesh, and Athena Infonomics, who shared valuable
                    insights into their specialized domains and provided updates
                    on ongoing endeavors. Additionally, the presence of
                    delegates from the Islamic Development Bank (IsDB), along
                    with the Project Director and Deputy Project Director of the
                    Inclusive and Integrated Sanitation & Hygiene Project in 10
                    priority Towns in Bangladesh, underscored the collaborative
                    nature of the endeavor. Notably, the Co-Chair, Chief
                    Operating Officer, and other members of the CWIS-FSM Support
                    Cell contributed significantly to the discourse, enriching
                    the dialogue with their expertise and perspectives.
                  </p>
                  <div style={{ textAlign: "center" }}>
                    <img
                      style={{
                        width: "400px",
                        maxHeight: "450px",
                      }}
                      src={process.env.PUBLIC_URL + `/assets/images/17_3.jpeg`}
                      alt="award"
                    />
                  </div>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "10px 0",
                    }}
                  >
                    Following robust deliberations and knowledge exchange, the
                    meeting culminated in a unanimous decision to convene
                    monthly coordination meetings regularly. This proactive
                    measure aims to sustain momentum, facilitate seamless
                    communication, and promote synergistic collaboration among
                    all stakeholders involved in realizing the shared vision of
                    a CWIS Model city in Bangladesh.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </Styles>
  );
};

export default Event3;
