import React from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const ContentCard = ({ icon, title, subtitle, id }) => {
  const history = useHistory();
  const handleClick = () => {
    history.push({ pathname: `/content/${id}`, state: { title: title } });
  };
  return (
    <div className="content-card" onClick={handleClick}>
      <Row>
        <Col>
          <div className="card">
            <img
              src={process.env.PUBLIC_URL + `/assets/images/icons/${icon}`}
              style={{ width: "70px" }}
              alt={title}
            />
            <div className="px-2 mt-2">
              <h3 className="card-title">{title}</h3>
              <h6 className="card-subtitle mt-2">{subtitle}</h6>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ContentCard;
