import React, { useEffect, useState } from "react";
import { shitFlowData } from "../../data/shitflow-diagram/shitflowDiagramData";
import { Container, Row, Col, Card } from "react-bootstrap";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import Footer from "../../components/Footer";
import { Styles } from "./styles/shitFlowDiagram";
import Header from "../../components/Header";

const ShitFlowDiagram = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 5;
  const [searchText, setSearchText] = useState("");
  const [currentData, setCurrentData] = useState([]);
  //const shitFlowData = shitFlowData;

  useEffect(() => {
    const tmpData = shitFlowData.sort((a, b) => (a.title > b.title ? 1 : -1));

    const currentDataT = tmpData;
    const filteredItems = currentDataT.filter((item) =>
      item.title.toLowerCase().includes(searchText.toLowerCase())
    );

    setCurrentData(filteredItems);
  }, [searchText]);

  return (
    <Styles>
      {/* Main Wrapper */}
      <div
        className="main-wrapper shitflow-page"
        style={{ position: "relative" }}
      >
        {/* Header 2 */}
        <Header />

        {/* Breadcroumb */}
        <div
          style={{
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            zIndex: 10,
            height: "270px",
            background: "white",
          }}
        >
          <BreadcrumbBox title="Shit Flow Diagram" img="slider3.png" />
        </div>

        {/* ShitFlowDiagram Area */}
        <section
          className="event-page-area"
          style={{ marginTop: "250px", zIndex: 1 }}
        >
          <div style={{ height: "450px", overflowY: "auto" }}>
            <Container>
              <Row style={{ padding: "0 0 20px 0" }}>
                <Col>
                  <div style={{ float: "right" }}>
                    <input
                      style={{
                        width: "250px",
                        padding: "10px 15px",
                        border: "1px solid lightgray",
                        borderRadius: "1rem",
                        fontSize: "15px",
                      }}
                      onChange={(e) => setSearchText(e.target.value)}
                      placeholder="Search..."
                    />
                  </div>
                </Col>
              </Row>
              <Row xs={1} sm={2} md={2} lg={3}>
                {currentData.map((data, i) => (
                  <div className="" key={i}>
                    <Col key={i + 1}>
                      {/* <div className="event-img">
                          <a href={data.url} target="_blank">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                `/assets/shitflowimages/${data.imgSrc}`
                              }
                              alt=""
                              className="img-fluid"
                            />
                          </a>
                        </div> */}
                      {/* <ScrollAnimation
                        animateIn={i % 2 == 0 ? "fadeInLeft" : "fadeInRight"}
                      > */}
                      <Card className="custom-card">
                        <div className="card-image-container">
                          <a href={data.url} target="_blank">
                            <Card.Img
                              variant="top"
                              src={
                                process.env.PUBLIC_URL +
                                `/assets/shitflowimages/${data.imgSrc}`
                              }
                              className="card-image"
                            />
                          </a>
                        </div>
                        <Card.Body>
                          <h2>
                            {i + 1}. {data.title}
                          </h2>
                          {/* <div className="join-btn">
                            <a href={data.url} target="_blank">
                              <i className="fa fa-eye "></i>
                              &nbsp;Click for Download Report
                            </a>
                          </div> */}
                        </Card.Body>
                      </Card>
                      {/* </ScrollAnimation> */}
                    </Col>
                    {/* <Col xl="9" lg="8" md="12">
                        <div
                          className="event-content"
                          style={{ width: "100%" }}
                        >
                          <div
                            className="content-box"
                            style={{
                              width: "100%",
                            }}
                          >
                            <Row>
                              <Col md="12">
                                <div
                                  className="event-title"
                                  style={{ paddingLeft: "10px" }}
                                >
                                  <h6>
                                    <a href={data.url} target="_blank">
                                      {data.title}
                                    </a>
                                  </h6>
                                  <div className="join-btn">
                                    <a href={data.url} target="_blank">
                                      <i className="fa fa-eye "></i>
                                      &nbsp;Click for Download Report
                                    </a>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col> */}
                  </div>
                ))}

                {/* <Col md="12" className="text-center">
                  <PaginationNew
                    total={shitFlowData.length}
                    perPage={perPage}
                    currentPage={currentPage}
                    onPageChange={onPageChange}
                    onNextPageChange={onNextPageChange}
                    onPreviousPageChange={onPreviousPageChange}
                  />
                </Col> */}
              </Row>
            </Container>
          </div>
        </section>

        {/* Footer 2 */}
        <Footer />
      </div>
    </Styles>
  );
};

export default ShitFlowDiagram;
