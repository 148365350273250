import React, { useState } from "react";
import { Styles } from "../award-event/styles/awardEvent";
import Header from "../../components/Header";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/Footer";
import "./styles/event-common.css";

const Event2 = () => {
  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper event-page">
        {/* Header 2 */}
        <Header />

        {/* Breadcroumb */}
        <BreadcrumbBox title="Event" img="16_5.jpg" />
        <Container>
          <Row>
            <Col>
              <a
                href="/news"
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  lineHeight: "40px",
                }}
              >
                Back To News
              </a>
            </Col>
          </Row>
        </Container>

        {/* News Area */}
        <section className="award-page">
          <Container>
            <Row>
              <Col md="12">
                <div style={{ padding: "20px 0" }}>
                  <h2
                    style={{
                      width: "100%",
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    A Model CWIS City Endeavour under Lakshmipur Municipality
                  </h2>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: 500,
                      padding: "20px 0 0 0",
                    }}
                  >
                    Learning Session on Ensuring Health Security Schemes for
                    Low-Income Communities Inclusive and Integrated Sanitation &
                    Hygiene Project in 10 priority Towns in Bangladesh
                    (Integrated Waste Management -IWM Project) supported by
                    GoB-ISDB.
                  </p>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: 500,
                      padding: "20px 0",
                    }}
                  >
                    Date: April 23, 2024
                  </p>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        width: "70%",
                        maxHeight: "550px",
                      }}
                      src={process.env.PUBLIC_URL + `/assets/images/16_1.jpg`}
                      alt="award"
                    />
                  </div>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "20px 0",
                    }}
                  >
                    In the ongoing efforts to scale up City-wide Inclusive
                    Sanitation (CWIS) in Bangladesh, a Model CWIS City
                    initiative is now in its take-off stage, spearheaded by the
                    CWIS-FSM Support Cell. Apart from this, a significant
                    "Learning Session on Ensuring Health Security Schemes for
                    Low-Income Communities in Lakshmipur Municipality" was
                    conducted on April 23, 2024. This session was held under the
                    overarching framework of the Inclusive and Integrated
                    Sanitation & Hygiene Project, focusing on 10 priority towns
                    in Bangladesh, known as the Integrated Waste Management
                    (IWM) Project supported by GoB-ISDB.
                  </p>
                  <div className="event-2-imgs">
                    <img
                      style={{
                        width: "70%",
                        maxHeight: "550px",
                      }}
                      src={process.env.PUBLIC_URL + `/assets/images/16_2.jpg`}
                      alt="award"
                    />
                    <img
                      style={{
                        width: "70%",
                        maxHeight: "550px",
                      }}
                      src={process.env.PUBLIC_URL + `/assets/images/16_3.jpg`}
                      alt="award"
                    />
                    <img
                      style={{
                        width: "70%",
                        maxHeight: "550px",
                      }}
                      src={process.env.PUBLIC_URL + `/assets/images/16_4.jpg`}
                      alt="award"
                    />
                  </div>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "10px 0",
                    }}
                  >
                    The session, convened at the DPHE CWIS-FSM Support Cell, was
                    chaired by Md. Saifur Rahman, SE (Ground Water Circle), with
                    notable attendees including Ehete Shamul Rassel Khan, SE
                    (Feasibility Study & Design Circle), Mohammad Anwar Eusuf,
                    SE (Planning Circle), and Dilruba Farzana, DPD of the
                    10-towns project. Dr. Abdullah Al Muyeed, COO of CFSC,
                    played a pivotal role by delivering presentations and
                    offering insightful feedback during the meeting.
                  </p>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "10px 0",
                    }}
                  >
                    The event attracted participation from WaterAid Bangladesh,
                    Practical Action, Athena Infonomics, Waadaa Insurance &
                    Chartered Line Insurance and CWIS-FSM Support Cell of 23
                    participants, collectively engaging in enriching discussions
                    aimed at enhancing health security measures for low-income
                    communities.
                  </p>
                  <div style={{ textAlign: "center" }}>
                    <img
                      style={{
                        width: "60%",
                        maxHeight: "450px",
                      }}
                      src={process.env.PUBLIC_URL + `/assets/images/16_5.jpg`}
                      alt="award"
                    />
                  </div>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "10px 0",
                    }}
                  >
                    This session, facilitated by WaterAid, aimed to enhance the
                    understanding and implementation of health security schemes,
                    particularly focusing on the Shastha Nirapotta Scheme (SNS).
                  </p>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "10px 0",
                    }}
                  >
                    During the meeting, WaterAid Bangladesh provided insightful
                    presentations on the Shastha Nirapotta Scheme (SNS),
                    detailing its relevance and implementation strategies.
                    Practical Action Bangladesh also outlined their plans for
                    integrating the SNS into their existing cooperative model,
                    emphasizing its potential impact on sanitation and waste
                    workers' health and well-being.
                  </p>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "10px 0",
                    }}
                  >
                    Additionally, representatives from Waadaa Insurance and
                    Chartered Line Insurance attended the meeting, bringing
                    their viewpoints and knowledge to the discussions. An
                    interactive question-and-answer session was conducted during
                    the learning session.
                  </p>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "10px 0",
                    }}
                  >
                    Drawing from the insights shared by WaterAid on the SNS, it
                    is evident that leveraging such schemes will be instrumental
                    in enhancing sanitation and waste workers' welfare in
                    Lakshmipur city. Incorporating these learnings is a crucial
                    step towards realizing the vision of making Lakshmipur a
                    model CWIS city, fostering sustainable development and
                    improved quality of life for its residents.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </Styles>
  );
};

export default Event2;
