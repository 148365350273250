import styled from "styled-components";
import { colors } from "../../../components/common/element/elements";

export const Styles = styled.div`
    .gallery-page-area {
        padding: 0 !important;
        .video-gallery {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;
            .video-gallery-box {
                border-radius: 1rem;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                padding: 10px;
                h4 {
                    color: ${colors.water};
                    padding: 10px 0;
                    font-size: 14px;
                }
            }
        }
        .photo-gallery {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 10px;
            .gallery-box {
                height: 250px;
                border-radius: 1rem;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                padding: 5px;
                img {
                    width: 100%;
                    max-height: 200px;
                    height: 200px;
                    border-radius: 1rem;
                    &:hover {
                        transform: scale(1);
                    }
                }
                h4 {
                    color: ${colors.water};
                    padding: 10px 0;
                    font-size: 14px;
                }
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
`;
