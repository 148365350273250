import React, { Component } from "react";
import Header from "./components/Header";
import HeroSlider from "./components/HeroSlider";
import TeamSlider from "./components/TeamSlider";
import Footer from "./components/Footer";
import { homeData } from "./data/homeData";
import HomeBlog from "./components/HomeBlog";
import HomeAbout from "./components/HomeAbout";
import PartnerSlider from "./components/PartnerSlider";

export default class HomeOne extends Component {
  render() {
    return (
      <div className="main-wrapper">
        {/* Header */}
        <Header />

        {/* Hero Slider */}
        <HeroSlider data={homeData && homeData.sliders} />

        {/* Home About */}
        <HomeAbout />

        {/* Blog Area */}
        {/* <HomeBlog /> */}

        {/* Team Slider */}
        {/* <TeamSlider /> */}

        {/* Partner Slider */}
        {/* <PartnerSlider /> */}

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
