import React, { Component, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import $ from "jquery";
import ScrollAnimation from "react-animate-on-scroll";
import { Styles } from "./styles/publicToiletModel";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";

const PublicToiletModel = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    $("#video").bind("contextmenu", function () {
      return false;
    });
  }, []);

  const openModal = () => {
    setIsOpen(true);
  };
  const playVideo = () => {
    setIsPlaying(true);
    const video = document.getElementById("video");
    video.play();
  };
  return (
    <Styles>
      <Header />
      <BreadcrumbBox
        title="DPHE Public Toilet Model"
        img="toilet_model_bg.png"
      />
      <section className="toilet-model">
        <Container>
          <Row>
            <Col md="12">
              <div style={{ textAlign: "center" }}>
                <h4
                  style={{
                    color: "#182B49",
                    lineHeight: "35px",
                    fontWeight: 600,
                    maxWidth: "550px",
                    margin: "auto",
                    marginBottom: "48px",
                  }}
                >
                  DPHE Public Toilet Model
                </h4>
              </div>
            </Col>
            <Col md="12">
              <ScrollAnimation animateIn="fadeInLeft">
                <div className="about-content">
                  <p className="about-para">
                    The CWIS-FSM Support Cell, Department of Public Health
                    Engineering has developed an inclusive public toilet model
                    that caters to the specific needs of women, children, and
                    individuals with disabilities. This initiative aims to
                    contribute towards achieving Sustainable Development Goal
                    (SDG 6.2) by 2030. The model includes separate entries for
                    both males and females to ensure privacy, disable friendly
                    ramps and handrails for easy accessibility, child-friendly
                    features such as lower height fixtures and changing
                    stations, breast Feeding/ Child Care Zone, Menstrual Hygiene
                    Pad dumping pit, Amenity Box and cloth hanger, etc. The
                    following are the features included in the inclusive public
                    toilet model:
                  </p>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp">
                <div className="model-table">
                  <div className="cell-1">
                    <h3>Common Features</h3>
                    <ol>
                      <li>Running Water Facility</li>
                      <li>Indoor Plantation</li>
                      <li>Gardening (Outside)</li>
                      <li>Septic tank with soak pit</li>
                    </ol>
                  </div>
                  <div className="cell-2">
                    <h3>Male Zone Features</h3>
                    <ol>
                      <li>Toll area</li>
                      <li>Foot Washing Facility: 01 Unit</li>
                      <li>Safe Drinking Water (UV Filter) facility: 01 Unit</li>
                      <li>Locker Facility:01 Unit</li>
                      <li>Disable Friendly Chamber:01 Unit</li>
                      <li>Hand Washing Facility:01 Unit</li>
                      <li>Bathing Facility:01 Unit</li>
                      <li>Toilet Chamber with high commode:01 Unit</li>
                      <li>Toilet Chamber with pan: 01 Unit</li>
                      <li>Regular Urinal:03 Unit</li>
                      <li>Disable Friendly Ramp (1:12 Slope)</li>
                      <li>Hand Railing in Disable Friendly Chamber</li>
                    </ol>
                  </div>
                  <div className="cell-3">
                    <h3>Female Zone Features</h3>
                    <ol>
                      <li>Toll Area</li>
                      <li>
                        Safe Drinking Water ( UV Filter ) facility: 01 Unit
                      </li>
                      <li>Locker Facility: 01 Unit</li>
                      <li>Disable Friendly Chamber:01 Unit</li>
                      <li>Hand Washing Facility:01 Unit</li>
                      <li>Bathing Facility:01 Unit</li>
                      <li>Toilet Chamber with high commode:01 Unit</li>
                      <li>Toilet Chamber with pan: 01 Unit</li>
                      <li>Amenity Box &amp; Cloth hanger</li>
                      <li>Menstrual Hygiene Pad dumping pit</li>
                      <li>Breast Feeding/ Child Care Zone</li>
                      <li>Hygiene/MHM Box:01 Unit</li>
                      <li>Disable Friendly Ramp (1:12 Slope )</li>
                      <li>Hand Railing in Disable Friendly Chamber</li>
                    </ol>
                  </div>
                </div>
              </ScrollAnimation>
            </Col>
          </Row>
          <Row className="mt-5 justify-content-md-center">
            <Col
              md={9}
              className="d-flex  align-items-center  justify-content-center "
            >
              <div
                style={{
                  position: "relative",
                  width: "70%",
                }}
              >
                <ScrollAnimation animateIn="fadeInUp">
                  {isPlaying ? null : (
                    <div
                      style={{
                        position: "relative",
                        background: "rgba(0, 0, 0, 0.5)",
                        cursor: "pointer",
                        width: "100%",
                      }}
                      onClick={playVideo}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/toilet_model_bg.png`} // Replace with your play button image
                        alt="Play Button"
                        style={{ width: "100%" }}
                      />
                      {/* <i
                                                //fas fa-play
                                                className=""
                                                // style={{
                                                //   fontSize: "50px",
                                                //   position: "absolute",
                                                //   top: "50%",
                                                //   left: "50%",
                                                //   transform: "translate(-50%,-50%)",
                                                //   border: "4px solid gray",
                                                //   height: "100px",
                                                //   width: "100px",
                                                //   borderRadius: "50%",
                                                //   display: "flex",
                                                //   alignItems: "center",
                                                //   justifyContent: "center",
                                                // }}
                                            >
                                                <span className="video-play-button"></span>
                                            </i> */}
                      <div id="play-video" class="video-play-button">
                        <span></span>
                      </div>
                    </div>
                  )}
                  <video
                    oncontextmenu="return false;"
                    id="video"
                    controls
                    style={{
                      display: isPlaying ? "block" : "none",
                      maxHeight: "350px",
                      width: "100%",
                    }}
                    controlsList="nodownload"
                  >
                    <source
                      src={`${process.env.PUBLIC_URL}/assets/video/DPHE_PublicToilet_Model_1.mp4`}
                      type="video/mp4"
                    />
                  </video>
                  <p
                    className="my-2 "
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    <sup>*</sup>If you want to see the video please click over
                    the image
                  </p>
                </ScrollAnimation>
              </div>
            </Col>
          </Row>
          {/* <p
              style={{
                textAlign: "center",
                fontSize: "30px",
                color: colors.water,
                fontWeight: "bold",
                fontStyle: "italic",
              }}
            >
              <blockquote className="blockquote">
                Supported by Bill & Melinda Gates Foundation
              </blockquote>
            </p> */}
        </Container>
      </section>
      <Footer />
    </Styles>
  );
};

export default PublicToiletModel;
