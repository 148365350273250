import React, { useEffect, useState } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { Styles } from "./styles/galleryTabs.js";
import { useParams } from "react-router-dom";
import { galleryData } from "../data/gallery/gallery.js";
import VideoGallery from "./VideoGallery.js";
import PhotoGallery from "./PhotoGallery.js";
const GalleryTabs = (props) => {
    const [defaultActiveKey, setDefaultActiveKey] = useState(
        galleryData.tabOption[0]
    );

    return (
        <Styles>
            {/* Tab Box Area */}
            <section className="tab-section">
                <Container>
                    <div style={{ textAlign: "center" }}>
                        <h4
                            style={{
                                color: "#182B49",
                                lineHeight: "35px",
                                fontWeight: 600,
                                maxWidth: "550px",
                                margin: "auto",
                                marginBottom: "48px",
                            }}
                        >
                            Video & Photo Gallery
                        </h4>
                    </div>
                    <Tab.Container
                        activeKey={defaultActiveKey}
                        onSelect={(key) => setDefaultActiveKey(key)}
                    >
                        <Row>
                            <Col lg="3" md="4">
                                <Nav className="flex-column">
                                    {galleryData &&
                                        galleryData.tabOption &&
                                        galleryData.tabOption.map((item) => (
                                            <Nav.Item key={item}>
                                                <Nav.Link eventKey={item}>
                                                    <i className="las la-arrow-right"></i>{" "}
                                                    {item}
                                                </Nav.Link>
                                            </Nav.Item>
                                        ))}
                                </Nav>
                            </Col>

                            <Col lg="9" md="8">
                                <Tab.Content>
                                    {galleryData &&
                                        galleryData.galleryData &&
                                        galleryData.galleryData.map((item) => (
                                            <Tab.Pane
                                                eventKey={item.eventKey}
                                                key={Math.random()}
                                            >
                                                {item.eventKey === "video" ? (
                                                    <VideoGallery
                                                        data={item.list}
                                                    />
                                                ) : (
                                                    <PhotoGallery
                                                        data={item.list}
                                                    />
                                                )}
                                            </Tab.Pane>
                                        ))}
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Container>
            </section>
        </Styles>
    );
};

export default GalleryTabs;
