export const data = [
  {
    name: "Araihazar Municipality",
    total_safe: 9,
    Onsite_safely_managed: 9,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 15,
    Offsite_unsafely_managed: 77,
    Open_defecation: 0,
  },
  {
    name: "Bagerhat Municipality",
    total_safe: 19,
    Onsite_safely_managed: 19,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 5,
    Offsite_unsafely_managed: 76,
    Open_defecation: 0,
  },
  {
    name: "Bandarban Municipality",
    total_safe: 18,
    Onsite_safely_managed: 18,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 6,
    Offsite_unsafely_managed: 77,
    Open_defecation: 0,
  },
  {
    name: "Barguna Municipality",
    total_safe: 24,
    Onsite_safely_managed: 24,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 4,
    Offsite_unsafely_managed: 72,
    Open_defecation: 0,
  },
  {
    name: "Barishal City Corporation",
    total_safe: 25,
    Onsite_safely_managed: 25,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 6,
    Offsite_unsafely_managed: 70,
    Open_defecation: 0,
  },
  {
    name: "Bauphal Municipality",
    total_safe: 21,
    Onsite_safely_managed: 21,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 0,
    Offsite_unsafely_managed: 79,
    Open_defecation: 0,
  },
  {
    name: "Betagi Municipality",
    total_safe: 21,
    Onsite_safely_managed: 21,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 1,
    Offsite_unsafely_managed: 78,
    Open_defecation: 0,
  },
  {
    name: "Bhola Municipality (vn 2022)",
    total_safe: 26,
    Onsite_safely_managed: 26,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 2,
    Offsite_unsafely_managed: 73,
    Open_defecation: 0,
  },
  {
    name: "Bogra Municipality",
    total_safe: 19,
    Onsite_safely_managed: 19,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 2,
    Offsite_unsafely_managed: 79,
    Open_defecation: 0,
  },
  {
    name: "Brahmanbaria Municipality",
    total_safe: 13,
    Onsite_safely_managed: 13,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 6,
    Offsite_unsafely_managed: 82,
    Open_defecation: 0,
  },
  {
    name: "Chandpur Municipality",
    total_safe: 20,
    Onsite_safely_managed: 20,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 11,
    Offsite_unsafely_managed: 69,
    Open_defecation: 0,
  },
  {
    name: "Chapai Nawabganj Municipality (vn 2022)",
    total_safe: 23,
    Onsite_safely_managed: 23,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 1,
    Offsite_unsafely_managed: 76,
    Open_defecation: 0,
  },
  {
    name: "Chuadanga Municipality",
    total_safe: 27,
    Onsite_safely_managed: 27,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 4,
    Offsite_unsafely_managed: 69,
    Open_defecation: 0,
  },
  {
    name: "Coxs Bazar Municipality",
    total_safe: 20,
    Onsite_safely_managed: 20,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 5,
    Offsite_unsafely_managed: 76,
    Open_defecation: 0,
  },
  {
    name: "Cumilla City Corporation (vn 2022)",
    total_safe: 14,
    Onsite_safely_managed: 14,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 2,
    Offsite_unsafely_managed: 84,
    Open_defecation: 0,
  },
  {
    name: "Dinajpur Municipality",
    total_safe: 23,
    Onsite_safely_managed: 23,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 6,
    Offsite_unsafely_managed: 71,
    Open_defecation: 0,
  },
  {
    name: "Faridpur Municipality",
    total_safe: 50,
    Onsite_safely_managed: 50,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 3,
    Offsite_unsafely_managed: 47,
    Open_defecation: 0,
  },
  {
    name: "Feni Municipality",
    total_safe: 8,
    Onsite_safely_managed: 8,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 5,
    Offsite_unsafely_managed: 87,
    Open_defecation: 0,
  },
  {
    name: "Gaibandha Municipality",
    total_safe: 24,
    Onsite_safely_managed: 24,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 3,
    Offsite_unsafely_managed: 74,
    Open_defecation: 0,
  },
  {
    name: "Galachipa Municipality",
    total_safe: 20,
    Onsite_safely_managed: 20,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 15,
    Offsite_unsafely_managed: 65,
    Open_defecation: 0,
  },
  {
    name: "Gazipur (vn 2022)",
    total_safe: 26,
    Onsite_safely_managed: 26,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 10,
    Offsite_unsafely_managed: 64,
    Open_defecation: 0,
  },
  {
    name: "Gopalganj Municipality",
    total_safe: 26,
    Onsite_safely_managed: 26,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 1,
    Offsite_unsafely_managed: 74,
    Open_defecation: 0,
  },
  {
    name: "Homna Municipality",
    total_safe: 9,
    Onsite_safely_managed: 9,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 0,
    Offsite_unsafely_managed: 91,
    Open_defecation: 0,
  },
  {
    name: "Ishwardi Municipality",
    total_safe: 18,
    Onsite_safely_managed: 18,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 7,
    Offsite_unsafely_managed: 75,
    Open_defecation: 0,
  },
  {
    name: "Jamalpur, Bangladesh",
    total_safe: 1,
    Onsite_safely_managed: 1,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 2,
    Offsite_unsafely_managed: 97,
    Open_defecation: 0,
  },
  {
    name: "Jhenaidah Municipality",
    total_safe: 29,
    Onsite_safely_managed: 29,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 1,
    Offsite_unsafely_managed: 71,
    Open_defecation: 0,
  },
  {
    name: "Joypurhat Municipality (vn 2022)",
    total_safe: 33,
    Onsite_safely_managed: 33,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 4,
    Offsite_unsafely_managed: 63,
    Open_defecation: 0,
  },
  {
    name: "Kaliakair Municipality",
    total_safe: 11,
    Onsite_safely_managed: 11,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 14,
    Offsite_unsafely_managed: 75,
    Open_defecation: 0,
  },
  {
    name: "Kalihati Municipality",
    total_safe: 26,
    Onsite_safely_managed: 26,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 2,
    Offsite_unsafely_managed: 72,
    Open_defecation: 0,
  },
  {
    name: "Kishoreganj Municipality",
    total_safe: 9,
    Onsite_safely_managed: 9,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 7,
    Offsite_unsafely_managed: 84,
    Open_defecation: 0,
  },
  {
    name: "Lakshmipur Municipality",
    total_safe: 30,
    Onsite_safely_managed: 30,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 3,
    Offsite_unsafely_managed: 67,
    Open_defecation: 0,
  },
  {
    name: "Lalmonirhat Municipality",
    total_safe: 17,
    Onsite_safely_managed: 17,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 6,
    Offsite_unsafely_managed: 76,
    Open_defecation: 0,
  },
  {
    name: "Lama Municipality",
    total_safe: 10,
    Onsite_safely_managed: 10,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 22,
    Offsite_unsafely_managed: 68,
    Open_defecation: 0,
  },
  {
    name: "Magura Municipality",
    total_safe: 17,
    Onsite_safely_managed: 17,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 2,
    Offsite_unsafely_managed: 81,
    Open_defecation: 0,
  },
  {
    name: "Meherpur Municipality (vn 2022)",
    total_safe: 16,
    Onsite_safely_managed: 16,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 1,
    Offsite_unsafely_managed: 82,
    Open_defecation: 0,
  },
  {
    name: "Naogaon Municipality (vn 2022)",
    total_safe: 30,
    Onsite_safely_managed: 30,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 3,
    Offsite_unsafely_managed: 68,
    Open_defecation: 0,
  },
  {
    name: "Narayanganj City Corporation (vn 2022)",
    total_safe: 10,
    Onsite_safely_managed: 10,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 22,
    Offsite_unsafely_managed: 68,
    Open_defecation: 0,
  },
  {
    name: "Narsingdi Municipality",
    total_safe: 2,
    Onsite_safely_managed: 2,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 17,
    Offsite_unsafely_managed: 81,
    Open_defecation: 0,
  },
  {
    name: "Natore Municipality",
    total_safe: 22,
    Onsite_safely_managed: 22,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 2,
    Offsite_unsafely_managed: 76,
    Open_defecation: 0,
  },
  {
    name: "Nilphamari Municipality (vn 2022)",
    total_safe: 23,
    Onsite_safely_managed: 23,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 8,
    Offsite_unsafely_managed: 69,
    Open_defecation: 0,
  },
  {
    name: "Noakhali Municipality",
    total_safe: 15,
    Onsite_safely_managed: 15,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 5,
    Offsite_unsafely_managed: 80,
    Open_defecation: 0,
  },
  {
    name: "Pabna Municipality",
    total_safe: 2,
    Onsite_safely_managed: 2,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 2,
    Offsite_unsafely_managed: 96,
    Open_defecation: 0,
  },
  {
    name: "Paikgachha Municipality",
    total_safe: 24,
    Onsite_safely_managed: 24,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 1,
    Offsite_unsafely_managed: 76,
    Open_defecation: 0,
  },
  {
    name: "Panchagarh Municipality",
    total_safe: 22,
    Onsite_safely_managed: 22,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 1,
    Offsite_unsafely_managed: 77,
    Open_defecation: 0,
  },
  {
    name: "Patuakhali Municipality",
    total_safe: 12,
    Onsite_safely_managed: 12,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 6,
    Offsite_unsafely_managed: 83,
    Open_defecation: 0,
  },
  {
    name: "Rajbari Municipality",
    total_safe: 19,
    Onsite_safely_managed: 19,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 1,
    Offsite_unsafely_managed: 80,
    Open_defecation: 0,
  },
  {
    name: "Rangamati Municipality",
    total_safe: 13,
    Onsite_safely_managed: 13,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 5,
    Offsite_unsafely_managed: 82,
    Open_defecation: 0,
  },
  {
    name: "Shariatpur Municipality",
    total_safe: 15,
    Onsite_safely_managed: 15,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 2,
    Offsite_unsafely_managed: 82,
    Open_defecation: 0,
  },
  {
    name: "Singair Municipality",
    total_safe: 24,
    Onsite_safely_managed: 24,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 1,
    Offsite_unsafely_managed: 75,
    Open_defecation: 0,
  },
  {
    name: "Sirajganj Municipality",
    total_safe: 29,
    Onsite_safely_managed: 29,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 4,
    Offsite_unsafely_managed: 66,
    Open_defecation: 0,
  },
  {
    name: "Sonargaon Municipality",
    total_safe: 25,
    Onsite_safely_managed: 25,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 6,
    Offsite_unsafely_managed: 69,
    Open_defecation: 0,
  },
  {
    name: "Sreepur Municipality",
    total_safe: 15,
    Onsite_safely_managed: 15,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 18,
    Offsite_unsafely_managed: 67,
    Open_defecation: 0,
  },
  {
    name: "Sylhet City Corporation (vn 2022)",
    total_safe: 27,
    Onsite_safely_managed: 27,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 5,
    Offsite_unsafely_managed: 68,
    Open_defecation: 0,
  },
  {
    name: "Tarabo Municipality",
    total_safe: 6,
    Onsite_safely_managed: 6,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 18,
    Offsite_unsafely_managed: 76,
    Open_defecation: 0,
  },
  {
    name: "Thakurgaon Municipality (vn 2022)",
    total_safe: 28,
    Onsite_safely_managed: 28,
    Offsite_safely_managed: 0,
    Onsite_unsafely_managed: 2,
    Offsite_unsafely_managed: 70,
    Open_defecation: 0,
  },
];
