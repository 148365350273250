export const galleryData = {
    tabOption: ["video", "photo"],
    title: "Gallery With Videos and Photos",
    galleryData: [
        {
            id: 1,
            eventKey: "video",
            list: [
                {
                    id: 1,
                    eventKey: "video",
                    video: "CWIS_ecosystem_v10.mp4",
                    title: "Video 1",
                },
                {
                    id: 2,
                    eventKey: "video",
                    video: "CWIS_ecosystem_v10.mp4",
                    title: "Video 2",
                },
                {
                    id: 3,
                    eventKey: "video",
                    video: "CWIS_ecosystem_v10.mp4",
                    title: "Video 2",
                },
            ],
        },
        {
            id: 2,
            eventKey: "photo",
            list: [
                {
                    id: 2,
                    albumTitle: "Album 1 title",
                    albumSubTitle: "Album 1 Subtitle",
                    thumbnail: "About-ITN.jpg",
                    list: [
                        {
                            id: 1,
                            eventKey: "photo",
                            img: "About-ITN.jpg",
                        },
                        {
                            id: 2,
                            eventKey: "photo",
                            img: "Aboutus-img.png",
                        },
                        {
                            id: 3,
                            eventKey: "photo",
                            img: "Research-and-Consultancy-scaled.jpg",
                        },
                    ],
                },
                {
                    id: 2,
                    albumTitle: "Album 2 title",
                    albumSubTitle: "Album 2 Subtitle",
                    thumbnail: "Aboutus-img.png",
                    list: [
                        {
                            id: 1,
                            eventKey: "photo",
                            img: "About-ITN.jpg",
                        },
                        {
                            id: 2,
                            eventKey: "photo",
                            img: "Aboutus-img.png",
                        },
                        {
                            id: 3,
                            eventKey: "photo",
                            img: "Research-and-Consultancy-scaled.jpg",
                        },
                        {
                            id: 3,
                            eventKey: "photo",
                            img: "Workshop-and-Seminar-scaled.jpg",
                        },
                    ],
                },
                {
                    id: 3,
                    albumTitle: "Album 3 title",
                    albumSubTitle: "Album 3 Subtitle",
                    thumbnail: "Research-and-Consultancy-scaled.jpg",
                    list: [
                        {
                            id: 1,
                            eventKey: "photo",
                            img: "About-ITN.jpg",
                        },
                        {
                            id: 2,
                            eventKey: "photo",
                            img: "Aboutus-img.png",
                        },
                    ],
                },
                {
                    id: 4,
                    albumTitle: "Album 4 title",
                    albumSubTitle: "Album 4 Subtitle",
                    thumbnail: "Workshop-and-Seminar-scaled.jpg",
                    list: [
                        {
                            id: 1,
                            eventKey: "photo",
                            img: "About-ITN.jpg",
                        },
                    ],
                },
            ],
        },
    ],
};
