import React, { Component } from 'react';
import Datas from '../data/activity-data/activity.json';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from './styles/activityComponent';

class ActivityComponent extends Component {
  render() {
    return (
      <Styles>
        {/* About Us */}
        <section className="about-us">
          <Container>
            <Row>
              <h4 className="about-titel">{Datas.title} </h4>
            </Row>
            <Row>
              <Col md="6">
                <div className="about-content">
                  <p
                    className="about-para"
                    dangerouslySetInnerHTML={{
                      __html: Datas.desc2.replace(/\n/g, '<br/>'),
                    }}
                  ></p>
                </div>
              </Col>
              <Col md="6">
                <div className="about-image">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      `/assets/images/${Datas.mainImage}`
                    }
                    className="main-img"
                    alt=""
                  />
                </div>
                <div className="image-caption">
                  {Datas.secondaryImageCaption}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>
    );
  }
}

export default ActivityComponent;
