import React from "react";
import { Styles } from "./styles/sfdDashboard";
import Header from "../../components/Header";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import Footer from "../../components/Footer";
import SFDDashboardChart1 from "./SFDDashboardChart1";
import { Container } from "react-bootstrap";
import SFDDashboardChart2 from "./SFDDashboardChart2";
import SFDDashboardChart3 from "./SFDDashboardChart3";
import DashboardMap from "./DashboardMap";

const SFDDashboard = () => {
  return (
    <Styles>
      <div className="main-wrapper sfd-dashboard">
        <Header />

        {/* Breadcroumb */}
        <BreadcrumbBox title="SFD Interactive Dashboard" img="" />
        <Container>
          <div style={{ padding: "50px 0" }} className="chart">
            <DashboardMap />
            <SFDDashboardChart1 />
            <SFDDashboardChart2 />
            <SFDDashboardChart3 />
          </div>
        </Container>
        <Footer />
      </div>
    </Styles>
  );
};

export default SFDDashboard;
