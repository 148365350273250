import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Styles } from "./../pages/gallery/styles/photoGallery.js";
const VideoGallery = ({ data }) => {
    return (
        <Styles>
            <section className="gallery-page-area">
                <Container>
                    <div className="video-gallery">
                        {data.map((album) => (
                            <div key={album.id} className="video-gallery-box">
                                <video
                                    id="video"
                                    src={
                                        process.env.PUBLIC_URL +
                                        `/assets/video/${album.video}`
                                    }
                                    width="100%"
                                    controls
                                ></video>

                                <h4>{album.title}</h4>
                            </div>
                        ))}
                    </div>
                </Container>
            </section>
        </Styles>
    );
};

export default VideoGallery;
