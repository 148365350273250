import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { data } from "./outcomeScore";
export const CWISOutcomeBar = () => {
  const categories = ["Equity", "Safety", "Sustainability"];
  const jsonData = categories.map((category) => {
    const categoryData = { name: category };

    data.forEach((entry) => {
      categoryData[entry.Name] = entry[category];
    });

    return categoryData;
  });
  return (
    <div
      style={{
        height: "500px",
        border: "2px solid lightgray",
        borderRadius: "8px",
        padding: "10px",
      }}
    >
      <p className="bar-title">CWIS Outcomes</p>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={jsonData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />

          <Bar
            dataKey="LAKSAM"
            fill="#40407a"
            activeBar={<Rectangle fill="#40407a" stroke="blue" />}
          />
          <Bar
            dataKey="LAXMIPUR"
            fill="#706fd3"
            activeBar={<Rectangle fill="#706fd3" stroke="blue" />}
          />
          <Bar
            dataKey="FENI"
            fill="#34ace0"
            activeBar={<Rectangle fill="#34ace0" stroke="blue" />}
          />
          <Bar
            dataKey="NABINAGAR"
            fill="#33d9b2"
            activeBar={<Rectangle fill="#33d9b2" stroke="blue" />}
          />
          <Bar
            dataKey="CHATKHIL"
            fill="#2c2c54"
            activeBar={<Rectangle fill="#2c2c54" stroke="blue" />}
          />
          <Bar
            dataKey="Faridpur"
            fill="#474787"
            activeBar={<Rectangle fill="#474787" stroke="blue" />}
          />
          <Bar
            dataKey="Jamalpur"
            fill="#227093"
            activeBar={<Rectangle fill="#227093" stroke="blue" />}
          />
          <Bar
            dataKey="Jhenaidah"
            fill="#218c74"
            activeBar={<Rectangle fill="#218c74" stroke="blue" />}
          />
          <Bar
            dataKey="Kushtia"
            fill="#ff5252"
            activeBar={<Rectangle fill="#ff5252" stroke="blue" />}
          />
          <Bar
            dataKey="Meherpur"
            fill="#ffb142"
            activeBar={<Rectangle fill="#ffb142" stroke="blue" />}
          />
          <Bar
            dataKey="Saidpur"
            fill="#ff793f"
            activeBar={<Rectangle fill="#ff793f" stroke="blue" />}
          />
          <Bar
            dataKey="Sakhipur"
            fill="#F0E68C"
            activeBar={<Rectangle fill="#b33939" stroke="blue" />}
          />
          <Bar
            dataKey="Tarabo"
            fill="#cd6133"
            activeBar={<Rectangle fill="#cd6133" stroke="blue" />}
          />
          <Bar
            dataKey="Teknaf"
            fill="#cc8e35"
            activeBar={<Rectangle fill="#cc8e35" stroke="blue" />}
          />
          <Bar
            dataKey="Shibganj"
            fill="#ef5777"
            activeBar={<Rectangle fill="#ef5777" stroke="blue" />}
          />
          <Bar
            dataKey="Jessore"
            fill="#05c46b"
            activeBar={<Rectangle fill="#05c46b" stroke="blue" />}
          />
          <Bar
            dataKey="Ghatail"
            fill="#00d8d6"
            activeBar={<Rectangle fill="#00d8d6" stroke="blue" />}
          />
          <Bar
            dataKey="Lalmonirhat"
            fill="#485460"
            activeBar={<Rectangle fill="#485460" stroke="blue" />}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
