import React, { Component } from "react";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import AboutUs from "../../components/AboutUs";
import Footer from "../../components/Footer";
import { Styles } from "./styles/about.js";
import Header from "../../components/Header";
import TeamSlider from "../../components/TeamSlider";
import ScrollAnimation from "react-animate-on-scroll";

class About extends Component {
  render() {
    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper about-page">
          {/* Header 2 */}
          <Header />

          {/* Breadcroumb */}
          <BreadcrumbBox title="About Us" img="slider1.jpg" />

          {/* About Area */}
          <AboutUs />

          {/* Team Slider */}

          <ScrollAnimation animateIn="fadeInDown">
            <TeamSlider />
          </ScrollAnimation>

          {/* Footer 2 */}
          <Footer />
        </div>
      </Styles>
    );
  }
}

export default About;
