import React, { PureComponent } from "react";
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Line,
    ComposedChart,
} from "recharts";
import { data } from "./dashboardData1";
export const RenderLegend = (props) => {
    const { payload } = props;

    return (
        <div
            className="d-flex justify-content-center"
            style={{ whiteSpace: "nowrap", flexWrap: "wrap" }}
        >
            {payload.map((entry, index) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                        style={{
                            height: "10px",
                            width: "10px",
                            background: entry.color,
                        }}
                    ></div>
                    <span className="mx-2" key={`item-${index}`}>
                        {entry.value.replace(/_/g, " ")}
                    </span>
                </div>
            ))}
        </div>
    );
};
export const RenderTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{ backgroundColor: "white", padding: "4px" }}>
                <p className="label">{`${label} `}</p>
                <div>
                    {payload.map((pld) => {
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "10px 20px",
                                }}
                            >
                                <p>
                                    {pld.dataKey.replace(/_/g, " ")}:{" "}
                                    {pld.value}
                                </p>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    return null;
};
const SFDDashboardChart1 = () => {
    return (
        <div
            style={{
                height: "600px",
                border: "2px solid lightgray",
                borderRadius: "8px",
                padding: "10px",
                marginBottom: "20px",
            }}
        >
            <p className="bar-title">
                Multi-city, 55 cities, total population:13002032 <br />{" "}
                Proportion of population with safely managed and unsafely
                managed sanitation by city
            </p>
            <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 100,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="name"
                        interval={0}
                        tick={{
                            angle: -45,
                            textAnchor: "end",
                            dominantBaseline: "middle",
                            fontSize: 8,
                        }}
                    />
                    <YAxis
                    // label="Proportion of population(%)"
                    // tick={{
                    //   angle: 270,
                    // }}
                    />
                    <Tooltip content={RenderTooltip} />
                    <Legend content={RenderLegend} />
                    <Bar
                        dataKey="Onsite_safely_managed"
                        stackId="a"
                        fill="green"
                    />
                    <Bar
                        dataKey="Offsite_safely_managed"
                        stackId="a"
                        fill="#82ca9d"
                    />
                    <Bar
                        dataKey="Onsite_unsafely_managed"
                        stackId="a"
                        fill="#FD9989"
                    />
                    <Bar
                        dataKey="Offsite_unsafely_managed"
                        stackId="a"
                        fill="#FE664E"
                    />
                    <Bar dataKey="Open_defecation" stackId="a" fill="#FE2402" />
                    <Line
                        type="monotone"
                        dataKey="total_safe"
                        stroke="#000000"
                    />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
};

export default SFDDashboardChart1;
