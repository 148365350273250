export const homeData = {
  sliders: [
    // {
    //   id: 1,
    //   title:
    //     "<p>Integrated Waste Management Plant for <br/><span>100+ Municipalities</span> </p>",
    //   subtitle: "",
    //   description:
    //     "Water safety involves practices to protect people in and around water, including swimming skills, life jacket use, and awareness of water conditions, to prevent drowning accidents.",
    //   isShowContactButton: true,
    //   imgUrl: "/assets/images/slider1.jpg",
    // },
    {
      id: 1,
      title: "", //<p>Shit Flow Diagram (SFD) Developed by<br/> CWIS FSM Support Cell</p>",
      subtitle: "Slider 3 subtitle",
      description: "",
      isButton1: true,
      button1Url: "/shit-flow-diagram",
      button1Text: "Click here for Details",
      imgUrl: "/assets/images/SFD.png",
    },
    {
      id: 2,
      title: "INTEGRATED WASTE MANAGEMENT (IWM)",
      subtitle: "",
      description: "",
      isButton1: true,
      button1Url: "/iwmdetails",
      button1Text: "Click for details",
      imgUrl: "/assets/images/slider-1.png",
    },
    {
      id: 3,
      title: "",
      subtitle: "",
      description: "",
      isButton1: true,
      button1Url: "/cwisscoring",
      button1Text: "Click here for CWIS Scoring",
      imgUrl: "/assets/images/slider-scoring.png",
    },
    {
      id: 4,
      title: "",
      subtitle: "",
      description: "",
      isButton1: true,
      button1Url: "/wfd",
      button1Text: "Click here for detail report",
      imgUrl: "/assets/images/WFD.png",
    },
    {
      id: 5,
      title: "Policy and Advocacy",
      subtitle: "",
      description: "",
      isButton1: false,
      button1Url: "",
      button1Text: "",
      imgUrl: "/assets/images/PolicyandAdvocacy.png",
    },
    {
      id: 6,
      title: "Gender Equality and Women Empowerment",
      subtitle: "",
      description: "",
      isButton1: true,
      button1Url: "/gender-equality",
      button1Text: "Click for Details",
      imgUrl: "/assets/images/GenderEqualityandWomenEmpowerment.png",
    },
    {
      id: 6,
      title: "Capacity Building",
      subtitle: "",
      description: "",
      isButton1: false,
      button1Url: "",
      button1Text: "",
      imgUrl: "/assets/images/CapacityBuilding.png",
    },
  ],
};
