import React, { Component } from "react";
import { partnerSliderData } from "../data/partner-slider/partnerSlider.js";
import { Container, Row, Col } from "react-bootstrap";
import Swiper from "react-id-swiper";
import { Styles } from "./styles/partnerSlider.js";

class PartnerSlider extends Component {
    render() {
        const settings = {
            slidesPerView: 5,
            loop: true,
            speed: 1000,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
            spaceBetween: 30,
            watchSlidesVisibility: true,
            // pagination: {
            //     el: ".slider-dot.text-center",
            //     clickable: true,
            // },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                },
                576: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 3,
                },
                992: {
                    slidesPerView: 5,
                },
            },
        };

        return (
            <Styles>
                {/* Team Slider */}
                <section className="team-member-area">
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="sec-title text-center">
                                    <h4>{partnerSliderData.title}</h4>
                                </div>
                            </Col>
                            <Col md="12" className="team-slider">
                                <Swiper {...settings}>
                                    {partnerSliderData.dataList.map(
                                        (data, i) => (
                                            <div className="team-item" key={i}>
                                                <div className="img-content text-center">
                                                    <img
                                                        src={
                                                            process.env
                                                                .PUBLIC_URL +
                                                            `/assets/images/${data.logo}`
                                                        }
                                                        alt=""
                                                        className="img-fluid"
                                                    />
                                                </div>
                                            </div>
                                        )
                                    )}
                                </Swiper>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>
        );
    }
}

export default PartnerSlider;
