import styled from "styled-components";
import { colors } from "../../../components/common/element/elements";

export const Styles = styled.div`
  .about-page {
    .icon-box-area {
      padding-bottom: 72px;
      .full-icon-box {
        .icon-box {
          position: inherit;
          top: 0;
          left: 0;
          width: unset;
        }
      }

      @media (max-width: 767px) {
        padding: 0 0 10px;
      }
    }
    .custom-card {
      margin-bottom: 20px;
      width: 400px;
      h2 {
        font-size: 14px;
        margin: 10px 0;
      }
      &:hover {
        transform: scale(1.1);
        transition: 0.5s;
        cursor: pointer;
      }
      .join-btn {
        a {
          font-size: 12px;
          color: #fff;
          background: ${colors.gr_bg};
          font-weight: 500;
          text-transform: uppercase;
          padding: 9px 12px 7px;
          border-radius: 1rem;
          &:hover {
            color: #ffffff;
            background: ${colors.gr_bg2};
            border-color: ${colors.water};
          }

          @media (max-width: 767px) {
            float: left;
            padding: 5px 10px 3px;
          }
        }
      }
    }
    .video-play-button {
      position: absolute;
      z-index: 10;
      top: 50%;
      left: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      box-sizing: content-box;
      display: block;
      width: 32px;
      height: 44px;
      /* background: #fa183d; */
      border-radius: 50%;
      padding: 18px 20px 18px 28px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .video-play-button:before {
      content: "";
      position: absolute;
      z-index: 0;
      left: 50%;
      top: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      display: block;
      width: 80px;
      height: 80px;
      background: ${colors.water};
      border-radius: 50%;
      -webkit-animation: pulse-border 1500ms ease-out infinite;
      animation: pulse-border 1500ms ease-out infinite;
    }
    .video-play-button:before:hover {
      background: green;
    }

    .video-play-button:after {
      content: "";
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      display: block;
      width: 80px;
      height: 80px;
      background: ${colors.bg1};
      border-radius: 50%;
      -webkit-transition: all 200ms;
      transition: all 200ms;
    }

    .video-play-button:hover:after {
      background-color: red;
    }

    .video-play-button img {
      position: relative;
      z-index: 3;
      max-width: 100%;
      width: auto;
      height: auto;
    }

    .video-play-button span {
      display: block;
      position: relative;
      z-index: 3;
      width: 0;
      height: 0;
      border-left: 20px solid white;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
    }

    @-webkit-keyframes pulse-border {
      0% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
          scale(1);
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
      }
      100% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
          scale(1.5);
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
      }
    }

    @keyframes pulse-border {
      0% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
          scale(1);
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
      }
      100% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
          scale(1.5);
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
      }
    }
    .video-overlay {
      position: fixed;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.8);
      opacity: 0;
      -webkit-transition: all ease 500ms;
      transition: all ease 500ms;
    }

    .video-overlay.open {
      position: fixed;
      z-index: 1000;
      opacity: 1;
    }

    .video-overlay-close {
      position: absolute;
      z-index: 1000;
      top: 15px;
      right: 20px;
      font-size: 36px;
      line-height: 1;
      font-weight: 400;
      color: #fff;
      text-decoration: none;
      cursor: pointer;
      -webkit-transition: all 200ms;
      transition: all 200ms;
    }

    .video-overlay-close:hover {
      color: #fa183d;
    }

    .video-overlay iframe {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      /* width: 90%; */
      /* height: auto; */
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.75);
    }
  }
`;
