import React, { Component } from "react";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import { Styles } from "./styles/gallery.js";
import Header from "../../components/Header";
import GalleryTabs from "../../components/GalleryTabs";
import Footer from "../../components/Footer";

class Gallery extends Component {
    render() {
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper gallery-page">
                    {/* Header 2 */}
                    <Header />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Gallery" />

                    <GalleryTabs />

                    {/* Footer 2 */}
                    <Footer />
                </div>
            </Styles>
        );
    }
}

export default Gallery;
