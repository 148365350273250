import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/stickyMenu.js";
import Menu from "./menu/Menu.js";
import { menuList } from "../../data/menu/menuList.js";

function StickyMenu() {
    useEffect(() => {
        window.addEventListener("scroll", () => {
            const stickyMenu = document.querySelector(".sticky-menu");

            if (window.scrollY > 80) {
                stickyMenu.classList.add("sticky");
            } else {
                stickyMenu.classList.remove("sticky");
            }
        });
    });

    return (
        <Styles>
            {/* Sticky Menu */}
            <section
                className="sticky-menu"
                style={{
                    height: "90px",
                }}
            >
                <Container>
                    <Row>
                        <Col md="3">
                            <div
                                className="logo"
                                style={{
                                    marginTop: "-12px",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Link to={process.env.PUBLIC_URL + "/"}>
                                    <div className="d-flex align-items-center ">
                                        <img
                                            style={{
                                                width: "70px",
                                                height: "70px",
                                            }}
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/assets/images/logo-bd.png"
                                            }
                                            alt=""
                                        />
                                        <img
                                            style={{
                                                width: "100px",
                                                height: "90px",
                                            }}
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/assets/images/logo-company.png"
                                            }
                                            alt=""
                                        />
                                    </div>
                                </Link>
                            </div>
                        </Col>
                        <Col md="9">
                            <div
                                className="menu-box d-flex justify-content-end"
                                style={{
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Menu menuData={menuList} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Styles>
    );
}

export default StickyMenu;
