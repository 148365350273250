import React from "react";
import Header from "../../components/Header";
import { workshopSeminarData } from "../../data/workshop-seminar/WorkshopSeminarData";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/Footer";

const WorkshopSeminar = () => {
  return (
    <div>
      <Header />
      <div
        style={{
          height: "250px",
          width: "100%",
          backgroundSize: "cover",
          backgroundPosition: "50%",
          backgroundBlendMode: "color",
          backgroundImage: `url(${
            window.location.origin + workshopSeminarData.backgroundImg
          })`,
        }}
      >
        <h1
          style={{
            backgroundColor: `rgba(249, 246, 239, 0.4)`,
            fontWeight: "bold",
            height: "100%",
            color: "#000",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {workshopSeminarData.title}
        </h1>
      </div>

      {/* <Container>
        <Row>
          <Col md={12}>
            <p
              className="mt-4"
              style={{
                fontWeight: 400,
                color: "#000",
                fontSize: "16px",
                textAlign: "justify",
              }}
            >
              {workshopSeminarData.shortDescription}
            </p>
            <br />
            <br />

            <p style={{ fontWeight: 400, color: "#000", fontSize: "16px" }}>
              {workshopSeminarData.itemDefination}
            </p>
            <div className="d-flex flex-column ">
              {workshopSeminarData.items.map((item) => (
                <div
                  key={item.id}
                  className="mt-4 d-flex align-items-baseline  justify-content-start space"
                  style={{ gap: "10px" }}
                >
                  <i class="far fa-calendar-plus"></i>
                  <span
                    style={{ fontWeight: 400, color: "#000", fontSize: "16px" }}
                  >
                    {item.description}
                  </span>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container> */}
      <Footer />
    </div>
  );
};

export default WorkshopSeminar;
