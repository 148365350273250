export const workshopSeminarData = {
  title: "Workshops and Seminars",
  backgroundImg: "/assets/images/Workshop-and-Seminar-scaled.jpg",
  shortDescription:
    "To address emerging sector issues and build consensus on probable solutions, ITN organizes workshops, seminars, symposiums etc., either in collaboration or individually. As in the recent past, ITN actively supports and assists the initiative of the government, other stakeholders and ESAs in these kinds of activities.",
  itemDefination:
    "ITN’s involvement in the following national and international workshops, seminars and conferences:",
  items: [
    {
      id: 1,
      description:
        "26 WEDC Conference held at Dhaka during 5-9 November 2000.\n ITN-Bangladesh was responsible to organize and manage the technical sessions of the conference.",
    },
    {
      id: 2,
      description: `
            
BUET-UNU International Workshop on Technologies for Arsenic Removal from Drinking water 5-7 May 2001, Dhaka.
ITN-Bangladesh provided technical assistance in organizing the workshop.`,
    },
    {
      id: 3,
      description: `nternational Workshop on Arsenic Mitigation in Bangladesh,
            Organized by Local Government Division, Ministry of Local Government, Rural Development and Cooperatives, held during 14-16 January 2002 in Dhaka.`,
    },
    {
      id: 4,
      description: `Discussion Session/Seminar on Environmental Issues 13 March 2002.`,
    },
    {
      id: 5,
      description: `
            International Conference on Bangladesh Environment 2002, DU, Dhaka 19-21 December 2002`,
    },
    {
      id: 6,
      description: `
            Discussion Session/Seminar on “Community Based Arsenic & Iron Removal Unit (AIRU)” 5 May 2002.`,
    },
    {
      id: 7,
      description: `Feed-back workshop on Field Test of Modified Community Based Iron & Arsenic Removal Unit, BUET, 28 May 2002
            ITN-Bangladesh was responsible for the technical front of the workshop.`,
    },
    {
      id: 8,
      description: `Presentation session/seminar on Rainwater Harvesting System, September 2002.`,
    },
    {
      id: 9,
      description: `Presentation session/seminar on the Findings of Field Testing of Shafi Filter, 12 September 2002.
            `,
    },
    {
      id: 10,
      description: `Presentation session/seminar on the Findings of Modified Community Based Iron & Arsenic Removal Unit, 28 September 2002.
            `,
    },
    {
      id: 11,
      description: `Presentation session/seminar on Rainwater Harvesting System, 28 October 2002.
            `,
    },
    {
      id: 12,
      description: `Workshop on Leaching of Arsenic from Wastes of Arsenic Removal System, 30 October 2002.
            `,
    },
    {
      id: 13,
      description: `Seminar of the Task Force constituted by IEB on Water Supply and Sanitation, 4 December 2002.
            `,
    },
    {
      id: 14,
      description: `International Workshop on Fate of Arsenic in the Environment at BUET, Dhaka, 05-06 February 2003.
            `,
    },
    {
      id: 15,
      description: `National Dialogue on Sanitation Strategies and Technologies at BUET, Dhaka, Date: 11 October 2003.
            `,
    },
    {
      id: 16,
      description: `South Asian Conference on Sanitation SACOSAN 2003 at Dhaka 21-23 October 2003. Proceedings details are available at Books and Proceedings section.
            `,
    },
    {
      id: 17,
      description: `International Symposium on Environmental Management ISEM – 2004, at BUET, Dhaka 7-8 January 2004.
            `,
    },
    {
      id: 18,
      description: `Workshop on Environmental Management Water Supply and Solid Waste Management BUET, Dhaka, 10-11 January, 2004.
            `,
    },
    {
      id: 19,
      description: `Seminar on Integrated Solid Waste Management: Sanitary Landfill, Transfer, Special Issues, Dhaka 31 May to 1 June, 2004.`,
    },
    {
      id: 20,
      description: `Workshop on Arsenic Mitigation Water Supply Technologies (Emergency Response) 15 July, 2004.
            `,
    },
    {
      id: 21,
      description: `Workshop on National Sanitation Strategy 2004 at ITN Centre, BUET (1st Workshop), 8 August 2004.
            `,
    },
    {
      id: 22,
      description: `Workshop on National Sanitation Strategy 2004 at ITN Centre, BUET (2nd Workshop), 22 August 2004.
            `,
    },
    {
      id: 23,
      description: `Workshop on Water Safety plan at ITN Centre, BUET (1st Workshop), 6-8 November 2004.`,
    },
    {
      id: 24,
      description: `Workshop on Water Safety Plan at ITN Centre, BUET (2nd Workshop), 7-9 December 2004.
            `,
    },
    {
      id: 25,
      description: `Seminar on National Urban Sanitation at BUET, 13 January 2005.
            `,
    },
    {
      id: 26,
      description: `Workshop on National Sanitation Strategy at BIAM, 26 February 2005.
            `,
    },
    {
      id: 27,
      description: `Presentation Session/Seminar at ITN Centre on the Findings of Field Testing of Shafi Filter 12 September 2002.
            `,
    },
    {
      id: 28,
      description: `Presentation Session/Seminar at ITN Centre on the Findings of Community Beast Arsenic & Iron Removal Unit (AIRU) 28 September 2002
            Second Session of the Rain Water Forum Venue: ITN Centre Date 28 October 2002.`,
    },
    {
      id: 29,
      description: `Presentation Session on the Findings of the research project on “Leaching of Arsenic from Wastes of Arsenic Removal Systems” 30 October 2002.
            `,
    },
    {
      id: 30,
      description: `Seminar of the Task Force constituted by IEB on Water Supply and Sanitation, 25 January 2003
            Presentation Session on “Economic and Financial Models for Appraisal of Water Treatment Options (Surface and Groundwater)”, 12 May 2003.`,
    },
    {
      id: 31,
      description: `Roundtable discussion on Hospital Waste Management, 25 October, 2003.
            `,
    },
    {
      id: 32,
      description: `Roundtable discussion on sanitation, 13 May 2004.
            `,
    },
    {
      id: 33,
      description: `Workshop on Quality Assurance System for Training Programmes, 26 May, 2004.

            `,
    },
    {
      id: 34,
      description: `Workshop on Quality Assurance System for Training Programmes, 10 June, 2004.
            `,
    },
    {
      id: 35,
      description: `The Methodological Workshop of the project” Capacity Building for Enhancing Local Participation in Water Supply and Sanitation Interventions in Poor Urban Areas”, ITN-BUET, 10-11 February 2007.

            `,
    },
    {
      id: 36,
      description: `Consultation meeting/ Workshop on Research Topic Identification, ITN-BUET, 5 March 2007.

            `,
    },
    {
      id: 37,
      description: `Sanitation Consultation Workshop at Khulna Division, 22 May 2007.

            `,
    },
    {
      id: 38,
      description: `Sanitation Consultation Workshop at Rajshahi Division, 24 May 2007.

            `,
    },
    {
      id: 39,
      description: `Sanitation Consultation Workshop at Chittagong Division, 22 May 2007.

            `,
    },
    {
      id: 40,
      description: `Technical Session on Gender & Participation in WSS, ITN Sub Centre, DMPI, 27 May 2007.

            `,
    },
    {
      id: 41,
      description: `Technical session on Management of dye effluent from Industries- A case study, SUST, 27 May 2007.

            `,
    },
    {
      id: 42,
      description: `Symposium on Promotion of Total Sanitation, Dhaka, 24 June 2007.

            `,
    },
    {
      id: 43,
      description: `Technical session on Rainwater Harvesting System (RWHS) in Slum Areas as an Alternative Source of Water, ITN-BUET, 13 September 2007.

            `,
    },
    {
      id: 44,
      description: `National Seminar on Environmental Sanitation in Bangladesh: Challenges and Way Forward, 29 October 2007.

            `,
    },
    {
      id: 45,
      description: `Consultation Workshop for Review and Updating the Existing Environmental Engineering Curricula of Undergraduate Level, ITN-BUET, 25 March 2008.

            `,
    },
    {
      id: 46,
      description: `Technical Session on Research Findings of “Coping with Arsenicosis: Socio-economic and Gender Response to Arsenic Contamination in Groundwater in Bangladesh”, ITN-BUET, 20 August 2008.

            `,
    },
    {
      id: 47,
      description: `Consultation Workshop on the draft report on the review exercise of the Existing Environmental Engineering Curricula of Undergraduate Level, ITN-BUET, 28 August 2008.

            `,
    },
    {
      id: 48,
      description: `International Conference on Climate Change Impacts and Adaptation Strategies for Bangladesh, BUET, 18-20 February 2009.

            `,
    },
    {
      id: 49,
      description: `International Conference on Climate Change Impacts and Adaptation Strategies for Bangladesh, BUET, 18-20 February 2009.

            `,
    },
    {
      id: 50,
      description: `Technical session on Rainwater Harvesting System in Slum Areas as an Alternative Source of Water, 23 May 2009.

            `,
    },
    {
      id: 51,
      description: `Technical session on Situation Analysis: Institutional Mechanism in Water Supply and Sanitation Interventions in Poor Urban Areas, 23 May 2009.

            `,
    },
    {
      id: 52,
      description: `Technical session on Waste Pickers Livelihoods, their vulnerability and possible measures to Overcome the existing condition, 26 May 2009.

            `,
    },
    {
      id: 53,
      description: `Technical session on Research findings of Performance of modified AIRD under different hydro geological conditions, 17 August 2009.

            `,
    },
    {
      id: 54,
      description: `Technical session conservation of water through the use of water efficient appliances in Dhaka city, 8 November 2009.

            `,
    },
    {
      id: 55,
      description: `Sharing session on Teaching materials on Solid Waste Management 13 December 2009.

            `,
    },
    {
      id: 56,
      description: `Consultation Workshop on Research Need Assessment, 2 August 2010.

            `,
    },
    {
      id: 57,
      description: `Technical Session on Research Findings of “Removal of Manganese and Arsenic from Groundwater using Manganese Oxide Coated Sand” held on 09 May 2011.

            `,
    },
    {
      id: 58,
      description: `Workshop on Water Safety Plan (WSP) Operational Monitoring held on 10 May 2011.

            `,
    },
    {
      id: 59,
      description: `Technical Session on Research Findings of “Possibility and Scope of Reusing Textile Wash Water directly or with Minimum Treatment for Pretreatment (Scouring and Bleaching) of Cotton Goods” held on 21 June 2011.

            `,
    },
    {
      id: 60,
      description: `Technical session on Development of Mathematical Models of Composting in Active and Passive Aeration, 27 September 2011.

            `,
    },
    {
      id: 61,
      description: `Technical session on Feasibility Study for using water ways in transportation of solid waste of Dhaka City and preparing GIS based route management system for DCC solid waste management, 27 September 2011.


            `,
    },
    {
      id: 62,
      description: `Technical Session on Assessing Climate Change Impacts on Surface Water of Bangladesh, 25 October 2011.


            `,
    },
  ],
};
