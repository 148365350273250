import React, { Component, useEffect, useState } from "react";
import Datas from "../data/about-us/about-us.json";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/aboutUs.js";
import $ from "jquery";
import ScrollAnimation from "react-animate-on-scroll";
import { colors } from "./common/element/elements.js";

const HomeAbout = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    $("#video").bind("contextmenu", function () {
      return false;
    });
  }, []);

  const openModal = () => {
    setIsOpen(true);
  };
  const playVideo = () => {
    setIsPlaying(true);
    const video = document.getElementById("video");
    video.play();
  };
  return (
    <Styles>
      {/* About Us */}
      <section className="about-us">
        <Container>
          <Row>
            <Col md="12">
              <div style={{ textAlign: "center" }}>
                <h4
                  style={{
                    color: "#182B49",
                    lineHeight: "35px",
                    fontWeight: 600,
                    maxWidth: "550px",
                    margin: "auto",
                    marginBottom: "48px",
                  }}
                >
                  About Us
                </h4>
              </div>
            </Col>
            <Col md="9">
              <Row>
                <Col md="6">
                  <ScrollAnimation animateIn="fadeInLeft">
                    <div className="about-content">
                      <p className="about-para">{Datas.desc2}</p>
                    </div>
                  </ScrollAnimation>
                </Col>
                <Col md="6">
                  <ScrollAnimation animateIn="fadeInRight">
                    <div className="about-image">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          `/assets/images/${Datas.mainImage}`
                        }
                        className="main-img"
                        alt=""
                      />
                    </div>
                  </ScrollAnimation>
                </Col>
              </Row>
            </Col>
            <Col
              md="3"
              style={{
                background: colors.gr_bg,
                color: "white",
                borderRadius: "16px",
              }}
            >
              <div
                style={{
                  padding: "20px 5px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <h5>Contact Us</h5>
                <p>
                  Get in touch with us and contact us for further enquiry and
                  suggestion.
                </p>
                <div className="contact-btn">
                  <a href="/contact">
                    <i className="fa fa-phone "> &nbsp;Contact Us</i>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-5 justify-content-md-center">
            <Col
              md={9}
              className="d-flex  align-items-center  justify-content-center "
            >
              <div
                style={{
                  position: "relative",
                  width: "70%",
                }}
              >
                <ScrollAnimation animateIn="fadeInUp">
                  {isPlaying ? null : (
                    <div
                      style={{
                        position: "relative",
                        background: "rgba(0, 0, 0, 0.5)",
                        cursor: "pointer",
                        width: "100%",
                      }}
                      onClick={playVideo}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/${Datas.videoBackground}`} // Replace with your play button image
                        alt="Play Button"
                        style={{ width: "100%" }}
                      />
                      {/* <i
                                                //fas fa-play
                                                className=""
                                                // style={{
                                                //   fontSize: "50px",
                                                //   position: "absolute",
                                                //   top: "50%",
                                                //   left: "50%",
                                                //   transform: "translate(-50%,-50%)",
                                                //   border: "4px solid gray",
                                                //   height: "100px",
                                                //   width: "100px",
                                                //   borderRadius: "50%",
                                                //   display: "flex",
                                                //   alignItems: "center",
                                                //   justifyContent: "center",
                                                // }}
                                            >
                                                <span className="video-play-button"></span>
                                            </i> */}
                      <div id="play-video" class="video-play-button">
                        <span></span>
                      </div>
                    </div>
                  )}
                  <video
                    oncontextmenu="return false;"
                    id="video"
                    controls
                    style={{
                      display: isPlaying ? "block" : "none",
                      maxHeight: "350px",
                      width: "100%",
                    }}
                    controlsList="nodownload"
                  >
                    <source
                      src={`${process.env.PUBLIC_URL}/assets/video/CWIS_ecosystem_v10.mp4`}
                      type="video/mp4"
                    />
                  </video>
                  <p
                    className="my-2 "
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    <sup>*</sup>If you want to see the video please click over
                    the image
                  </p>
                </ScrollAnimation>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
};

export default HomeAbout;
