import React, { useState } from "react";
import { Styles } from "../award-event/styles/awardEvent";
import Header from "../../components/Header";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/Footer";
import "./styles/event-common.css";

const Event5 = () => {
  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper event-page">
        {/* Header 2 */}
        <Header />

        {/* Breadcroumb */}
        <BreadcrumbBox title="News" img="/news19/19_thumb.jpg" />
        <Container>
          <Row>
            <Col>
              <a
                href="/news"
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  lineHeight: "40px",
                }}
              >
                Back To News
              </a>
            </Col>
          </Row>
        </Container>

        {/* News Area */}
        <section className="award-page">
          <Container>
            <Row>
              <Col md="12">
                <div style={{ padding: "20px 0" }}>
                  <h2
                    style={{
                      width: "100%",
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    Training on Gender Inclusion in WASH Services for
                    Municipality Personnel
                  </h2>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: 500,
                      padding: "20px 0",
                    }}
                  >
                    May 18th to May 20th, 2024
                  </p>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        maxHeight: "550px",
                      }}
                      src={
                        process.env.PUBLIC_URL +
                        `/assets/images/news19/19_thumb.jpg`
                      }
                      alt="thumb"
                    />
                  </div>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "20px 0",
                    }}
                  >
                    A three-day program comprising two days of training and a
                    one-day exposure visit was conducted under the Integrated
                    Sanitation & Hygiene Project in 10 priority towns in
                    Bangladesh by DPHE at ITN BUET and Faridpur Municipality.
                    The program took place from May 18th to May 20th, 2024.
                    Among the ten towns, four municipalities and one city
                    corporation were selected for the training program. The
                    CWIS-FSM Support Cell, DPHE, and the International Training
                    Network of Bangladesh University of Engineering and
                    Technology (ITN-BUET) jointly organized the training. The
                    objective was to enhance the knowledge and skills of
                    municipal personnel on gender equity and mainstreaming in
                    every facet of their operations, including planning,
                    management, and monitoring processes.
                  </p>

                  <p
                    style={{
                      width: "100%",
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "black",
                      padding: "10px 0",
                    }}
                  >
                    A training needs assessment was undertaken for the 10-town
                    project to identify the specific skills and competencies
                    required for successful implementation and to ensure role
                    clarity among city officials and relevant stakeholders. This
                    training needs assessment was conducted jointly by CWIS-FSM
                    Support Cell and ITN BUET. According to the TNA report,
                    Cumilla City Corporation was identified as having a high
                    priority for gender training, while Lakshmipur, Pabna,
                    Natore, and Jamalpur municipalities were identified as
                    having a moderate priority for gender training. Based on
                    this assessment, among the 10 towns, one city corporation
                    and four municipalities were selected for training on the
                    manual "Gender Inclusive WASH Services for Municipality
                    Personnel."
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={9}>
                <p
                  style={{
                    width: "100%",
                    textAlign: "justify",
                    fontSize: "16px",
                    color: "black",
                    padding: "20px 0",
                  }}
                >
                  The CWIS-FSM Support Cell, DPHE, and ITN BUET collaboratively
                  developed a training manual titled "Gender Inclusion in WASH
                  Services for Municipality Personnel." This manual focuses on
                  enhancing the inclusivity, gender responsiveness, and
                  effectiveness of water, sanitation, and hygiene (WASH)
                  services. It provides municipal personnel with practical
                  guidance on integrating gender-responsive and inclusive
                  practices into their work.
                </p>
              </Col>
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "350px",
                    }}
                    src={process.env.PUBLIC_URL + `/assets/images/news19/1.jpg`}
                    alt="thumb"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <p
                  style={{
                    width: "100%",
                    textAlign: "justify",
                    fontSize: "16px",
                    color: "black",
                    padding: "20px 0",
                  }}
                >
                  During the opening session of the training, the Chief Engineer
                  of DPHE, Mr. Tusher Mohon Shadhu Khan, delivered his valuable
                  speech as the Chief Guest. Alongside him, Mr. Shafiqul Hassan,
                  Co-Chair and Mr. Abdullah Al Muyeed, COO of CWIS-FSM Support
                  Cell, participated as special guests. Professor Mr. Tanvir
                  Ahmed, Director of ITN BUET, and Mr. Alauddin Ahmed, Project
                  Manager of ITN BUET, were also present and provided their
                  valuable speeches. They each contributed to the event's
                  significance by sharing insights during the opening session of
                  the training.
                </p>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "750px",
                    }}
                    src={process.env.PUBLIC_URL + `/assets/images/news19/2.jpg`}
                    alt="thumb"
                  />
                </div>
                <p
                  style={{
                    width: "100%",
                    textAlign: "justify",
                    fontSize: "16px",
                    color: "black",
                    padding: "20px 0",
                  }}
                >
                  A total of 29 participants from four municipalities—Natore,
                  Sirajganj, Lakshmipur, and Pabna—and one City Corporation,
                  Faridpur, attended this training. Among the attendees were the
                  Pannel Mayors, male and female Councilors, the Poura Nirbahi
                  Officer, Executive Engineer, Assistant Engineer, Conservancy
                  Officer, and Social Development Officer.
                </p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "550px",
                    }}
                    src={process.env.PUBLIC_URL + `/assets/images/news19/3.jpg`}
                    alt="thumb"
                  />
                </div>
              </Col>
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "550px",
                    }}
                    src={process.env.PUBLIC_URL + `/assets/images/news19/4.jpg`}
                    alt="thumb"
                  />
                </div>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "550px",
                    }}
                    src={process.env.PUBLIC_URL + `/assets/images/news19/5.jpg`}
                    alt="thumb"
                  />
                </div>
              </Col>
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "550px",
                    }}
                    src={process.env.PUBLIC_URL + `/assets/images/news19/6.jpg`}
                    alt="thumb"
                  />
                </div>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "550px",
                    }}
                    src={process.env.PUBLIC_URL + `/assets/images/news19/7.jpg`}
                    alt="thumb"
                  />
                </div>
              </Col>
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "550px",
                    }}
                    src={process.env.PUBLIC_URL + `/assets/images/news19/8.jpg`}
                    alt="thumb"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "16px",
                    color: "black",
                    padding: "20px 0",
                  }}
                >
                  Participants contributed vibrantly through their individual
                  and group participation.
                </p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "550px",
                    }}
                    src={process.env.PUBLIC_URL + `/assets/images/news19/9.jpg`}
                    alt="thumb"
                  />
                </div>
              </Col>
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "550px",
                    }}
                    src={
                      process.env.PUBLIC_URL + `/assets/images/news19/10.jpg`
                    }
                    alt="thumb"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "550px",
                    }}
                    src={
                      process.env.PUBLIC_URL + `/assets/images/news19/11.jpg`
                    }
                    alt="thumb"
                  />
                </div>
              </Col>
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "550px",
                    }}
                    src={
                      process.env.PUBLIC_URL + `/assets/images/news19/12.jpg`
                    }
                    alt="thumb"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "16px",
                    color: "black",
                    padding: "20px 0",
                  }}
                >
                  Exposutre visit at Faridpur Municipality
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "550px",
                    }}
                    src={
                      process.env.PUBLIC_URL + `/assets/images/news19/13.jpg`
                    }
                    alt="thumb"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "16px",
                    color: "black",
                    padding: "20px 0",
                  }}
                >
                  Certificate-awarded participants at Gender Inclusive WASH
                  Training program
                </p>
              </Col>
            </Row>

            <Row>
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "550px",
                    }}
                    src={
                      process.env.PUBLIC_URL + `/assets/images/news19/14.jpg`
                    }
                    alt="thumb"
                  />
                </div>
              </Col>
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "550px",
                    }}
                    src={
                      process.env.PUBLIC_URL + `/assets/images/news19/15.jpg`
                    }
                    alt="thumb"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "16px",
                    color: "black",
                    padding: "20px 0",
                  }}
                >
                  PD and DPD of 10 Town project delivering their closing remarks
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p
                  style={{
                    width: "100%",
                    textAlign: "justify",
                    fontSize: "16px",
                    color: "black",
                    padding: "20px 0",
                  }}
                >
                  During the training session, participants contributed
                  vibrantly through their individual and group participation.
                  The Project Director (PD) Mr. Shamim Ahmed, Deputy Project
                  Director (DPD) Ms. Dilruba Farzana, and Mr. Alauddin Ahmed,
                  Project Manager of ITN BUET, provided their closing remarks.
                  After the training, all 29 participants received certificates.
                </p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "550px",
                    }}
                    src={
                      process.env.PUBLIC_URL + `/assets/images/news19/16.jpg`
                    }
                    alt="thumb"
                  />
                </div>
              </Col>
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "550px",
                    }}
                    src={
                      process.env.PUBLIC_URL + `/assets/images/news19/17.jpg`
                    }
                    alt="thumb"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "550px",
                    }}
                    src={
                      process.env.PUBLIC_URL + `/assets/images/news19/18.jpg`
                    }
                    alt="thumb"
                  />
                </div>
              </Col>
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "650px",
                    }}
                    src={
                      process.env.PUBLIC_URL + `/assets/images/news19/19.jpg`
                    }
                    alt="thumb"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "16px",
                    color: "black",
                    padding: "20px 0",
                  }}
                >
                  Resourse Person
                </p>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "550px",
                    }}
                    src={
                      process.env.PUBLIC_URL + `/assets/images/news19/20.jpg`
                    }
                    alt="thumb"
                  />
                </div>
              </Col>
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "550px",
                    }}
                    src={
                      process.env.PUBLIC_URL + `/assets/images/news19/21.jpg`
                    }
                    alt="thumb"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "550px",
                    }}
                    src={
                      process.env.PUBLIC_URL + `/assets/images/news19/22.jpg`
                    }
                    alt="thumb"
                  />
                </div>
              </Col>
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "650px",
                    }}
                    src={
                      process.env.PUBLIC_URL + `/assets/images/news19/23.jpg`
                    }
                    alt="thumb"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "550px",
                    }}
                    src={
                      process.env.PUBLIC_URL + `/assets/images/news19/24.jpg`
                    }
                    alt="thumb"
                  />
                </div>
              </Col>
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "650px",
                    }}
                    src={
                      process.env.PUBLIC_URL + `/assets/images/news19/25.jpg`
                    }
                    alt="thumb"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "16px",
                    color: "black",
                    padding: "20px 0",
                  }}
                >
                  Glimpses of the Training Program
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </Styles>
  );
};

export default Event5;
