import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
    .event-page {
        .event-page-area {
            padding: 70px 0;
            .publication-container {
                display: flex;
                align-itmes: center;
                justify-content: space-between;
                gap: 20px;
                flex-wrap: wrap;
            }
            .event-box {
                margin-bottom: 30px;
                box-shadow: 0 0px 20px rgba(0, 0, 0, 0.08);
                background: #ffffff;
                border-radius: 1rem;
                max-width: 570px;
                width: 540px;

                .event-img {
                    padding: 0;
                    // height: 190px !important;
                    a {
                        img {
                            border-radius: 1rem;
                            height: 250px;
                            width: 100%;
                        }
                    }

                    // @media (max-width: 991px) {
                    //   display: none;
                    // }
                }
                .event-content {
                    position: relative;
                    display: flex;
                    align-items: center;

                    .content-box1 {
                        padding: 50px 0;
                        .event-title {
                            h6 {
                                margin-bottom: 10px;
                                a {
                                    color: ${colors.black1};
                                    font-weight: 600;
                                    font-size: 15px;

                                    &:hover {
                                        color: ${colors.water};
                                    }
                                }
                            }
                        }
                        .join-btn {
                            margin-top: 50px;
                            a {
                                font-size: 12px;
                                color: white;
                                border: 1px solid ${colors.border3};
                                font-weight: 500;
                                text-transform: uppercase;
                                padding: 9px 12px 7px;
                                border-radius: 1rem;
                                background: ${colors.gr_bg};

                                &:hover {
                                    color: #ffffff;
                                    background: ${colors.gr_bg2};
                                    border-color: ${colors.water};
                                }

                                @media (max-width: 767px) {
                                    float: left;
                                    padding: 5px 10px 3px;
                                }
                            }
                        }
                    }
                    .content-box {
                        padding: 20px;
                        background: #ffffff;
                        border-radius: 1rem;
                        position: absolute;
                        top: 22px;
                        left: -9%;
                        z-index: 1;
                        .event-title {
                            h6 {
                                margin-bottom: 10px;
                                a {
                                    color: ${colors.black1};
                                    font-weight: 600;

                                    &:hover {
                                        color: ${colors.water};
                                    }
                                }
                            }
                        }

                        .event-time-location {
                            margin-bottom: 10px;
                            ul {
                                li {
                                    font-size: 13px;
                                    color: ${colors.text3};
                                    i {
                                        font-size: 18px;
                                        color: ${colors.water};
                                        vertical-align: top;
                                    }
                                    &:first-child {
                                        margin-right: 20px;
                                    }
                                }
                            }
                        }

                        .event-desc {
                            p {
                                font-size: 13px;
                                color: ${colors.text2};
                                line-height: 20px;
                            }

                            @media (max-width: 767px) {
                                margin-bottom: 10px;
                            }
                        }

                        .event-date {
                            position: relative;
                            margin-bottom: 20px;
                            padding-top: 6px;
                            &:before {
                                position: absolute;
                                content: "";
                                background: ${colors.border1};
                                width: 1px;
                                height: 100px;
                                top: 0;
                                left: -30px;

                                @media (max-width: 767px) {
                                    content: none;
                                }
                            }
                            p {
                                font-size: 20px;
                                color: ${colors.water};
                                font-weight: 500;
                                text-transform: uppercase;

                                @media (max-width: 767px) {
                                    float: left;
                                    font-size: 18px;
                                    margin-right: 20px;
                                }
                            }

                            @media (max-width: 767px) {
                                margin-bottom: 0;
                                padding-top: 0;
                            }
                        }

                        .join-btn {
                            a {
                                font-size: 12px;
                                color: ${colors.black2};
                                border: 1px solid ${colors.border3};
                                font-weight: 500;
                                text-transform: uppercase;
                                padding: 9px 12px 7px;
                                border-radius: 1rem;
                                &:hover {
                                    color: #ffffff;
                                    background: ${colors.gr_bg};
                                    border-color: ${colors.water};
                                }

                                @media (max-width: 767px) {
                                    float: left;
                                    padding: 5px 10px 3px;
                                }
                            }
                        }

                        @media (max-width: 991px) {
                            position: unset;
                        }
                    }
                }
                &:hover {
                    box-shadow: 0 0px 20px rgba(0, 0, 0, 0.2);
                    background: #e0e9fd;
                }
            }

            ul.pagination-box {
                margin-top: 50px;
            }

            .course-sidebar {
                @media (max-width: 991px) {
                    display: none;
                }
            }

            @media (max-width: 767px) {
                padding: 40px 0 35px;
            }
        }
    }
`;
