import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Styles } from "./../pages/gallery/styles/photoGallery.js";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const PhotoGallery = ({ data }) => {
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [currentAlbum, setCurrentAlbum] = useState(null);

    const openLightbox = (index, album) => {
        setPhotoIndex(index);
        setCurrentAlbum(album);
        setLightboxIsOpen(true);
    };

    const closeLightbox = () => {
        setLightboxIsOpen(false);
        setCurrentAlbum(null);
    };

    return (
        <Styles>
            <section className="gallery-page-area">
                <Container>
                    <div className="photo-gallery">
                        {data.map((album, albumIndex) => (
                            <div key={album.id} className="gallery-box">
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        `/assets/images/${album.thumbnail}`
                                    }
                                    alt={album.albumTitle}
                                    onClick={() => openLightbox(0, album)}
                                />
                                <h4>{album.albumTitle}</h4>
                            </div>
                        ))}
                    </div>
                    {lightboxIsOpen && currentAlbum && (
                        <Lightbox
                            mainSrc={
                                process.env.PUBLIC_URL +
                                `/assets/images/${currentAlbum.list[photoIndex].img}`
                            }
                            nextSrc={
                                process.env.PUBLIC_URL +
                                `/assets/images/${
                                    currentAlbum.list[
                                        (photoIndex + 1) %
                                            currentAlbum.list.length
                                    ].img
                                }`
                            }
                            prevSrc={
                                process.env.PUBLIC_URL +
                                `/assets/images/${
                                    currentAlbum.list[
                                        (photoIndex +
                                            currentAlbum.list.length -
                                            1) %
                                            currentAlbum.list.length
                                    ].img
                                }`
                            }
                            onCloseRequest={closeLightbox}
                            onMovePrevRequest={() =>
                                setPhotoIndex(
                                    (photoIndex +
                                        currentAlbum.list.length -
                                        1) %
                                        currentAlbum.list.length
                                )
                            }
                            onMoveNextRequest={() =>
                                setPhotoIndex(
                                    (photoIndex + 1) % currentAlbum.list.length
                                )
                            }
                        />
                    )}
                </Container>
            </section>
        </Styles>
    );
};

export default PhotoGallery;
