import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Swiper from "react-id-swiper";
import { Styles } from "./styles/heroSlider.js";

class HeroSlider extends Component {
  render() {
    const { data } = this.props;
    const settings = {
      slidesPerView: 1,
      loop: true,
      speed: 3000,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      watchSlidesVisibility: true,
      // effect: "fade",
      navigation: {
        nextEl: ".slider-button-next",
        prevEl: ".slider-button-prev",
      },
      renderPrevButton: () => (
        <div className="swiper-btn slider-button-prev">
          <i className="flaticon-arrow-left-th"></i>
        </div>
      ),
      renderNextButton: () => (
        <div className="swiper-btn slider-button-next">
          <i className="flaticon-arrow-right-th"></i>
        </div>
      ),
    };

    return (
      <Styles>
        {/* Hero Slider */}
        <section className="hero-slider-area">
          <Swiper {...settings}>
            {data &&
              data.length > 0 &&
              data.map((data, i) => (
                <div className="slider-item" key={i}>
                  <div className="image-container">
                    <div
                      className="slider-image"
                      style={{
                        height: "100%",
                        backgroundSize: data.id !== 4 ? "cover" : "contain",
                        backgroundPosition: "50%",
                        backgroundRepeat: "no-repeat",
                        backgroundImage: `url(${
                          window.location.origin + data.imgUrl
                        })`,
                      }}
                    ></div>
                  </div>
                  <div
                    className="slider-table"
                    style={{
                      backgroundColor: `rgba(249, 246, 239, 0.6)`,
                      color: "#000",
                    }}
                  >
                    <div className="slider-tablecell">
                      <Container>
                        <Row>
                          <Col md="12">
                            <div className="slider-box">
                              <div className="slider-title">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: data.title,
                                  }}
                                ></p>
                              </div>
                              {/* <div className="slider-desc">
                                <h1>{data.description}</h1>
                              </div> */}
                              {data.isButton1 && (
                                <div
                                  className="slider-btn"
                                  style={
                                    data.id === 1 || data.id === 3
                                      ? { marginTop: "160px" }
                                      : null
                                  }
                                >
                                  {data.id === 1 ||
                                  data.id === 2 ||
                                  data.id === 3 ||
                                  data.id === 4 ||
                                  data.id === 6 ? (
                                    <Link
                                      className="slider-btn2"
                                      to={
                                        process.env.PUBLIC_URL + data.button1Url
                                      }
                                    >
                                      {data.button1Text}
                                    </Link>
                                  ) : (
                                    <a
                                      className="slider-btn2"
                                      href={data.button1Url}
                                      target="_blank"
                                    >
                                      {data.button1Text}
                                    </a>
                                  )}
                                </div>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </div>
                </div>
              ))}
          </Swiper>
        </section>
      </Styles>
    );
  }
}

export default HeroSlider;
