import React, { Component } from "react";
import Datas from "../data/about-us/about-us.json";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/aboutUs.js";
import $ from "jquery";
import ScrollAnimation from "react-animate-on-scroll";

class AboutUs extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      isPlaying: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  componentDidMount = () => {
    $("#video").bind("contextmenu", function () {
      return false;
    });
  };
  openModal() {
    this.setState({ isOpen: true });
  }
  playVideo = () => {
    this.setState({ isPlaying: true });
    const video = document.getElementById("video");
    video.play();
  };
  render() {
    return (
      <Styles>
        {/* About Us */}
        <section className="about-us">
          <Container>
            <Row>
              <Col md="6">
                <ScrollAnimation animateIn="fadeInLeft">
                  <div className="about-content">
                    <p className="about-para">{Datas.desc2}</p>
                  </div>
                </ScrollAnimation>
                {/* <div className="about-image">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      `/assets/images/${Datas.secondaryImage}`
                    }
                    className="main-img"
                    alt=""
                  />
                </div> */}
              </Col>
              <Col md="6">
                <ScrollAnimation animateIn="fadeInRight">
                  <div className="about-image">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        `/assets/images/${Datas.mainImage}`
                      }
                      className="main-img"
                      alt=""
                    />
                  </div>
                </ScrollAnimation>
              </Col>
            </Row>
            <Row className="mt-5 justify-content-md-center">
              <Col
                md={9}
                className="d-flex  align-items-center  justify-content-center "
              >
                <div
                  style={{
                    position: "relative",
                    width: "70%",
                  }}
                >
                  <ScrollAnimation animateIn="fadeInUp">
                    {this.state.isPlaying ? null : (
                      <div
                        style={{
                          position: "relative",
                          background: "rgba(0, 0, 0, 0.5)",
                          cursor: "pointer",
                          width: "100%",
                        }}
                        onClick={this.playVideo}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/${Datas.videoBackground}`} // Replace with your play button image
                          alt="Play Button"
                          style={{ width: "100%" }}
                        />
                        {/* <i
                        className="fas fa-play"
                        style={{
                          fontSize: "50px",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%,-50%)",
                          border: "4px solid gray",
                          height: "100px",
                          width: "100px",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      ></i> */}
                        <div id="play-video" class="video-play-button">
                          <span></span>
                        </div>
                      </div>
                    )}
                    <video
                      id="video"
                      controls
                      style={{
                        display: this.state.isPlaying ? "block" : "none",
                        maxHeight: "350px",
                        width: "100%",
                      }}
                      controlsList="nodownload"
                    >
                      <source
                        src={`${process.env.PUBLIC_URL}/assets/video/CWIS_ecosystem_v10.mp4`}
                        type="video/mp4"
                      />
                    </video>
                    <p
                      className="my-2 "
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      <sup>*</sup>If you want to see the video please click over
                      the image
                    </p>
                  </ScrollAnimation>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>
    );
  }
}

export default AboutUs;
