import styled from "styled-components";
import { colors } from "../../../components/common/element/elements";

export const Styles = styled.div`
  .home-main {
    overflow-x: hidden;
    .home-banner {
      position: relative;
      margin-top: 80px;
      padding-right: 20px;
      width: 100vw;
      height: calc(100vh - 80px);
      img {
        width: 100%;
        height: 100%;
      }
      .icon {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 2rem;
        cursor: pointer;
        color: #005f89;
        // background: #005f89;
        // height: 40px;
        // width: 30px;
        // border-radius: 40%;
        text-align: center;
        animation: bounce 2s infinite;
      }
      @keyframes bounce {
        0%,
        20%,
        50%,
        80%,
        100% {
          transform: translateY(0);
        }
        40% {
          transform: translateY(-10px);
        }
        60% {
          transform: translateY(-5px);
        }
      }
      @media (max-width: 1023px) {
        margin-top: 80px;
        width: 100vw;
        height: calc(50vh - 80px);
        img {
          width: 100%;
          height: 100%;
        }
      }
      @media (max-width: 767px) {
        margin-top: 0;
        width: 100vw;
        height: 50vh;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .content-wrapper {
      max-width: 90%;
      margin: 0 auto;
      padding: 50px 10px;
    }
    .content-card {
      margin: 15px 0;
      box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
        rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
      border: 1px solid lightgray;
      height: 280px;
      border-radius: 8px;
      .card {
        padding: 0 20px;
        text-align: center;
        width: 100%;
        height: 280px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
      }
      :hover {
        cursor: pointer;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
          rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
      }

      .card-title {
        font-size: 23px;
        font-weight: bold;
        color: black;
      }
      .card-subtitle {
        font-size: 13px;
        font-weight: 500;
        color: black;
        color: black;
      }
    }
    .contact-btn {
      margin-top: 20px;
      a {
        font-size: 12px;
        color: white;
        border: 1px solid ${colors.border3};
        font-weight: 500;
        text-transform: uppercase;
        padding: 9px 12px 7px;
        border-radius: 1rem;
        &:hover {
          color: #000;
          background: white;
          border-color: ${colors.water};
        }

        @media (max-width: 767px) {
          float: left;
          padding: 5px 10px 3px;
        }
      }
    }
    .home-main-area {
      @media (max-width: 767px) {
        padding: 30px 0;
      }
    }
  }
`;
