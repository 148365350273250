import React, { useState } from "react";
import Datas from "../../data/publication/publications.json";
import { Container, Row, Col } from "react-bootstrap";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import Footer from "../../components/Footer";
import { Styles } from "./styles/publication";
import Header from "../../components/Header";
import PaginationNew from "../../components/PaginationNew";
import ScrollAnimation from "react-animate-on-scroll";

const Publication = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 10;
  const publicaitonsData = Datas.publicaitonsData;

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onNextPageChange = (pageNumber) => {
    setCurrentPage(pageNumber + 1);
  };

  const onPreviousPageChange = (pageNumber) => {
    setCurrentPage(pageNumber - 1);
  };

  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentData = publicaitonsData.slice(indexOfFirst, indexOfLast);

  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper event-page">
        {/* Header 2 */}
        <Header />

        {/* Breadcroumb */}
        <BreadcrumbBox title="Publication" img="4.jpg" />

        {/* Publication Area */}
        <section className="event-page-area">
          <Container>
            {/* <Row
                            xs={1}
                            sm={2}
                            md={2}
                            lg={2}
                        > */}
            <div className="publication-container">
              {currentData
                .sort((a, b) => b.id - a.id)
                .map((data, i) => (
                  <ScrollAnimation
                    animateIn={i % 2 == 0 ? "fadeInLeft" : "fadeInRight"}
                  >
                    <div className="event-box" key={i}>
                      <Row>
                        <Col xl="6" lg="6" md="0">
                          <div className="event-img">
                            <a href={data.eventLink} target="_blank">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  `/assets/images/${data.eventImg}`
                                }
                                alt=""
                                className="img-fluid"
                              />
                            </a>
                          </div>
                        </Col>
                        <Col>
                          <div
                            className="event-content"
                            style={{ width: "100%" }}
                          >
                            <div
                              // className="content-box"
                              className="content-box1"
                              style={{
                                width: "100%",
                              }}
                            >
                              <Row>
                                <Col md="9">
                                  <div className="event-title">
                                    <h6>
                                      <a href={data.eventLink} target="_blank">
                                        {data.eventTitle}
                                      </a>
                                    </h6>
                                  </div>

                                  <div className="event-desc">
                                    <p>{data.eventdesc.slice(0, 138)}</p>
                                  </div>
                                  <div className="event-date">
                                    <p>{data.eventDate}</p>
                                  </div>
                                  <div className="join-btn">
                                    <a href={data.eventLink} target="_blank">
                                      <i className="fa fa-eye ">View</i>
                                    </a>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </ScrollAnimation>
                ))}
              {/* </Row> */}
            </div>
            <Row>
              <Col md="12" className="text-center">
                <PaginationNew
                  total={publicaitonsData.length}
                  perPage={perPage}
                  currentPage={currentPage}
                  onPageChange={onPageChange}
                  onNextPageChange={onNextPageChange}
                  onPreviousPageChange={onPreviousPageChange}
                />
              </Col>
            </Row>
          </Container>
        </section>

        {/* Footer 2 */}
        <Footer />
      </div>
    </Styles>
  );
};

export default Publication;
