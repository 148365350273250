export const shitFlowData = [
  {
    id: 1,
    url: "https://sfd.susana.org/about/worldwide-projects/city/310-feni",
    imgSrc: "image1.png",
    title: "Feni Municipality",
  },
  {
    id: 2,
    url: "https://sfd.susana.org/about/worldwide-projects/city/311-gopalganj",
    imgSrc: "image2.png",
    title: "Gopalganj Municipality",
  },
  {
    id: 3,
    url: "https://sfd.susana.org/about/worldwide-projects/city/312-gazipur-2022",
    imgSrc: "image3.png",
    title: "Gazipur City Corporation",
  },
  {
    id: 4,
    url: "https://sfd.susana.org/about/worldwide-projects/city/309-bhola-municipality-2022",
    imgSrc: "image4.png",
    title: "Bhola Municipality",
  },
  {
    id: 5,
    url: "https://sfd.susana.org/about/worldwide-projects/city/307-brahmanbaria-municipality",
    imgSrc: "image5.png",
    title: "Brahmanbaria Municipality",
  },
  {
    id: 6,
    url: "https://sfd.susana.org/about/worldwide-projects/city/300-chandpur-municipality",
    imgSrc: "image6.png",
    title: "Chandpur Municipality",
  },
  {
    id: 7,
    url: "https://sfd.susana.org/about/worldwide-projects/city/277-sirajganj-municipality",
    imgSrc: "image7.png",
    title: "Sirajganj Municipality",
  },
  {
    id: 8,
    url: "https://sfd.susana.org/about/worldwide-projects/city/278-sfd-lite-report-magura-municipality-bangladesh",
    imgSrc: "image8.png",
    title: "Magura Municipality",
  },
  {
    id: 9,
    url: "https://sfd.susana.org/about/worldwide-projects/city/279-nilphamari-municipality",
    imgSrc: "image9.png",
    title: "Nilphamari Municipality",
  },
  {
    id: 10,
    url: "https://sfd.susana.org/about/worldwide-projects/city/280-joypurhat-municipality",
    imgSrc: "image10.png",
    title: "Joypurhat Municipality",
  },
  {
    id: 11,
    url: "https://sfd.susana.org/about/worldwide-projects/city/281-naogaon-municipality",
    imgSrc: "image11.png",
    title: "Naogaon Municipality",
  },
  {
    id: 12,
    url: "https://sfd.susana.org/about/worldwide-projects/city/282-thakurgaon-municipality",
    imgSrc: "image12.png",
    title: "Thakurgaon Municipality",
  },
  {
    id: 13,
    url: "https://sfd.susana.org/about/worldwide-projects/city/283-meherpur-municipality",
    imgSrc: "image13.png",
    title: "Meherpur Municipality",
  },
  {
    id: 14,
    url: "https://sfd.susana.org/about/worldwide-projects/city/284-rajbari-municipality",
    imgSrc: "image14.png",
    title: "Rajbari Municipality",
  },
  {
    id: 15,
    url: "https://sfd.susana.org/about/worldwide-projects/city/285-noakhali-municipality",
    imgSrc: "image15.png",
    title: "Noakhali Municipality",
  },
  {
    id: 16,
    url: "https://sfd.susana.org/about/worldwide-projects/city/286-sylhet-city-corporation",
    imgSrc: "image16.png",
    title: "Sylhet City Corporation",
  },
  {
    id: 17,
    url: "https://sfd.susana.org/about/worldwide-projects/city/287-chuadanga-municipality",
    imgSrc: "image17.png",
    title: "Chuadanga Municipality",
  },
  {
    id: 18,
    url: "https://sfd.susana.org/about/worldwide-projects/city/288-narayanganj-city-corporation",
    imgSrc: "image18.png",
    title: "Narayanganj City Corporation",
  },
  {
    id: 19,
    url: "https://sfd.susana.org/about/worldwide-projects/city/289-chapai-nawabganj-municipality",
    imgSrc: "image19.png",
    title: "Chapai Nawabganj Municipality",
  },
  {
    id: 20,
    url: "https://sfd.susana.org/about/worldwide-projects/city/290-gaibandha-municipality",
    imgSrc: "image20.png",
    title: "Gaibandha Municipality",
  },
  {
    id: 21,
    url: "https://sfd.susana.org/about/worldwide-projects/city/291-bogra-municipality",
    imgSrc: "image21.png",
    title: "Bogra Municipality",
  },
  {
    id: 22,
    url: "https://sfd.susana.org/about/worldwide-projects/city/271-lalmonirhat-municipality",
    imgSrc: "image22.png",
    title: "Lalmonirhat Municipality",
  },
  {
    id: 23,
    url: "https://sfd.susana.org/about/worldwide-projects/city/272-cumilla-city-corporation-2022",
    imgSrc: "image23.png",
    title: "Cumilla City Corporation",
  },
  {
    id: 24,
    url: "https://sfd.susana.org/about/worldwide-projects/city/273-lakshmipur-municipality-bangladesh",
    imgSrc: "image24.png",
    title: "Lakshmipur Municipality",
  },
  {
    id: 25,
    url: "https://sfd.susana.org/about/worldwide-projects/city/270-panchagarh-municipality",
    imgSrc: "image25.png",
    title: "Panchagarh Municipality",
  },
  {
    id: 26,
    url: "https://sfd.susana.org/about/worldwide-projects/city/269-kishoreganj-municipality",
    imgSrc: "image26.png",
    title: "Kishoreganj Municipality",
  },
  {
    id: 27,
    url: "https://sfd.susana.org/about/worldwide-projects/city/268-dinajpur-municipality",
    imgSrc: "image27.png",
    title: "Dinajpur Municipality",
  },
  {
    id: 28,
    url: "https://sfd.susana.org/about/worldwide-projects/city/261-natore-municipality",
    imgSrc: "image28.png",
    title: "Natore Municipality",
  },
  {
    id: 29,
    url: "https://sfd.susana.org/about/worldwide-projects/city/262-patuakhali-municipality",
    imgSrc: "image29.png",
    title: "Patuakhali Municipality",
  },
  {
    id: 30,
    url: "https://sfd.susana.org/about/worldwide-projects/city/263-bagerhat-municipality",
    imgSrc: "image30.png",
    title: "Bagerhat Municipality",
  },
  {
    id: 31,
    url: "https://sfd.susana.org/about/worldwide-projects/city/264-shariatpur-municipality",
    imgSrc: "image31.png",
    title: "Shariatpur Municipality",
  },
  {
    id: 32,
    url: "https://sfd.susana.org/about/worldwide-projects/city/265-ghatail-municipality",
    imgSrc: "image32.png",
    title: "Ghatail Municipality",
  },
  {
    id: 33,
    url: "https://sfd.susana.org/about/worldwide-projects/city/266-narsingdi-municipality",
    imgSrc: "image33.png",
    title: "Narsingdi Municipality",
  },
  {
    id: 34,
    url: "https://sfd.susana.org/about/worldwide-projects/city/267-mirzapur-municipality",
    imgSrc: "image34.png",
    title: "Mirzapur Municipality",
  },
  {
    id: 35,
    url: "https://sfd.susana.org/about/worldwide-projects/city/251-bandarban-municipality",
    imgSrc: "image35.png",
    title: "Bandarban Municipality",
  },
  {
    id: 36,
    url: "https://sfd.susana.org/about/worldwide-projects/city/252-homna-municipality",
    imgSrc: "image36.png",
    title: "Homna Municipality",
  },
  {
    id: 37,
    url: "https://sfd.susana.org/about/worldwide-projects/city/253-tarabo",
    imgSrc: "image37.png",
    title: "Tarabo Municipality",
  },
  {
    id: 38,
    url: "https://sfd.susana.org/about/worldwide-projects/city/245-ishwardi-municipality",
    imgSrc: "image38.png",
    title: "Ishwardi Municipality",
  },
  {
    id: 39,
    url: "https://sfd.susana.org/about/worldwide-projects/city/246-rangamati-municipality",
    imgSrc: "image39.png",
    title: "Rangamati Municipality",
  },
  {
    id: 40,
    url: "https://sfd.susana.org/about/worldwide-projects/city/228-jamalpur-bangladesh",
    imgSrc: "image40.png",
    title: "Jamalpur Municipality",
  },
  {
    id: 41,
    url: "https://sfd.susana.org/about/worldwide-projects/city/230-pabna-municipality",
    imgSrc: "image41.png",
    title: "Pabna Municipality",
  },
  {
    id: 42,
    url: "https://sfd.susana.org/about/worldwide-projects/city/232-lama",
    imgSrc: "image42.png",
    title: "Lama Municipality",
  },
  {
    id: 43,
    url: "https://sfd.susana.org/about/worldwide-projects/city/355-betagi-municipality",
    imgSrc: "image43.png",
    title: "Betagi Municipality",
  },
  {
    id: 44,
    url: "https://sfd.susana.org/about/worldwide-projects/city/353-kalihati-municipality",
    imgSrc: "image44.png",
    title: "Kalihati Municipality",
  },
  {
    id: 45,
    url: "https://sfd.susana.org/about/worldwide-projects/city/352-sreepur-municipality",
    imgSrc: "image45.png",
    title: "Sreepur Municipality",
  },
  {
    id: 46,
    url: "https://sfd.susana.org/about/worldwide-projects/city/351-sonargaon-municipality",
    imgSrc: "image46.png",
    title: "Sonargaon Municipality",
  },
  {
    id: 47,
    url: "https://sfd.susana.org/about/worldwide-projects/city/350-singair-municipality",
    imgSrc: "image47.png",
    title: "Singair Municipality",
  },
  {
    id: 48,
    url: "https://sfd.susana.org/about/worldwide-projects/city/349-paikgachha-municipality",
    imgSrc: "image48.png",
    title: "Paikgachha Municipality",
  },
  {
    id: 49,
    url: "https://sfd.susana.org/about/worldwide-projects/city/348-galachipa-municipality",
    imgSrc: "image49.png",
    title: "Galachipa Municipality",
  },
  {
    id: 50,
    url: "https://sfd.susana.org/about/worldwide-projects/city/347-bauphal-municipality",
    imgSrc: "image50.png",
    title: "Bauphal Municipality",
  },
  {
    id: 51,
    url: "https://sfd.susana.org/about/worldwide-projects/city/346-kaliakair-municipality",
    imgSrc: "image51.png",
    title: "Kaliakair Municipality",
  },
  {
    id: 52,
    url: "https://sfd.susana.org/about/worldwide-projects/city/333-jhenaidah-municipality",
    imgSrc: "image52.png",
    title: "Jhenaidah Municipality",
  },
  {
    id: 53,
    url: "https://sfd.susana.org/about/worldwide-projects/city/306-barishal",
    imgSrc: "image53.png",
    title: "Barishal City Corporation",
  },
  {
    id: 54,
    url: "https://sfd.susana.org/about/worldwide-projects/city/305-barguna-municipality",
    imgSrc: "image54.png",
    title: "Barguna Municipality",
  },
];
