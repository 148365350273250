import React from "react";
import Header from "../../components/Header";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import DynamicTabs from "../../components/DynamicTabs";
import Footer from "../../components/Footer";
import { Styles } from "./styles/indicators.js";

const Indicators = () => {
  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper about-page">
        {/* Header 2 */}
        <Header />

        {/* Breadcroumb */}
        <BreadcrumbBox title="Indicators" img="2.jpg" />

        {/* Tab Section */}
        <DynamicTabs />

        {/* Footer 2 */}
        <Footer />
      </div>
    </Styles>
  );
};

export default Indicators;
