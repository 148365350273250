import React, { useState } from "react";
import Datas from "../../data/news/news.json";
import { Container, Row, Col } from "react-bootstrap";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import Footer from "../../components/Footer";
import { Styles } from "./styles/news";
import Header from "../../components/Header";
import PaginationNew from "../../components/PaginationNew";
import ScrollAnimation from "react-animate-on-scroll";

const News = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const newsPerPage = 5;
  const newsData = Datas.newsData.sort((a, b) => {
    return b.id - a.id;
  });

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onNextPageChange = (pageNumber) => {
    if (pageNumber <= newsData.length) setCurrentPage(pageNumber + 1);
  };

  const onPreviousPageChange = (pageNumber) => {
    if (pageNumber >= 1) setCurrentPage(pageNumber - 1);
  };

  const indexOfLastNews = currentPage * newsPerPage;
  const indexOfFirstNews = indexOfLastNews - newsPerPage;
  const currentNewsData = newsData.slice(indexOfFirstNews, indexOfLastNews);

  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper event-page">
        {/* Header 2 */}
        <Header />

        {/* Breadcroumb */}
        <BreadcrumbBox title="News" img="3.jpg" />

        {/* News Area */}
        <section className="event-page-area">
          <Container>
            <Row>
              <Col md="12">
                {currentNewsData.map((data, i) => {
                  return (
                    <ScrollAnimation animateIn="fadeInUp">
                      <div className="event-box" key={i}>
                        <Row>
                          <Col xl="3" lg="4" md="0">
                            <div className="event-img">
                              <a
                                href={data.eventLink}
                                target={data.isExternal ? "_blank" : "_self"}
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    `/assets/images/${data.eventImg}`
                                  }
                                  alt=""
                                  className="img-fluid"
                                />
                              </a>
                            </div>
                          </Col>
                          <Col xl="9" lg="8" md="12">
                            <div
                              className="event-content"
                              style={{ width: "100%" }}
                            >
                              <div
                                className="content-box"
                                style={{
                                  width: "100%",
                                }}
                              >
                                <Row>
                                  <Col md="9">
                                    <div className="event-title">
                                      <h6>
                                        <a
                                          href={data.eventLink}
                                          target={
                                            data.isExternal ? "_blank" : "_self"
                                          }
                                          rel="noopener noreferrer"
                                        >
                                          {data.eventTitle}
                                        </a>
                                      </h6>
                                    </div>
                                    <div className="event-time-location">
                                      <ul className="list-unstyled list-inline">
                                        <li className="list-inline-item">
                                          <i className="las la-clock"></i>{" "}
                                          {data.eventTime}
                                        </li>
                                        <li className="list-inline-item">
                                          <i className="las la-map-marker"></i>{" "}
                                          {data.eventLocation}
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="event-desc">
                                      <p>
                                        {data.eventdesc.slice(0, 138)}
                                        ..
                                      </p>
                                    </div>
                                  </Col>
                                  <Col md="3" className="text-center">
                                    <div className="event-date">
                                      <p>{data.eventDate}</p>
                                    </div>
                                    <div className="join-btn">
                                      <a
                                        href={data.eventLink}
                                        target={
                                          data.isExternal ? "_blank" : "_self"
                                        }
                                        rel="noopener noreferrer"
                                      >
                                        Read More
                                      </a>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </ScrollAnimation>
                  );
                })}

                <Col md="12" className="text-center">
                  <PaginationNew
                    total={newsData.length}
                    perPage={newsPerPage}
                    currentPage={currentPage}
                    onPageChange={onPageChange}
                    onNextPageChange={onNextPageChange}
                    onPreviousPageChange={onPreviousPageChange}
                  />
                </Col>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Footer 2 */}
        <Footer />
      </div>
    </Styles>
  );
};

export default News;
