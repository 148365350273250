import React from "react";
import { data } from "./dashboardDataMap";
import { MapContainer, Marker, Polygon, Popup, TileLayer } from "react-leaflet";
import L from "leaflet";

const DashboardMap = () => {
  const myIcon = L.icon({
    iconUrl: require("./marker.png"),
    iconSize: [32, 32],
    // iconAnchor: [32, 64],
    // popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
  });

  const handleClick = (e) => {
    console.log("Location is at:", e.latlng);
  };

  return (
    <div
      style={{
        height: "600px",
        border: "2px solid lightgray",
        borderRadius: "8px",
        padding: "10px",
        marginBottom: "20px",
      }}
    >
      <MapContainer
        center={[23.777176, 90.399452]}
        zoom={8}
        style={{ width: "100%", height: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="© OpenStreetMap contributors"
        />
        {data.map((x) => {
          const coor = [x.Coordinate.split(",")[0], x.Coordinate.split(",")[1]];
          return (
            <Marker
              key={Math.random()}
              position={coor}
              icon={myIcon}
              eventHandlers={{
                click: (e) => handleClick(e),
              }}
            >
              <Popup>{x.City}</Popup>
            </Marker>
          );
        })}
      </MapContainer>
    </div>
  );
};

export default DashboardMap;
