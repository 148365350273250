export const data = [
  {
    name: "Araihazar Municipality",
    total_safe: 9,
    Offsite_treated: 9,
    Offsite_not_delivered_to_treatment: 15,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Bagerhat Municipality",
    total_safe: 19,
    Offsite_treated: 19,
    Offsite_not_delivered_to_treatment: 5,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Bandarban Municipality",
    total_safe: 18,
    Offsite_treated: 18,
    Offsite_not_delivered_to_treatment: 6,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Barguna Municipality",
    total_safe: 24,
    Offsite_treated: 24,
    Offsite_not_delivered_to_treatment: 4,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Barishal City Corporation",
    total_safe: 25,
    Offsite_treated: 25,
    Offsite_not_delivered_to_treatment: 6,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Bauphal Municipality",
    total_safe: 21,
    Offsite_treated: 21,
    Offsite_not_delivered_to_treatment: 0,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Betagi Municipality",
    total_safe: 21,
    Offsite_treated: 21,
    Offsite_not_delivered_to_treatment: 1,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Bhola Municipality (vn 2022)",
    total_safe: 26,
    Offsite_treated: 26,
    Offsite_not_delivered_to_treatment: 2,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Bogra Municipality",
    total_safe: 19,
    Offsite_treated: 19,
    Offsite_not_delivered_to_treatment: 2,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Brahmanbaria Municipality",
    total_safe: 13,
    Offsite_treated: 13,
    Offsite_not_delivered_to_treatment: 6,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Chandpur Municipality",
    total_safe: 20,
    Offsite_treated: 20,
    Offsite_not_delivered_to_treatment: 11,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Chapai Nawabganj Municipality (vn 2022)",
    total_safe: 23,
    Offsite_treated: 23,
    Offsite_not_delivered_to_treatment: 1,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Chuadanga Municipality",
    total_safe: 27,
    Offsite_treated: 27,
    Offsite_not_delivered_to_treatment: 4,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Coxs Bazar Municipality",
    total_safe: 20,
    Offsite_treated: 20,
    Offsite_not_delivered_to_treatment: 5,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Cumilla City Corporation (vn 2022)",
    total_safe: 14,
    Offsite_treated: 14,
    Offsite_not_delivered_to_treatment: 2,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Dinajpur  Municipality",
    total_safe: 23,
    Offsite_treated: 23,
    Offsite_not_delivered_to_treatment: 6,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Faridpur Municipality",
    total_safe: 50,
    Offsite_treated: 50,
    Offsite_not_delivered_to_treatment: 3,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Feni Municipality",
    total_safe: 8,
    Offsite_treated: 8,
    Offsite_not_delivered_to_treatment: 5,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Gaibandha Municipality",
    total_safe: 24,
    Offsite_treated: 24,
    Offsite_not_delivered_to_treatment: 3,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Galachipa Municipality",
    total_safe: 20,
    Offsite_treated: 20,
    Offsite_not_delivered_to_treatment: 15,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Gazipur (vn 2022)",
    total_safe: 26,
    Offsite_treated: 26,
    Offsite_not_delivered_to_treatment: 10,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Gopalganj Municipality",
    total_safe: 26,
    Offsite_treated: 26,
    Offsite_not_delivered_to_treatment: 1,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Homna Municipality",
    total_safe: 9,
    Offsite_treated: 9,
    Offsite_not_delivered_to_treatment: 0,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Ishwardi Municipality",
    total_safe: 18,
    Offsite_treated: 18,
    Offsite_not_delivered_to_treatment: 7,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Jamalpur, Bangladesh",
    total_safe: 1,
    Offsite_treated: 1,
    Offsite_not_delivered_to_treatment: 2,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Jhenaidah Municipality",
    total_safe: 29,
    Offsite_treated: 29,
    Offsite_not_delivered_to_treatment: 1,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Joypurhat Municipality (vn 2022)",
    total_safe: 33,
    Offsite_treated: 33,
    Offsite_not_delivered_to_treatment: 4,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Kaliakair Municipality",
    total_safe: 11,
    Offsite_treated: 11,
    Offsite_not_delivered_to_treatment: 14,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Kalihati Municipality",
    total_safe: 26,
    Offsite_treated: 26,
    Offsite_not_delivered_to_treatment: 2,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Kishoreganj Municipality",
    total_safe: 9,
    Offsite_treated: 9,
    Offsite_not_delivered_to_treatment: 7,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Lakshmipur Municipality",
    total_safe: 30,
    Offsite_treated: 30,
    Offsite_not_delivered_to_treatment: 3,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Lalmonirhat Municipality",
    total_safe: 17,
    Offsite_treated: 17,
    Offsite_not_delivered_to_treatment: 6,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Lama Municipality",
    total_safe: 10,
    Offsite_treated: 10,
    Offsite_not_delivered_to_treatment: 22,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Magura Municipality",
    total_safe: 17,
    Offsite_treated: 17,
    Offsite_not_delivered_to_treatment: 2,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Meherpur Municipality (vn 2022)",
    total_safe: 16,
    Offsite_treated: 16,
    Offsite_not_delivered_to_treatment: 1,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Naogaon Municipality (vn 2022)",
    total_safe: 30,
    Offsite_treated: 30,
    Offsite_not_delivered_to_treatment: 3,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Narayanganj City Corporation (vn 2022)",
    total_safe: 10,
    Offsite_treated: 10,
    Offsite_not_delivered_to_treatment: 22,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Narsingdi Municipality",
    total_safe: 2,
    Offsite_treated: 2,
    Offsite_not_delivered_to_treatment: 17,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Natore Municipality",
    total_safe: 22,
    Offsite_treated: 22,
    Offsite_not_delivered_to_treatment: 2,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Nilphamari Municipality (vn 2022)",
    total_safe: 23,
    Offsite_treated: 23,
    Offsite_not_delivered_to_treatment: 8,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Noakhali Municipality",
    total_safe: 15,
    Offsite_treated: 15,
    Offsite_not_delivered_to_treatment: 5,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Pabna Municipality",
    total_safe: 2,
    Offsite_treated: 2,
    Offsite_not_delivered_to_treatment: 2,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Paikgachha Municipality",
    total_safe: 24,
    Offsite_treated: 24,
    Offsite_not_delivered_to_treatment: 1,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Panchagarh Municipality",
    total_safe: 22,
    Offsite_treated: 22,
    Offsite_not_delivered_to_treatment: 1,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Patuakhali Municipality",
    total_safe: 12,
    Offsite_treated: 12,
    Offsite_not_delivered_to_treatment: 6,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Rajbari Municipality",
    total_safe: 19,
    Offsite_treated: 19,
    Offsite_not_delivered_to_treatment: 1,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Rangamati Municipality",
    total_safe: 13,
    Offsite_treated: 13,
    Offsite_not_delivered_to_treatment: 5,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Shariatpur Municipality",
    total_safe: 15,
    Offsite_treated: 15,
    Offsite_not_delivered_to_treatment: 2,
    Offsite_delivered_to_treatment_but_not_treated: 1,
  },
  {
    name: "Singair Municipality",
    total_safe: 24,
    Offsite_treated: 24,
    Offsite_not_delivered_to_treatment: 1,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Sirajganj Municipality",
    total_safe: 29,
    Offsite_treated: 29,
    Offsite_not_delivered_to_treatment: 4,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Sonargaon Municipality",
    total_safe: 25,
    Offsite_treated: 25,
    Offsite_not_delivered_to_treatment: 6,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Sreepur Municipality",
    total_safe: 15,
    Offsite_treated: 15,
    Offsite_not_delivered_to_treatment: 18,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Sylhet City Corporation (vn 2022)",
    total_safe: 27,
    Offsite_treated: 27,
    Offsite_not_delivered_to_treatment: 5,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Tarabo Municipality",
    total_safe: 6,
    Offsite_treated: 6,
    Offsite_not_delivered_to_treatment: 18,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
  {
    name: "Thakurgaon Municipality (vn 2022)",
    total_safe: 28,
    Offsite_treated: 28,
    Offsite_not_delivered_to_treatment: 2,
    Offsite_delivered_to_treatment_but_not_treated: 0,
  },
];
