import React, { Component } from 'react';
import Datas from '../data/news/news.json';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from './styles/homeBlog.js';
import { Link } from 'react-router-dom';

class HomeBlog extends Component {
  render() {
    return (
      <Styles>
        {/* Blog Area */}
        <section className="home-blog-area">
          <Container>
            <Row>
              <Col md="12">
                <div className="sec-title text-center">
                  <h4>Latest News</h4>
                </div>
              </Col>
              {Datas.newsData.slice(0, 4).map((data, i) => (
                <Col md="6" key={i}>
                  <div className="blog-post">
                    <Row>
                      <Col lg="6" md="12">
                        <div className="blog-img">
                          <a href={data.eventLink}>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                `/assets/images/${data.eventImg}`
                              }
                              alt=""
                              className="img-fluid"
                            />
                          </a>
                        </div>
                      </Col>
                      <Col lg="6" md="12">
                        <div className="blog-content">
                          <div className="content-box">
                            <div className="top-content d-flex flex-column ">
                              <div className="d-flex">
                                <div className="blog-title">
                                  <h6>
                                    <a
                                      href={
                                        data.eventLink !== ''
                                          ? data.eventLink
                                          : null
                                      }
                                      target="_blank"
                                    >
                                      {data.eventTitle}
                                    </a>
                                  </h6>
                                </div>
                              </div>
                              <div className="mt-2 d-flex align-items-start flex-column ">
                                <p
                                  className="time"
                                  style={{
                                    display: 'inline-block',
                                  }}
                                >
                                  <i className="fa fa-calendar"></i>{' '}
                                  {data.eventDate}
                                </p>
                                <div>
                                  <p style={{ lineBreak: 'anywhere' }}>
                                    <i className="las la-map-marker"></i>{' '}
                                    {data.eventLocation}
                                  </p>
                                </div>
                              </div>
                              <div className="mt-3">
                                <p>{data.eventdesc.slice(0, 150)}...</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              ))}
            </Row>

            <div
              className="d-flex align-items-center  justify-content-center"
              style={{ height: 'auto' }}
            >
              <Link to={process.env.PUBLIC_URL + `/news`} className="readmore">
                All News &nbsp; <i className="fa fa-arrow-right"></i>
              </Link>
            </div>
          </Container>
        </section>
      </Styles>
    );
  }
}

export default HomeBlog;
