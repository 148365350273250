import React from "react";
import { teamData } from "../data/team/team-slider";
import { Container, Row, Col } from "react-bootstrap";
import Swiper from "react-id-swiper";
import styled from "styled-components";

const StyledTeamSlider = styled.section`
  padding: 80px 0;
  background-color: #f8f9fa;

  .sec-title {
    margin-bottom: 40px;

    h4 {
      font-size: 32px;
      font-weight: 700;
      color: #333;
      text-align: center;
    }
  }

  .team-item {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease;
    cursor: pointer;
    overflow: hidden;
    height: auto;
    display: flex;
    flex-direction: column;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 6px 25px rgba(0, 0, 0, 0.12);
    }
  }

  .team-image-wrapper {
    width: 100%;
    padding-top: 150%; // 1:1 Aspect ratio
    position: relative;
    overflow: hidden;
  }

  .team-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100% !important;
    height: 95%;
    object-fit: cover;
  }

  .img-content {
    padding: 20px;
    text-align: center;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .person-name {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    margin-bottom: 8px;
  }

  .person-title {
    font-size: 16px;
    color: #666;
    margin-bottom: 0;
  }

  .swiper-container {
    padding-bottom: 40px;
  }

  .swiper-pagination-bullet {
    background: #333;
    opacity: 0.5;

    &-active {
      opacity: 1;
    }
  }
`;

const TeamSlider = () => {
  const settings = {
    slidesPerView: 4,
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    spaceBetween: 30,
    watchSlidesVisibility: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 4,
      },
    },
  };

  return (
    <StyledTeamSlider>
      <Container>
        <Row>
          <Col md={12}>
            <div className="sec-title">
              <h4>{teamData.title}</h4>
            </div>
          </Col>
          <Col md={12} className="team-slider">
            <Swiper {...settings}>
              {teamData.dataList.map((data, i) => (
                <div className="team-item" key={i}>
                  <div className="team-image-wrapper">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        `/assets/images/team/${data.personImage}`
                      }
                      alt={data.personName}
                      className="team-image"
                    />
                  </div>
                  <div className="img-content">
                    <h5 className="person-name">{data.personName}</h5>
                    <p className="person-title">{data.personTitle}</p>
                  </div>
                </div>
              ))}
            </Swiper>
          </Col>
        </Row>
      </Container>
    </StyledTeamSlider>
  );
};

export default TeamSlider;
