import React from "react";
import { Styles } from "./styles/wfd";
import Header from "../../components/Header";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import Footer from "../../components/Footer";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import { wfdData } from "../../data/wfd/wfdData";

const WFD = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const playVideo = () => {
    setIsPlaying(true);
    const video = document.getElementById("video");
    video.play();
  };
  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper about-page">
        {/* Header 2 */}
        <Header />

        {/* Breadcroumb */}
        <BreadcrumbBox title="WFD" img="2.jpg" />

        {/* main content */}
        <section className="details">
          <Container>
            <Row xs={1} sm={1} md={2} lg={3}>
              {wfdData.map((item, i) => (
                <div>
                  <Col>
                    <Card
                      className="custom-card"
                      style={{ margin: "30px 0", padding: "10px" }}
                    >
                      <div className="card-image-container">
                        <a href={item.link} target="_blank">
                          <Card.Img
                            variant="top"
                            src={
                              process.env.PUBLIC_URL +
                              `/assets/images/${item.img}`
                            }
                            className="card-image"
                          />
                        </a>
                      </div>
                      <Card.Body>
                        <div className="join-btn">
                          <a href={item.link} target="_blank">
                            <i className="fa fa-eye "></i>
                            &nbsp;Click for Download
                          </a>
                        </div>
                        <a href={item.link} target="_blank">
                          {item.link}
                        </a>
                      </Card.Body>
                    </Card>
                  </Col>
                </div>
              ))}
            </Row>
          </Container>
        </section>

        {/* Footer 2 */}
        <Footer />
      </div>
    </Styles>
  );
};

export default WFD;
